import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import StudentSidebar from "components/StudentSidebar";
import { useNavigate } from "react-router-dom";


export default function Chatbot() {
    const navigate = useNavigate();


    useEffect(() => {

    }, [navigate]);


    return (
        <>
            <Helmet>
                <title>intertwined</title>
                <meta name="description" content="Intertwined Finance" />
            </Helmet>
            <div className="flex w-full h-screen overflow-hidden m-auto">
                <StudentSidebar />

                <div className="flex w-full h-full">
                    <iframe
                        src="https://intertwined-chatbot.phallsoft.net/"
                        title="Chatbot"
                        style={{ width: '100%', height: '100%', border: 'none' }}
                    ></iframe>
                </div>
            </div>
        </>
    );
}
