import React from "react";
import { Helmet } from "react-helmet";
import { Heading } from "../../../../components";
import TopNavLogin from "../../../../components/TopNavLogin";
import Form from "./LoginForm";
import LoginSideGraphic from "components/LoginSideGraphic";

export default function LoginPagePage() {
  return (
    <>
      <Helmet>
        <title>Login | Intertwined</title>
        <meta name="description" content="Intertwined Finance" />
      </Helmet>
      <div className="flex h-screen overscroll-none overflow-hidden m-0">
        <div className="w-full bg-white-A700 sm:w-[75%] relative">
          <TopNavLogin className="absolute top-0 left-0 right-0 z-10" type={"login"}/>

          <div className="flex-1 flex flex-col gap-4 h-full justify-center items-center">
            <Heading size="12xl" as="h1" className="!text-gray-900_05">
              Welcome Back!
            </Heading>

            <Form />
          </div>
        </div>

        <div className="hidden sm:block sm:w-[25%]">
          <LoginSideGraphic/>
        </div>
      </div>

    </>
  );
}
