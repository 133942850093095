import React from "react";
import ProjectRoutes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { UserProvider } from "UserContext";

function App() {
  return (
    <UserProvider>
      <Router>
        <ProjectRoutes />
      </Router>
    </UserProvider>

  );
}

export default App;
