import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Heading } from "../../components";
import { Link, useNavigate } from 'react-router-dom';
import StudentSidebar from "../../components/StudentSidebar";
import { TabPanel, Tabs } from "react-tabs";
import { ReactTableHoldings } from "../../components/ReactTableHoldings";
import { createColumnHelper } from "@tanstack/react-table";
import TickerTape from "components/TickerTape";
import StockSearch from "components/StockSearch";
import { fetchStudentId, fetchStudentPortfolioGraph, fetchStudentTradeOrders } from "SimulatorAPIService";
import { formatDate } from "utils";
import Skeleton from 'react-loading-skeleton';
import PortfolioChart from "components/PortfolioChart";
import StockSimulatorTabs from "components/StockSimulatorTabs";

export default function DashboardPage() {
  const [portfolioGraph, setPortfolioGraph] = useState([]);
  const [tradeOrders, setTradeOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const table6Columns = React.useMemo(() => {
    const table6ColumnHelper = createColumnHelper();
    return [
      table6ColumnHelper.accessor("tickerName", {
        cell: (info) => (
          <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
            <Link
              to={{
                pathname: `/stockview/${info.row.original.tickerSymbol}`,
                state: { symbol: info.row.original.tickerSymbol }
              }}
              className="text-blue-500 hover:text-blue-600 text-center"
            >
              {info.getValue()}
            </Link>
          </div>
        ),
        header: () => "Stock",
      }),
      table6ColumnHelper.accessor("tickerSymbol", {
        cell: (info) => (
          <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
            {info.getValue()}
          </div>
        ),
        header: () => "Symbol",
      }),
      table6ColumnHelper.accessor("action", {
        cell: (info) => (
          <div className="flex w-full items-center justify-center">
            {info.getValue() === 0 ? (
              <div className="bg-green-100 text-green-700 px-2 py-1 rounded">BUY</div>
            ) : (
              <div className="bg-red-100 text-red-700 px-2 py-1 rounded">SELL</div>
            )}
          </div>
        ),
        header: () => "Action",
      }),
      table6ColumnHelper.accessor("type", {
        cell: (info) => (
          <div className="flex items-center justify-center">
            {info.getValue() === 0 ? "Market" : info.getValue() === 1 ? "Limit" : "Stop"}
          </div>
        ),
        header: () => "Type",
      }),
      table6ColumnHelper.accessor("quantityOfShares", {
        cell: (info) => (
          <div className="flex w-full items-center justify-center">
            {Math.abs(info.getValue())}
          </div>
        ),
        header: () => "Qty",
      }),
      table6ColumnHelper.accessor("executedDetails.pricePerShare", {
        cell: (info) => (
          <div className="flex w-full items-center justify-center">
            {info.getValue()}
          </div>
        ),
        header: () => "Price",
      }),
      table6ColumnHelper.accessor("executedDetails.stockValue", {
        cell: (info) => (
          <div className="flex w-full items-center justify-center">
            {info.getValue()}
          </div>
        ),
        header: () => "Total Amount",
      }),
      table6ColumnHelper.accessor("executedDetails.executedUtc", {
        cell: (info) => (
          <div className="flex w-full justify-between items-center border-solid border-blue_gray-100" style={{ marginLeft: '10px' }}>
            {formatDate(info.getValue())}
          </div>
        ),
        header: () => "Purchase Date",
      }),
    ];
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = await fetchStudentId();

        if (id) {

          const portfolioData = await fetchStudentPortfolioGraph(id);
          setPortfolioGraph(portfolioData);

          const tradeOrderData = await fetchStudentTradeOrders(id);
          setTradeOrders(tradeOrderData);

          // const stockData = await fetchStudentStocks(id);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/timeout");
        } else {
          console.error('Error fetching data:', error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>Stock Sim Dashboard | Intertwined</title>
        <meta name="description" content="Intertwined Finance" />
      </Helmet>

      <div className="flex w-full h-screen overflow-hidden m-auto">

        <div><StudentSidebar /></div>

        <div className="flex flex-col w-full bg-white-A700">
          <TickerTape />

          <div className="flex w-full h-full">

            <div className="flex flex-grow flex-shrink-0 w-[25%] ml-4 mt-4 h-[90%] border rounded-lg bg-white-A700 ">
              <StockSearch />
            </div>

            <div className="flex flex-col justify-start  w-full h-[90%] gap-4 m-4 border border-solid rounded-lg bg-white-A700">

              <div className="flex w-full gap-6 border-b p-4 justify-end">
                <StockSimulatorTabs currentTab={"dashboard"}/>
              </div>

              <div className="flex flex-col overflow-auto gap-4 pb-4">

                <div className="items-stretch w-full gap-1.5">
                  <Tabs
                    className="flex flex-col gap-4 md:pb-5"
                    selectedTabClassName="!text-blue-A200"
                    selectedTabPanelClassName="relative tab-panel--selected"
                  >
                    {[...Array(5)].map((_, index) => (
                      <TabPanel key={`tab-panel${index}`} className="absolute items-center">
                        <div className="w-full">
                          <div className="flex flex-col gap-4">
                            <div className="flex self-start px-4 pb-1 pt-2.5">
                              <Heading size="11xl" as="h2">
                                Portfolio Summary
                              </Heading>
                            </div>
                            <div className="flex rounded-lg justify-center items-center border border-solid border-blue_gray-50_01 bg-white-A700 px-4 py-[19px] mx-2.5">
                              <div className="flex flex-col items-center gap-2 border-r border-solid border-blue_gray-100 px-[38px] sm:px-5">
                                <div className="flex self-start">
                                  <Heading as="h3" className="!font-poppins !text-gray-500_02">
                                    Balance Funds
                                  </Heading>
                                </div>
                                <div className="flex-1 w-full self-start">
                                  <Heading size="7xl" as="h4" className="!text-gray-800_02">
                                    <span>${portfolioGraph.currentBalance?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || <Skeleton width={"85%"} count={1} />}</span>
                                  </Heading>
                                </div>
                              </div>
                              <div className="flex flex-col items-center gap-2 border-l border-r border-solid border-blue_gray-100 px-8 sm:px-5">
                                <div className="flex self-start">
                                  <Heading as="h5" className="!font-poppins !text-gray-500_02">
                                    Total Investment
                                  </Heading>
                                </div>
                                <div className="flex-1 w-full self-start">
                                  <Heading size="7xl" as="h6" className="!text-gray-800_02">
                                    <span>$
                                      {isNaN(portfolioGraph.startingBalance - portfolioGraph.currentBalance) ? (
                                        <Skeleton width="100px" height="1em" />
                                      ) : (
                                        `${(portfolioGraph.startingBalance - portfolioGraph.currentBalance).toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}`
                                      )}
                                    </span>
                                  </Heading>
                                </div>
                              </div>
                              <div className="flex flex-col items-center gap-2 border-l border-r border-solid border-blue_gray-100 px-[38px] sm:px-5">
                                <div className="flex self-start">
                                  <Heading as="p" className="!font-poppins !text-gray-500_02">
                                    Current Value
                                  </Heading>
                                </div>
                                <div className="flex-1 w-full self-start">
                                  <Heading size="7xl" as="h6" className="!text-gray-800_02">
                                    <span>${portfolioGraph.portfolioTotalValue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || <Skeleton width={"85%"} count={1} />}</span>
                                  </Heading>
                                </div>
                              </div>
                              <div className="flex flex-col items-center gap-2 border-l border-r border-solid border-blue_gray-100 px-[25px] sm:px-5">
                                <div className="flex">
                                  <Heading as="p" className="!font-poppins !text-gray-500_02">
                                    Day's P&L
                                  </Heading>
                                </div>
                                <div className="flex-1 w-full self-start text-center">
                                  <Heading size="7xl" as="h6">
                                    <span>
                                      ${portfolioGraph.historicalGraphPoints &&
                                        portfolioGraph.historicalGraphPoints.length > 1 ? (
                                        <>
                                          {(
                                            portfolioGraph.portfolioTotalValue -
                                            portfolioGraph.historicalGraphPoints[portfolioGraph.historicalGraphPoints.length - 1].portfolioValue
                                          ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                          <span
                                            className={
                                              (portfolioGraph.portfolioTotalValue -
                                                portfolioGraph.historicalGraphPoints[portfolioGraph.historicalGraphPoints.length - 1].portfolioValue) > 0
                                                ? "text-green-500"
                                                : "text-red-500"
                                            }
                                          >
                                            {` (${(
                                              ((portfolioGraph.portfolioTotalValue -
                                                portfolioGraph.historicalGraphPoints[portfolioGraph.historicalGraphPoints.length - 1].portfolioValue) /
                                                portfolioGraph.historicalGraphPoints[portfolioGraph.historicalGraphPoints.length - 1].portfolioValue) * 100
                                            ).toFixed(2)
                                              }%)`}
                                          </span>
                                        </>
                                      ) : (
                                        <Skeleton width={"65%"} count={1} />
                                      )}
                                    </span>
                                  </Heading>
                                </div>
                              </div>
                              <div className="flex flex-col items-center gap-2 border-l border-solid border-blue_gray-100 px-[35px] sm:px-5">
                                <div className="flex">
                                  <Heading as="p" className="!font-poppins !text-gray-500_02">
                                    Total P&L
                                  </Heading>
                                </div>
                                <div className="flex-1 w-full self-start text-center">
                                  <Heading size="7xl" as="h6" className="!font-poppins !text-gray-800_02">
                                    <span>$
                                      {portfolioGraph.portfolioTotalValue !== undefined &&
                                        portfolioGraph.startingBalance !== undefined ? (
                                        <>
                                          {(
                                            portfolioGraph.portfolioTotalValue - (portfolioGraph.startingBalance - portfolioGraph.currentBalance)
                                          ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                          <span
                                            className={
                                              (portfolioGraph.portfolioTotalValue - (portfolioGraph.startingBalance - portfolioGraph.currentBalance)) > 0
                                                ? "text-green-500"
                                                : "text-red-500"
                                            }
                                          >
                                            {` (${(
                                              ((portfolioGraph.portfolioTotalValue - (portfolioGraph.startingBalance - portfolioGraph.currentBalance)) /
                                                portfolioGraph.startingBalance) * 100
                                            ).toFixed(2)
                                              }%)`}
                                          </span>
                                        </>
                                      ) : (
                                        <Skeleton width={"65%"} count={1} />
                                      )}
                                    </span>
                                  </Heading>
                                </div>
                              </div>
                            </div>


                          </div>

                          <div className="flex flex-col p-4 h-[350px]">
                            <div className="flex self-start">
                              <Heading size="6xl" as="h6">
                                Portfolio Trend
                              </Heading>
                            </div>
                            <PortfolioChart portfolioData={portfolioGraph}/>
                          </div>

                          <div className="flex flex-col rounded-lg mx-3 mt-10 border border-solid border-blue_gray-50_01 bg-white-A700">
                            <div className="flex self-start px-4 pb-3 pt-[13px]">
                              <Heading size="6xl" as="h6">
                                Recent Transactions
                              </Heading>
                            </div>
                            <div className="p-1">
                              <ReactTableHoldings
                                size="xl"
                                bodyProps={{ className: "w-full" }}
                                headerProps={{
                                  className: "w-full border-blue_gray-100 border-b border-solid bg-gray-50_01 md:flex-col",
                                }}
                                rowDataProps={{ className: "w-full border-t border-b border-solid border-blue_gray-100" }}
                                className="w-full"
                                columns={table6Columns}
                                data={isLoading ? [] : tradeOrders?.executed}
                                isLoading={isLoading} // Add this prop to handle loading state
                              />
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    ))}
                  </Tabs>
                </div>


              </div>
            </div>

          </div>
        </div>


      </div>


    </>
  );
}

