import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Img, Text, Heading, Button } from "../../components";
import Skeleton from 'react-loading-skeleton';
import StudentSidebar from "../../components/StudentSidebar";
import {
  AccordionItemPanel,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemState,
  Accordion,
  AccordionItem,
} from "react-accessible-accordion";
import { useNavigate, useParams } from 'react-router-dom';
import HomeDate from "../../components/HomeDate";
import { ConvertDuration } from "utils";
import { fetchStudentStatus, fetchUnits } from "StudentAPIService";
import { apiClient } from "AuthService";
import ProgressBar from "components/ProgressBar";

const colors = {
  "orange": {
    bg: "bg-yellow-100_01",
    image: "/images/img_currency_dollar.svg",
    border: "border-deep_orange-900_87",
    text: "!text-deep_orange-900_7f",
    progress: "bg-deep_orange-900_7f",
  },
  "blue": {
    bg: "bg-blue-50_04",
    image: "/images/img_line_chart_up_03.svg",
    border: "border-blue-900_87",
    text: "!text-blue-900_7f",
    progress: "bg-blue-900_7f",
  },
  "pink": {
    bg: "bg-purple-50",
    image: "/images/img_graduation_hat_02.svg",
    border: "border-pink-800_87",
    text: "!text-pink-800_7f",
    progress: "bg-pink-800_7f",
  }
};

const colorKeys = Object.keys(colors);

function seededRandom(seed) {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

export default function CourseViewPage() {
  const { courseId, studentId } = useParams();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState([]);
  const [course, setCourse] = useState({ units: [] });
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const data = await fetchUnits(courseId);
        data.units.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
        data.units.forEach(unit => {
          unit.sections.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
        });
        setCourse(data);
        console.log(data);
      } catch (err) {
        console.error("Fetching course failed", err);
      }
    };

    fetchCourseData();
  }, [courseId, studentId]);

  useEffect(() => {
    const getStudentStatus = async () => {
      try {
        const data = await fetchStudentStatus(studentId, courseId);
        setStatus(data);
        if (data.courseStatus === 1) {

        }
      } catch (err) {
        console.error("Fetching status failed", err);
      }
    };

    getStudentStatus();
  }, [courseId, studentId]);

  const handleContinue = async () => {
    for (let unit of course.units) {
      try {
        const response = await apiClient(`/api/Students/${studentId}/Status?courseId=${courseId}&unitId=${unit.id}`, {
          method: 'GET',
          headers: {
            accept: 'application/json',
          },
        });

        if (response.status === 401) {
          navigate("/timeout");
          return;
        }

        const data = await response.json();
        if (data.unitStatus.status === 1) {
          navigate(`/courses/course/${courseId}/student/${studentId}/unit/${unit.id}`);
          break;
        }
      } catch (error) {
        console.error("Fetching unit status failed", error);
      }
    }
  };

  const toggleAccordion = (uuid) => {
    const currentIndex = expanded.indexOf(uuid);
    const newExpanded = [...expanded];

    if (currentIndex === -1) {
      newExpanded.push(uuid);
    } else {
      newExpanded.splice(currentIndex, 1);
    }

    setExpanded(newExpanded);
  };

  const seed = course.name ? course.name.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0) : 0;
  const colorIndex = Math.floor(seededRandom(seed) * colorKeys.length);
  const assignedColor = colorKeys[colorIndex];
  const colorScheme = colors[assignedColor];

  return (
    <>
      <Helmet>
        <title>Course | Intertwined</title>
        <meta name="description" content="Intertwined Finance" />
      </Helmet>
      <div className="flex w-full h-screen items-start justify-center bg-white-A700">
        <StudentSidebar />

        <div className="p-5 gap-4 flex flex-col h-full w-full bg-mesh-gradient">
          <div className="flex justify-between rounded-lg bg-white-A700 shadow-md">
            <div className="flex items-center p-4">
              <Img
                src="/images/img_arrow_left_blue_a200.svg"
                alt="arrowleft"
                className="relative right-px"
                onClick={() => navigate('/courses')}
              />
              <Text as="p" className="text-blue-500 cursor-pointer justify-start" onClick={() => navigate('/courses')}>
                Back to all courses
              </Text>
            </div>
            <HomeDate className={"rounded-lg"} />
          </div>

          <div className="flex-grow overflow-auto">
            <div className="flex flex-col items-center gap-6 w-full">
              <div className="flex w-full flex-col items-start justify-center gap-4 shadow-md rounded-lg">
                <div className="flex items-center w-full p-4 rounded-lg border border-solid border-indigo-50 bg-white-A700 md:flex-col">
                  <div className="flex self-start pb-4">
                    <Heading size="13xl" as="h2">
                      {course.name}
                    </Heading>
                  </div>
                  <div className={`w-[25%] rounded-lg p-2 md:w-full md:p-5 ${colorScheme.bg}`}>
                    <div className="rounded-md px-1.5 pt-1.5">
                      <div className="p-12 items-center justify-center md:py-5 sm:p-5">
                        <Img
                          src={colorScheme.image}
                          alt="course-icon"
                          className="h-[148px] w-[148px]"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col items-start gap-6 rounded-lg bg-white-A700 p-4 md:self-stretch md:p-5">
                    <div className="flex flex-col items-start gap-[15px] self-stretch">
                      <Heading size="6xl" as="h3" className="!font-bold">
                        {course.name}
                      </Heading>
                      <Heading as="h4" className="w-[85%] leading-[170%] !text-gray-500_02 md:w-full">
                        {course.description}
                      </Heading>
                    </div>
                    <div className="flex justify-between gap-4 py-2 w-full mt-4 border-b border-t border-solid border-indigo-50 bg-white-A700 md:w-full md:p-5 items-center divide-x">
                      <div className="flex p-2 ">
                        <Text className="!font-bold">Course Content</Text>
                      </div>
                      <div className="flex p-2 px-8 gap-2 items-center ">
                        <Img src="/images/img_library_books.svg" alt="library books" className="h-[16px] w-[16px]" />
                        <Text>{course.units.length} Units</Text>
                      </div>
                      <div className="flex p-2 px-8 gap-2 items-center ">
                        <Img src="/images/img_access_time.svg" alt="access time" className="h-[16px] w-[16px]" />
                        <Text>{course.units.reduce((acc, cur) => acc + cur.expectedDuration, 0)} Hours of Content</Text>
                      </div>
                      <div className="flex p-2 px-8 gap-2 items-center">
                        <Img src="/images/img_edit_document.svg" alt="edit document" className="h-[16px] w-[16px]" />
                        <Text>{course.units.reduce((acc, cur) => acc + cur.sections.length, 0)} Lessons</Text>
                      </div>
                    </div>
                    <div className="flex items-center justify-center w-full gap-4">
                      <div className="flex w-[67%] gap-4 md:w-full sm:flex-col">
                        <div className="flex w-full gap-2 p-2.5 sm:self-stretch items-center">
                          <div className={`h-full flex-1 rounded-sm items-center justify-center flex`}>
                            {status ? (
                              <ProgressBar color={colorScheme.progress} backgroundColor={colorScheme.bg} progress={status.courseStatus.percentageComplete} />
                            ) : (
                              <Skeleton height={6} width={"100%"} />
                            )}
                          </div>
                        </div>
                      </div>
                      <Button
                        color="blue_500"
                        size="lg"
                        className="justify-center ml-auto rounded-[18px] font-medium tracking-[0.10px] sm:px-6 py-3 text-sm tracking-tight min-w-[200px] "
                        onClick={handleContinue}
                      >
                        Resume Learning
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full rounded-lg border border-solid border-indigo-50 bg-white-A700 shadow-md">
                <div className="rounded-lg">
                  <Text className="font-bold bg-blue-50 p-2 rounded text-lg">Units</Text>
                  <Accordion allowZeroExpanded preExpanded={expanded} className="flex flex-col gap-px">
                    {course.units.map((u, index) => (
                      <AccordionItem uuid={index} key={`expandablelistt${index}`}>
                        <AccordionItemHeading className="w-full">
                          <AccordionItemButton onClick={() => toggleAccordion(index)}>
                            <AccordionItemState>
                              {(props) => (
                                <>
                                  <div className="flex items-center justify-between gap-5 self-stretch border-b border-solid border-indigo-50 bg-white-A700 px-4 pb-[19px] pt-5">
                                    <Heading size="6xl" as="h6" className="self-end">
                                      {u.name}
                                    </Heading>
                                    <div className="flex flex-wrap items-center gap-2.5 self-start">
                                      <Text as="p" className="!text-gray-500_02">
                                        {`${u.expectedDuration} ${ConvertDuration(u.durationUnit)}`}
                                      </Text>
                                      {expanded.indexOf(index) > -1 ? (
                                        <Img src="/images/img_arrow_up_black_900.svg" alt="arrowup" className="h-[24px] w-[24px]" />
                                      ) : (
                                        <Img src="/images/img_arrow_down_black_900.svg" alt="arrowdown" className="h-[24px] w-[24px]" />
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </AccordionItemState>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="flex justify-between m-[20px] gap-4">
                            <Heading as="p" className="min-w-[50px] text-center self-start text-deep_purple-A200_01 bg-gray-50 p-1 rounded-md border-2 border-deep_purple-A200_01">
                              Unit {index + 1}
                            </Heading>
                            <div className="flex flex-col items-start gap-4">
                              <div className="flex justify-between items-start">
                                <Heading
                                  size="6xl"
                                  as="h6"
                                  className="!font-poppins leading-[130%]"
                                >
                                  <span className="font-notosans text-gray-900_07">
                                    <>
                                      {u.name} <br />
                                    </>
                                  </span>
                                  <span className="text-gray-900_07">
                                    <>
                                      <br />
                                    </>
                                  </span>
                                  <span className="font-notosans font-normal text-gray-900_07">
                                    <>
                                      {u.description}<br />
                                    </>
                                  </span>
                                </Heading>

                                <Button
                                  size="3xl"
                                  className="z-[2] min-w-[113px] rounded-[20px] font-medium tracking-[0.10px] mr-0 px-5"
                                  onClick={() => navigate(`unit/${u.id}`)}
                                >
                                  View Unit
                                </Button>
                              </div>

                              <div className="relative z-[1] w-[86%] rounded-lg border border-solid border-indigo-50 ">
                                {u.sections.map((s, j) => (
                                  <div key={`sectionitem${j}`} className="flex border border-indigo-50 bg-gray-50_01 divide-x">
                                    <div className="flex flex-1 p-2 md:self-stretch md:p-5">
                                      <div className="flex pb-2 pt-[11px]">
                                        <Text as="p" className="tracking-[0.50px] !text-blue_gray-500">
                                          {`${index + 1}.${j + 1} ${s.name}`}
                                        </Text>
                                      </div>
                                    </div>
                                    <Text
                                      size="7xl"
                                      as="p"
                                      className=" py-[17px] pl-6 pr-[35px] tracking-[0.10px] !text-blue_gray-500 md:p-5 sm:px-5"
                                    >
                                      20 min
                                    </Text>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
