import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Heading } from "../../components";
import { ReactTable } from "../../components/ReactTable";
import StudentSidebar from "../../components/StudentSidebar";
import { createColumnHelper } from "@tanstack/react-table";
import { TabPanel, Tabs } from "react-tabs";
import { Link } from 'react-router-dom';
import TickerTape from "components/TickerTape";
import { fetchStudentTradeOrders, fetchStudentId } from "SimulatorAPIService";
import StockSearch from "components/StockSearch";
import { formatDate } from "utils";
import StockSimulatorTabs from "components/StockSimulatorTabs";
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react';


export default function OrdersPage() {

  const [orders, setOrders] = useState([]);
  const [studentId, setStudentId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedCompletedRows, setExpandedCompletedRows] = useState({});
  const [expandedPendingRows, setExpandedPendingRows] = useState({});

  const handleExpandCompletedRow = (rowIndex) => {
    setExpandedCompletedRows((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex],
    }));
  };

  const handleExpandPendingRow = (rowIndex) => {
    setExpandedPendingRows((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex],
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = await fetchStudentId();
        setStudentId(id);

        if (studentId) {
          const tradeOrders = await fetchStudentTradeOrders(id);
          setOrders(tradeOrders);
          console.log(tradeOrders);
        }
      } catch (error) {
        console.error('Error fetching data or posting trade:', error);
      } finally {
        setIsLoading(false);
      }

      

    };

    fetchData();
  }, [studentId]);


  const table5Columns = React.useMemo(() => {
    const table5ColumnHelper = createColumnHelper();
    return [
      table5ColumnHelper.display({
        id: "expand",
        cell: ({ row }) => (
          <div className="flex items-center justify-center">
            <button onClick={() => handleExpandCompletedRow(row.index)} className="mr-2">
              {expandedCompletedRows[row.index] ? (<IconChevronDown />) : (<IconChevronUp />)}
            </button>
          </div>
        ),
        meta: { width: "40px" },
      }),
      table5ColumnHelper.accessor("tickerName", {
        cell: (info) => (
          <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
            <Link
              to={`/stockview/${info.row.original.tickerSymbol}`}
              className="text-blue-500 hover:text-blue-600 text-center"
            >
              {info.getValue()}
            </Link>
          </div>
        ),
        header: () => "Stock",
        meta: { width: "full" },
      }),
      table5ColumnHelper.accessor("tickerSymbol", {
        cell: (info) => (
          <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
            {info.getValue()}
          </div>
        ),
        header: () => "Symbol",
        meta: { width: "full" },
      }),
      table5ColumnHelper.accessor("action", {
        cell: (info) => (
          <div className="flex items-center justify-center">
            {info.getValue() === 0 ? (
              <div className="bg-green-100 text-green-700 px-2 py-1 rounded">BUY</div>
            ) : (
              <div className="bg-red-100 text-red-700 px-2 py-1 rounded">SELL</div>
            )}
          </div>
        ),
        header: () => "Action",
        meta: { width: "129px" },
      }),
      table5ColumnHelper.accessor("type", {
        cell: (info) => (
          <div className="flex items-center justify-center">
            {info.getValue() === 0 ? "Market" : info.getValue() === 1 ? "Limit" : "Stop"}
          </div>
        ),
        header: () => "Type",
        meta: { width: "129px" },
      }),
      table5ColumnHelper.accessor("quantityOfShares", {
        cell: (info) => (
          <div className="flex items-center justify-center">
            {Math.abs(info.getValue())}
          </div>
        ),
        header: () => "Qty",
        meta: { width: "129px" },
      }),
      table5ColumnHelper.accessor("activeDetails.pricePerShare", {
        cell: (info) => (
          <div className="flex items-center justify-center">
            {info.getValue()}
          </div>
        ),
        header: () => "Price",
        meta: { width: "129px" },
      }),
      table5ColumnHelper.accessor("activeDetails.stockValue", {
        cell: (info) => (
          <div className="flex items-center justify-center">
            {info.getValue()}
          </div>
        ),
        header: () => "Total Amount",
        meta: { width: "129px" },
      }),
      table5ColumnHelper.accessor("activeDetails.submittedUtc", {
        cell: (info) => (
          <div className="flex w-full justify-center ml-5 items-center border-solid border-blue_gray-100" style={{ marginLeft: '10px' }}>
            {formatDate(info.getValue())}
          </div>
        ),
        header: () => "Purchase Date",
        meta: { width: "full" },
      }),

    ];
  }, [expandedCompletedRows]);

  const table4Columns = React.useMemo(() => {
    const table4ColumnHelper = createColumnHelper();
    return [
      table4ColumnHelper.display({
        id: "expand",
        cell: ({ row }) => (
          <div className="flex items-center justify-center">
            <button onClick={() => handleExpandPendingRow(row.index)} className="mr-2">
              {expandedPendingRows[row.index] ? (<IconChevronDown />) : (<IconChevronUp />)}
            </button>
          </div>
        ),
        meta: { width: "40px" },
      }),
      table4ColumnHelper.accessor("tickerName", {
        cell: (info) => (
          <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
            <Link
              to={`/stockview/${info.row.original.tickerSymbol}`}
              className="text-blue-500 hover:text-blue-600 text-center"
            >
              {info.getValue()}
            </Link>
          </div>
        ),
        header: () => "Stock",
        meta: { width: "full" },
      }),
      table4ColumnHelper.accessor("tickerSymbol", {
        cell: (info) => (
          <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
            {info.getValue()}
          </div>
        ),
        header: () => "Symbol",
        meta: { width: "full" },
      }),
      table4ColumnHelper.accessor("action", {
        cell: (info) => (
          <div className="flex items-center justify-center">
            {info.getValue() === 0 ? (
              <div className="bg-green-100 text-green-700 px-2 py-1 rounded">BUY</div>
            ) : (
              <div className="bg-red-100 text-red-700 px-2 py-1 rounded">SELL</div>
            )}
          </div>
        ),
        header: () => "Action",
        meta: { width: "129px" },
      }),
      table4ColumnHelper.accessor("type", {
        cell: (info) => (
          <div className="flex items-center justify-center">
            {info.getValue() === 0 ? "Market" : info.getValue() === 1 ? "Limit" : "Stop"}
          </div>
        ),
        header: () => "Type",
        meta: { width: "129px" },
      }),
      table4ColumnHelper.accessor("quantityOfShares", {
        cell: (info) => (
          <div className="flex items-center justify-center">
            {Math.abs(info.getValue())}
          </div>
        ),
        header: () => "Qty",
        meta: { width: "129px" },
      }),
      table4ColumnHelper.accessor("executedDetails.pricePerShare", {
        cell: (info) => (
          <div className="flex items-center justify-center">
            {info.getValue()}
          </div>
        ),
        header: () => "Price",
        meta: { width: "129px" },
      }),
      table4ColumnHelper.accessor("executedDetails.stockValue", {
        cell: (info) => (
          <div className="flex items-center justify-center">
            {info.getValue()}
          </div>
        ),
        header: () => "Total Amount",
        meta: { width: "129px" },
      }),
      table4ColumnHelper.accessor("executedDetails.executedUtc", {
        cell: (info) => (
          <div className="flex w-full justify-center ml-5 items-center border-solid border-blue_gray-100" style={{ marginLeft: '10px' }}>
            {formatDate(info.getValue())}
          </div>
        ),
        header: () => "Purchase Date",
        meta: { width: "full" },
      }),
    ];
  }, [expandedPendingRows]);
  
  return (
    <>
      <Helmet>
        <title>Orders | Intertwined</title>
        <meta name="description" content="Intertwined Finance" />
      </Helmet>

      <div className="flex w-full h-screen overflow-hidden m-auto">

        <div><StudentSidebar /></div>

        <div className="flex flex-col w-full">
          <TickerTape />

          <div className="flex w-full h-full bg-white-A700">

            <div className="flex flex-grow flex-shrink-0 w-[25%] ml-4 mt-4 h-[90%] border rounded-lg bg-white-A700">
              <StockSearch />
            </div>

            <div className="flex flex-col justify-start  w-full h-[90%] gap-4 m-4 border border-solid rounded-lg bg-white-A700">

              <div className="flex w-full gap-6 border-b p-4 justify-end">
                <StockSimulatorTabs currentTab={"orders"} />
              </div>

              <div className="flex flex-col overflow-auto gap-4">

                <div className="items-stretch w-full gap-1.5 rounded-lg">
                  <Tabs
                    className="flex w-full flex-col gap-4"
                    selectedTabClassName="!text-blue-A200"
                    selectedTabPanelClassName="relative tab-panel--selected"
                  >
                    {[...Array(5)].map((_, index) => (
                      <TabPanel key={`tab-panel${index}`} className="absolute items-center">
                        <div className="w-full overflow-x-hidden">
                          <div className="flex flex-col gap-4">
                            <div className="flex self-start px-4">
                              <Heading size="11xl" as="h2">
                                Orders
                              </Heading>
                            </div>
                            <div className="flex self-start px-4">
                              <Heading size="6xl" as="h3">
                                Pending Orders
                              </Heading>
                            </div>
                            <ReactTable
                              size="2xl"
                              bodyProps={{ className: "w-full" }}
                              headerProps={{
                                className: "w-full border-blue_gray-100 border-b border-solid bg-gray-50_01",
                              }}
                              rowDataProps={{ className: "w-full border-b border-solid border-blue_gray-100" }}
                              className="w-full rounded-lg"
                              columns={table5Columns}
                              data={isLoading ? [] : orders?.active}
                              isLoading={isLoading}
                              expandedRows={expandedCompletedRows}
                              handleExpandRow={handleExpandCompletedRow}
                              orderType="active"
                            />
                            <div className="flex self-start p-4">
                              <Heading size="6xl" as="h6">
                                Completed Orders
                              </Heading>
                            </div>
                            <ReactTable
                              size="2xl"
                              bodyProps={{ className: "w-full" }}
                              headerProps={{
                                className: "w-full border-blue_gray-100 border-b border-solid bg-gray-50_01 md:flex-col",
                              }}
                              rowDataProps={{ className: "border-t justify-center border-b border-solid border-blue_gray-100" }}
                              className="w-full"
                              columns={table4Columns}
                              data={isLoading ? [] : orders?.executed}
                              isLoading={isLoading}
                              expandedRows={expandedPendingRows}
                              handleExpandRow={handleExpandPendingRow}
                              orderType="executed"
                            />
                          </div>
                        </div>
                      </TabPanel>
                    ))}
                  </Tabs>
                </div>



              </div>
            </div>

          </div>
        </div>


      </div>
    </>
  );
}
