import React, { useState } from "react";
import AddCourseModal from "components/AddCourseModal";
import QuickLink from "components/QuickLink";
import TeacherCourseCard from "components/TeacherCourseCard";
import { ConvertDuration } from "utils";

const AssignedCourses = ({ classroomData, reloadClassroomDetails }) => {
    const [isAddingCourse, setIsAddingCourse] = useState(false);

    return (
        <div className="flex overflow-y-auto divide-x h-full w-full">
            <div className="flex flex-col w-full h-full gap-4 p-4 lg:w-[85%]">
                <div className="flex w-full gap-2 justify-end">
                    <button
                        onClick={() => setIsAddingCourse(true)}
                        className={`flex justify-center h-[80%] items-center p-4 font-semibold text-blue-500 text-sm border border-blue-500 bg-white-A700 rounded-lg hover:bg-blue-100 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100`}
                    >
                        Add Courses
                    </button>
                </div>

                <div className="flex gap-6 rounded-lg flex-col md:flex-row">
                    {classroomData.courses.map((course, index) => (
                        <TeacherCourseCard
                            key={index}
                            className={course.name} // Adjust according to  data structure
                            courseName={course.name} // Example: "Not Started", "Continue"
                            expectedDuration={course.expectedDuration}
                            durationUnit={ConvertDuration(course.durationUnit)}
                            courseId={course.id}
                            to={`/course/${course.id}`} // Adjust routing as needed
                        />
                    ))}
                </div>
            </div>

            <AddCourseModal
                open={isAddingCourse}
                onClose={() => setIsAddingCourse(false)}
                classId={classroomData.id}
                classroomData={classroomData}
                reloadClassroomDetails={reloadClassroomDetails}
            />

            <div className="flex-col md:w-[25%] h-full p-4 hidden lg:flex">
                <QuickLink classroomData={classroomData} />
            </div>
        </div>
    );
};

export default AssignedCourses;
