import React from "react";
import { Helmet } from "react-helmet";
import StudentSidebar from "../../components/StudentSidebar";
import TickerTape from "components/TickerTape";
import StockSearch from "components/StockSearch";
import StockSimulatorTabs from "components/StockSimulatorTabs";
import TopStoriesComponent from "components/TopStoriesComponent";

export default function TopStoriesPage() {

  return (
    <>
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Intertwined Finance" />
      </Helmet>

      <div className="flex w-full h-screen overflow-hidden m-auto">

        <div><StudentSidebar /></div>

        <div className="flex flex-col w-full bg-white-A700">
          <TickerTape />

          <div className="flex w-full h-full">

            <div className="flex flex-grow flex-shrink-0 w-[25%] ml-4 mt-4 h-[94%] border rounded-lg bg-white-A700">
              <StockSearch />
            </div>

            <div className="flex flex-col justify-start  w-full h-[94%] gap-4 m-4 border border-solid rounded-lg bg-white-A700 ">

              <div className="flex w-full gap-6 border-b p-4 justify-end">
                <StockSimulatorTabs currentTab={"topstories"} />
              </div>

              <div className="flex flex-col gap-4 p-4 overflow-hidden">

                <div className="flex flex-col items-stretch w-full gap-1.5">
                  <div className="flex flex-col h-max gap-3.5">

                    <div className="flex w-full h-[80vh] items-center justify-center">
                        <TopStoriesComponent/>
                    </div>
                    
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>


      </div>


    </>
  );
}


