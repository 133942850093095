import React, { useState, useEffect, useRef, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Img } from "../../components";
import { useNavigate, useParams } from 'react-router-dom';
import { fetchClassroomDetails } from "TeacherAPIService";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AssignedCourses from "components/AssignedCourses";
import Students from "components/Students";
import Instructors from "components/Instructors";
import Sidebar1 from "components/Sidebar1";
import HomeDate from "components/HomeDate";
import ShareClassCode from "components/ShareClassCode";
import StudentPerformance from "components/StudentPerformance";
import ClassroomPerformance from "components/ClassroomPerformance";
import Assignments from "components/Assignments";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { formatDate } from "utils";

export default function ClassroomDetails() {
    const navigate = useNavigate();
    const { classroomID } = useParams();
    const [classroomDetails, setClassroomDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSharingClassCode, setIsSharingClassCode] = useState(false);
    const scrollRef = useRef(null); // Step 2

    const fetchData = useCallback(async () => {
        try {
            const fetchedClassroom = await fetchClassroomDetails(classroomID);
            setClassroomDetails(fetchedClassroom);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate("/timeout");
            } else {
                console.error('Error fetching classrooms:', error);
            }
        } finally {
            setIsLoading(false);
        }
    }, [classroomID, navigate]);

    useEffect(() => {
        console.log("Classroom ID: ", classroomID);
        fetchData();
    }, [classroomID, fetchData]);

    useEffect(() => {
        const handleResize = () => {
            if (scrollRef.current) { // Step 4
                scrollRef.current.scrollTop = 0;
            }
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    });

    return (
        <>
            <Helmet>
                <title>Classroom | Intertwined</title>
                <meta name="description" content="Intertwined Finance" />
            </Helmet>
            <div className="flex h-screen overflow-hidden">
                <Sidebar1 />

                <div className="flex flex-col w-full max-full overflow-hidden bg-mesh-gradient p-4 gap-4">
                    <div className="bg-white-A700 rounded-lg divide-y shadow-md">
                        <div className="flex justify-between ">
                            <div className="flex items-center text-center gap-2 min-h-[50px] px-4 cursor-pointer" onClick={() => navigate('/teacherhome')}>
                                <Img src="/images/img_arrow_left_blue_a200.svg" alt="arrowleft" className="h-[20px]" />
                                <p className="text-md !text-blue-500">All Classes</p>
                            </div>
                            <HomeDate className={"rounded-lg"} />
                        </div>

                        <div className="flex justify-between p-4">
                            <div className="flex flex-col gap-2">
                                {isLoading ? (
                                    <Skeleton height={36} width={200} />
                                ) : (
                                    <h1 className="text-3xl font-semibold">
                                        {classroomDetails?.name ?? 'N/A'}
                                    </h1>
                                )}
                                {isLoading ? (
                                    <Skeleton width={150} />
                                ) : (
                                    <p className="text-xs text-gray-400">Created: {formatDate(classroomDetails?.createdDate) ?? 'Unknown'}</p>
                                )}
                            </div>
                            <div className="flex items-center justify-center gap-4">
                                <button
                                    onClick={() => setIsSharingClassCode(true)}
                                    className={`flex justify-center h-[80%] items-center p-4 font-semibold text-blue-500 text-sm border border-blue-500 bg-white-A700 rounded-lg hover:bg-blue-100 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100`}
                                >
                                    Share Class Code
                                </button>

                                {classroomDetails && (
                                    <ShareClassCode
                                        open={isSharingClassCode}
                                        onClose={() => setIsSharingClassCode(false)}
                                        classTokenInfo={classroomDetails.classTokens?.sort((a, b) => new Date(b.generatedUtc) - new Date(a.generatedUtc))[0]} // GETTING MOST RECENT TOKEN
                                        className={classroomDetails.name}
                                        classroomID={classroomID}
                                    />
                                )}
                                {/* <Button color="blue_gray_100" size="2xl" variant="outline" shape="round" className="w-[40px] border">
                                <Img src="/images/img_search.svg" />
                            </Button> */}
                            </div>
                        </div>
                    </div>

                    <div
                        ref={scrollRef} // Step 3
                        className="overflow-hidden h-full 2xl:overflow-auto bg-white-A700 rounded-lg shadow-md"
                    >
                        <Tabs className={"h-[75vh] rounded-lg"}>
                            <TabList className="flex border-b border-t border-gray-300 gap-4 px-4 overflow-hidden">
                                <Tab className="text-sm p-2 cursor-pointer text-gray-800 hover:border-b-2 hover:border-blue-200" selectedClassName="border-b-2 font-semibold border-blue-500 hover:border-blue-500 text-blue-400">Assigned Courses</Tab>
                                <Tab className="text-sm p-2 cursor-pointer text-gray-800 hover:border-b-2 hover:border-blue-200" selectedClassName="border-b-2 font-semibold border-blue-500 hover:border-blue-500 text-blue-400">Students</Tab>
                                <Tab className="text-sm p-2 cursor-pointer text-gray-800 hover:border-b-2 hover:border-blue-200" selectedClassName="border-b-2 font-semibold border-blue-500 hover:border-blue-500 text-blue-400">Instructors</Tab>
                                <Tab className="text-sm p-2 cursor-pointer text-gray-800 hover:border-b-2 hover:border-blue-200" selectedClassName="border-b-2 font-semibold border-blue-500 hover:border-blue-500 text-blue-400">Classroom Performance</Tab>
                                <Tab className="text-sm p-2 cursor-pointer text-gray-800 hover:border-b-2 hover:border-blue-200" selectedClassName="border-b-2 font-semibold border-blue-500 hover:border-blue-500 text-blue-400">Student Performance</Tab>
                                <Tab className="text-sm p-2 cursor-pointer text-gray-800 hover:border-b-2 hover:border-blue-200" selectedClassName="border-b-2 font-semibold border-blue-500 hover:border-blue-500 text-blue-400">Assignments</Tab>
                            </TabList>
                            <TabPanel className={"overflow-hidden"}>
                                {isLoading ? (
                                    <></>
                                ) : (
                                    <div className="flex h-full">
                                        <AssignedCourses classroomData={classroomDetails ?? {}} reloadClassroomDetails={fetchData} />
                                    </div>

                                )}
                            </TabPanel>
                            <TabPanel className={"overflow-hidden"}>
                                {isLoading ? (
                                    <></>
                                ) : (
                                    <Students classroomData={classroomDetails ?? {}} />
                                )}
                            </TabPanel>
                            <TabPanel className={"overflow-hidden"}>
                                {isLoading ? (
                                    <></>
                                ) : (
                                    <Instructors classroomData={classroomDetails ?? {}} />
                                )}
                            </TabPanel>
                            <TabPanel className={"overflow-hidden"}>
                                {isLoading ? (
                                    <></>
                                ) : (
                                    <ClassroomPerformance classroomData={classroomDetails ?? {}} />
                                )}
                            </TabPanel>
                            <TabPanel className={"overflow-hidden"}>
                                {isLoading ? (
                                    <></>
                                ) : (
                                    <StudentPerformance classroomData={classroomDetails ?? {}} />
                                )}
                            </TabPanel>
                            <TabPanel className={"overflow-hidden"}>
                                {isLoading ? (
                                    <></>
                                ) : (
                                    <Assignments classroomData={classroomDetails ?? {}} />
                                )}
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
}
