// src/components/Assignments.jsx

import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { IconSortAscending, IconSortDescending } from '@tabler/icons-react';
import { format } from 'date-fns';
import { fetchSectionDetails } from "TeacherAPIService"; // Import the new API function
import { useNavigate } from "react-router-dom";
import QuickLink from "components/QuickLink";
import LoadingAnimation from "components/LoadingAnimation";

const Assignments = ({ classroomData }) => {
    const [isAscending, setIsAscending] = useState(true); // For sorting by due date
    const [assignmentsWithSections, setAssignmentsWithSections] = useState([]); // To store assignments with section titles
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const navigate = useNavigate();

    useEffect(() => {
        const loadAssignmentsWithSections = async () => {
            setIsLoading(true); // Start loading
            try {
                const assignmentsWithSections = await Promise.all(
                    classroomData.assignments.map(async (assignment) => {
                        try {
                            const section = await fetchSectionDetails(assignment.sectionId);
                            return {
                                ...assignment,
                                sectionName: section.name, // Assuming the section name is in `section.name`
                            };
                        } catch (error) {
                            if (error.status === 401) {
                                navigate("/timeout");
                            }
                            console.error(`Failed to fetch section for assignment ${assignment.id}`, error);
                            return {
                                ...assignment,
                                sectionName: 'Unknown Section', // Fallback if fetching the section fails
                            };
                        }
                    })
                );
                setAssignmentsWithSections(assignmentsWithSections);
            } catch (error) {
                console.error("Failed to load assignments with sections", error);
                // Optionally, you can set an error state here to display an error message
            } finally {
                setIsLoading(false); // Stop loading
            }
        };

        loadAssignmentsWithSections();
    }, [classroomData.assignments, navigate]);

    const sortedAssignments = assignmentsWithSections.sort((a, b) => {
        const dateA = new Date(a.dueUTC);
        const dateB = new Date(b.dueUTC);
        return isAscending ? dateA - dateB : dateB - dateA;
    });

    const handleSortToggle = () => {
        setIsAscending(!isAscending);
    };

    return (
        <div className="flex overflow-hidden divide-x h-[70vh] w-full">
            <div className="flex flex-col h-full w-[75%] overflow-hidden p-6">
                {isLoading ? (
                    <div className="flex h-full w-full justify-center items-center">
                        <LoadingAnimation />
                    </div>
                ) : (
                    <div className="flex h-full overflow-auto">
                        <table className="min-w-full bg-white border border-gray-200 rounded-lg">
                            <thead className="sticky top-0 bg-gray-100">
                                <tr>
                                    <th className="py-2 px-4 border-b text-left text-sm font-medium text-gray-700">
                                        Section
                                    </th>
                                    <th
                                        className="py-2 px-4 border-b text-left text-sm font-medium text-gray-700 cursor-pointer flex items-center"
                                        onClick={handleSortToggle}
                                    >
                                        Due Date
                                        {isAscending ? (
                                            <IconSortAscending className="ml-1" />
                                        ) : (
                                            <IconSortDescending className="ml-1" />
                                        )}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedAssignments.map((assignment) => (
                                    <tr key={assignment.id} className="hover:bg-gray-50">
                                        <td className="py-2 px-4 border-b text-sm text-gray-800 whitespace-nowrap">
                                            {assignment.sectionName}
                                        </td>
                                        <td className="py-2 px-4 border-b text-sm text-gray-800 whitespace-nowrap">
                                            {format(new Date(assignment.dueUTC), 'MMM dd, yyyy')}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            <div className="flex-col md:w-[25%] h-full p-4 hidden md:flex">
                <QuickLink classroomData={classroomData} />
            </div>
        </div>
    );
};

// PropTypes for type checking
Assignments.propTypes = {
    classroomData: PropTypes.shape({
        assignments: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                sectionId: PropTypes.string.isRequired,
                dueUTC: PropTypes.string.isRequired,
                // Add other assignment properties as needed
            })
        ).isRequired,
        // Add other classroomData properties as needed
    }).isRequired,
};

export default Assignments;
