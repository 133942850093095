import React, { useEffect, useRef, memo } from 'react';

function StockNews({ stockInfo }) {
    const container = useRef(null);

    useEffect(() => {
        if (stockInfo) {
            const script = document.createElement('script');
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js';
            script.type = 'text/javascript';
            script.async = true;
            script.innerHTML = JSON.stringify({
                "feedMode": "symbol",
                "symbol": `${stockInfo.profile?.exchange}:${stockInfo.profile?.symbol}`,
                "isTransparent": true,
                "displayMode": "regular",
                "width": "100%",
                "height": "100%",
                "colorTheme": "light",
                "locale": "en"
            });
            container.current.appendChild(script);
        }
    }, [stockInfo.symbol, stockInfo]);

    return (
        <div className="tradingview-widget-container" ref={container}>
            <div className="tradingview-widget-container__widget"></div>
        </div>
    );
}

export default memo(StockNews);
