import React, { useState, useEffect, useCallback } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { formatUSD } from 'utils';

const PortfolioChart = ({ portfolioData }) => {
    const [filteredData, setFilteredData] = useState([]);
    const [activeRange, setActiveRange] = useState('1W');
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        // Initial check and add resize listener
        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        // Cleanup on unmount
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    const filterData = useCallback(
        (range) => {
            const now = new Date();
            let filtered = [];

            switch (range) {
                case '1W':
                    filtered = portfolioData.historicalGraphPoints.filter(point => {
                        const date = new Date(point.dateTime);
                        return now - date <= 7 * 24 * 60 * 60 * 1000;
                    });
                    break;
                case '1M':
                    filtered = portfolioData.historicalGraphPoints.filter(point => {
                        const date = new Date(point.dateTime);
                        return now - date <= 30 * 24 * 60 * 60 * 1000;
                    });
                    break;
                case '6M':
                    filtered = portfolioData.historicalGraphPoints.filter(point => {
                        const date = new Date(point.dateTime);
                        return now - date <= 6 * 30 * 24 * 60 * 60 * 1000;
                    });
                    break;
                case '1Y':
                    filtered = portfolioData.historicalGraphPoints.filter(point => {
                        const date = new Date(point.dateTime);
                        return now - date <= 365 * 24 * 60 * 60 * 1000;
                    });
                    break;
                case 'All':
                default:
                    filtered = portfolioData.historicalGraphPoints;
                    break;
            }

            setFilteredData(
                filtered.map(point => ({
                    date: new Date(point.dateTime).toISOString(),
                    value: point.portfolioValue,
                }))
            );
        },
        [portfolioData]
    );

    useEffect(() => {
        if (portfolioData && portfolioData.historicalGraphPoints) {
            filterData('1W'); // Default filter
        }
    }, [portfolioData, filterData]);

    const handleRangeChange = (range) => {
        setActiveRange(range);
        filterData(range);
    };

    const options = {
        chart: {
            type: 'area',
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'straight',
        },
        labels: filteredData.map(d => d.date),
        xaxis: {
            type: 'datetime',
            labels: {
                show: false,
            },
        },
        yaxis: {
            opposite: true,
            labels: {
                show: !isSmallScreen,
                formatter: (val) => formatUSD(val),
            },
        },
        legend: {
            horizontalAlign: 'left',
        },
        tooltip: {
            y: {
                formatter: (val) => formatUSD(val),
            },
        },
        fill: {
            type: 'gradient',
        },
        colors: ['#2e90fa'],
    };

    const series = [
        {
            name: "Portfolio Value",
            data: filteredData.map(d => d.value),
        },
    ];

    return (
        <div className="flex flex-col h-full grow">
            <div className="h-[90%]">
                <Chart
                    options={options}
                    series={series}
                    type="area"
                    height="90%"
                    width="100%"
                />
            </div>
            <div className="flex justify-center lg:gap-2 2xl:gap-4">
                {['1W', '1M', '6M', '1Y', 'All'].map(range => (
                    <button
                        key={range}
                        className={`rounded transition-colors duration-300 ${
                            activeRange === range
                                ? 'bg-blue-500 text-white-A700'
                                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                        } py-1 px-2 lg:py-2 lg:px-4 text-xs xl:text-base`}
                        onClick={() => handleRangeChange(range)}
                    >
                        {range}
                    </button>
                ))}
            </div>
        </div>
    );
};

PortfolioChart.propTypes = {
    portfolioData: PropTypes.shape({
        historicalGraphPoints: PropTypes.arrayOf(
            PropTypes.shape({
                dateTime: PropTypes.string.isRequired,
                portfolioValue: PropTypes.number.isRequired,
            })
        ).isRequired,
    }).isRequired,
};

export default PortfolioChart;
