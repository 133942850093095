// TODO: Issue with plurals! Maybe add an optional parameter that's amount?
function ConvertDuration(d) {
    switch (d) {
        case 0: return "seconds";
        case 1: return "minutes";
        case 2: return "hours";
        case 3: return "days";
        case 4: return "weeks";
        case 5: return "months";
        default: return "";
    }
}

// shuffle from https://stackoverflow.com/a/12646864
function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function formatNumber(num) {
    if (num >= 1000000000000) {
        return (num / 1000000000000).toFixed(1) + 'T';
    }
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1) + 'B';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
    }
    return num;
}

export function formatDate(dateString) {
    if (dateString) {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            timeZone: 'UTC',
            year: 'numeric',
            month: 'long',
            day: '2-digit',
        }).format(date);
    }
}

const LESSON_ARTICLE = 1;
const LESSON_MEDIA = 0;

/**
 * Formats a number into USD currency format.
 *
 * @param {number} amount - The numeric value to format.
 * @returns {string} The formatted currency string.
 *
 * @example
 * formatUSD(1500.5);    // "$1,500.50"
 * formatUSD(-1500.5);   // "-$1,500.50"
 */
const formatUSD = (amount, cents = true) => {
    const digits = cents ? 2 : 0;

    // Ensure the input is a number
    const number = Number(amount);
    if (isNaN(number)) {
        console.error(`Invalid amount: ${amount}. Expected a number.`);
        return '$0.00';
    }

    // Create a new Intl.NumberFormat instance for USD
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: digits, // Ensures two decimal places
        maximumFractionDigits: digits,
    });

    return formatter.format(number);
};



export { ConvertDuration, shuffle, LESSON_ARTICLE, LESSON_MEDIA, formatUSD }