// TeacherDashboard.jsx
import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import Sidebar1 from "components/Sidebar1";
import HomeDate from "components/HomeDate";
import ClassPopup from "components/CreateClassPopup";
import { fetchClasses } from "TeacherAPIService";
import TeacherDashboardTable from "components/TeacherDashboardTable";
import LoadingAnimation from "components/LoadingAnimation";

export default function TeacherDashboard() {

  const [classrooms, setClassrooms] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  const handleClassCreated = useCallback(() => {
    const fetchClassrooms = async () => {
      setIsLoading(true); // Start loading
      try {
        const fetchedClassrooms = await fetchClasses();
        setClassrooms(fetchedClassrooms);
        console.log('fetchedClassrooms:', fetchedClassrooms);
      } catch (error) {
        if (error.status === 401) {
          navigate("/timeout");
        } else {
          console.error('Error fetching data:', error);
        }
      } finally {
        setIsLoading(false); // End loading
      }
    };
    fetchClassrooms();
  }, [navigate]);

  useEffect(() => {
    handleClassCreated();
  }, [handleClassCreated]);

  return (
    <>
      <Helmet>
        <title>Dashboard | Intertwined</title>
        <meta name="description" content="Intertwined Finance" />
      </Helmet>

      <div className="relative w-full h-screen bg-mesh-gradient">
        <div className="flex w-full h-full overflow-hidden">
          {/* Sidebar */}
          <Sidebar1 />

          <div className="flex flex-col flex-grow h-full p-4 gap-4">
            <HomeDate className="w-full px-4 rounded-lg shadow-md" />

            <div className="flex flex-col h-full gap-4 ">
              <div className="flex justify-end">

                <button
                  onClick={() => setPopupOpen(true)}
                  className={`flex justify-center h-full items-center shadow-md px-6 py-3 font-inter font-semibold text-primary text-md bg-white-A700 rounded-lg hover:bg-gray-100 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100`}
                >
                  Add Class
                </button>
              </div>

              <div className="flex flex-col flex-grow border border-gray-300 rounded-lg bg-white-A700 shadow-md">
                <div className="flex justify-end min-h-[45px] px-4 border-b flex-none">
                  <button>
                    {/* <p className="p-2 font-bold text-xl">⋮</p> */}
                  </button>
                </div>

                <div className="border border-white-A700 flex-grow relative rounded-lg ">
                  {isLoading ? (
                    // Loading Animation
                    <div className="flex items-center justify-center h-full">
                      <LoadingAnimation />
                    </div>
                  ) : (
                    // Table Content
                    <TeacherDashboardTable classrooms={classrooms} />
                  )}
                </div>
              </div>
            </div>

            <ClassPopup
              open={isPopupOpen}
              onClose={() => setPopupOpen(false)}
              onClassCreated={handleClassCreated}
            />
          </div>
        </div>
      </div>
    </>
  );
}
