import React, { useEffect, useState } from "react";
import { Heading } from "..";

export default function StartupProgressSteps({sessionData, dashData, stageNumber}) {
  const [stage1BubbleState, setStage1BubbleState] = useState("");
  const [stage2BubbleState, setStage2BubbleState] = useState("");
  const [stage3BubbleState, setStage3BubbleState] = useState("");
  const [stage4BubbleState, setStage4BubbleState] = useState("");
  const [stage5BubbleState, setStage5BubbleState] = useState("");

  const [stage1WordState, setStage1WordState] = useState("");
  const [stage2WordState, setStage2WordState] = useState("");
  const [stage3WordState, setStage3WordState] = useState("");
  const [stage4WordState, setStage4WordState] = useState("");
  const [stage5WordState, setStage5WordState] = useState("");

  const [stage1StatusState, setStage1StatusState] = useState("");
  const [stage2StatusState, setStage2StatusState] = useState("");
  const [stage3StatusState, setStage3StatusState] = useState("");
  const [stage4StatusState, setStage4StatusState] = useState("");
  const [stage5StatusState, setStage5StatusState] = useState("");

  let notStartedBubbleClass = "flex flex-col items-center justify-center w-11 h-11 border-2 border-gray-500_02 !text-gray-500_02 rounded-full";
  let currentBubbleClass = "flex flex-col items-center justify-center w-11 h-11 border-2 border-blue-700 text-blue-700 rounded-full";
  let completedBubbleClass = "flex flex-col items-center justify-center w-11 h-11 bg-blue-700 text-white-A700 rounded-full";

  let notStartedWordClass = "!font-poppins !text-gray-500_02";
  let currentWordClass = "!font-poppins !text-blue-700";
  let completedWordClass = "!font-poppins !text-gray-800_02";

  useEffect(() => {
    switch(stageNumber){
      case 1:
        setStage1BubbleState(currentBubbleClass);
        setStage2BubbleState(notStartedBubbleClass);
        setStage3BubbleState(notStartedBubbleClass);
        setStage4BubbleState(notStartedBubbleClass);
        setStage5BubbleState(notStartedBubbleClass);

        setStage1WordState(currentWordClass);
        setStage2WordState(notStartedWordClass);
        setStage3WordState(notStartedWordClass);
        setStage4WordState(notStartedWordClass);
        setStage5WordState(notStartedWordClass);

        setStage1StatusState("In Progress: "+dashData.percentToStageComplete+"%");
        setStage2StatusState("");
        setStage3StatusState("");
        setStage4StatusState("");
        setStage5StatusState("");
        break;
      case 2:
        setStage1BubbleState(completedBubbleClass);
        setStage2BubbleState(currentBubbleClass);
        setStage3BubbleState(notStartedBubbleClass);
        setStage4BubbleState(notStartedBubbleClass);
        setStage5BubbleState(notStartedBubbleClass);

        setStage1WordState(completedWordClass);
        setStage2WordState(currentWordClass);
        setStage3WordState(notStartedWordClass);
        setStage4WordState(notStartedWordClass);
        setStage5WordState(notStartedWordClass);

        setStage1StatusState("Completed");
        setStage2StatusState("In Progress: "+dashData.percentToStageComplete+"%");
        setStage3StatusState("");
        setStage4StatusState("");
        setStage5StatusState("");
        break;
      case 3:
        setStage1BubbleState(completedBubbleClass);
        setStage2BubbleState(completedBubbleClass);
        setStage3BubbleState(currentBubbleClass);
        setStage4BubbleState(notStartedBubbleClass);
        setStage5BubbleState(notStartedBubbleClass);

        setStage1WordState(completedWordClass);
        setStage2WordState(completedWordClass);
        setStage3WordState(currentWordClass);
        setStage4WordState(notStartedWordClass);
        setStage5WordState(notStartedWordClass);

        setStage1StatusState("Completed");
        setStage2StatusState("Completed");
        setStage3StatusState("In Progress: "+dashData.percentToStageComplete+"%");
        setStage4StatusState("");
        setStage5StatusState("");
        break;
      case 4:
        setStage1BubbleState(completedBubbleClass);
        setStage2BubbleState(completedBubbleClass);
        setStage3BubbleState(completedBubbleClass);
        setStage4BubbleState(currentBubbleClass);
        setStage5BubbleState(notStartedBubbleClass);

        setStage1WordState(completedWordClass);
        setStage2WordState(completedWordClass);
        setStage3WordState(completedWordClass);
        setStage4WordState(currentWordClass);
        setStage5WordState(notStartedWordClass);

        setStage1StatusState("Completed");
        setStage2StatusState("Completed");
        setStage3StatusState("Completed");
        setStage4StatusState("In Progress: "+dashData.percentToStageComplete+"%");
        setStage5StatusState("");
        break;
      case 5:
        setStage1BubbleState(completedBubbleClass);
        setStage2BubbleState(completedBubbleClass);
        setStage3BubbleState(completedBubbleClass);
        setStage4BubbleState(completedBubbleClass);
        setStage5BubbleState(currentBubbleClass);

        setStage1WordState(completedWordClass);
        setStage2WordState(completedWordClass);
        setStage3WordState(completedWordClass);
        setStage4WordState(completedWordClass);
        setStage5WordState(currentWordClass);

        setStage1StatusState("Completed");
        setStage2StatusState("Completed");
        setStage3StatusState("Completed");
        setStage4StatusState("Completed");
        setStage5StatusState("In Progress: "+dashData.percentToStageComplete+"%");
        break;
      default:
        break;
    }
  }, [sessionData, stageNumber, completedBubbleClass, completedWordClass, currentBubbleClass, currentWordClass, dashData.percentToStageComplete, notStartedBubbleClass, notStartedWordClass]);

  return (
    <div className="flex rounded-lg border border-solid border-slate-300 bg-white-A700 shadow-md sm:flex-col m-3">
      <div className="flex w-full">
        <div className="flex flex-1 px-4 py-[19px] border-r border-slate-300 justify-content items-center">
          <div className={stage1BubbleState}>
            1
          </div>
          <div className="flex flex-col items-center gap-2  border-slate-300 px-[15px] sm:px-5">
            <div className="flex self-start">
              <Heading size="4xl" as="h4" className={stage1WordState}>
                Friends & Family
              </Heading>
            </div>
            <div className="flex-1 w-full self-start">
              <Heading size="3xl" as="h4" className="!text-gray-500_02">
                <span>{stage1StatusState}</span>
              </Heading>
            </div>
          </div>
          <div className="flex flex-col relative group">
            <div className="flex flex-col items-center justify-center w-4 h-4 bg-white-A700 text-xs border-2 border-gray-500_02 rounded-full">
              i   
            </div>
            <div style={{zIndex: 100}} className="absolute top-full mt-2 left-1/2 transform -translate-x-1/2 w-[calc(100%+15rem)] opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto bg-gray-800 text-white-A700 text-xs rounded py-1 px-2 whitespace-no-wrap">
              <Heading className="!font-poppins !text-white-A700 py-1">Friends & Family Round:</Heading>
              <Heading className="!font-poppins !text-white-A700 pb-1">Objective: {new Intl.NumberFormat('en-US').format(sessionData.stage1Number)} {sessionData.stage1Metric}</Heading>
              <p>An early phase where you build and test your idea, often supported by initial funding from close connections</p>
            </div>
          </div>
        </div>

        <div className="flex flex-1 px-4 py-[19px] border-r border-slate-300 justify-content items-center">
          <div className={stage2BubbleState}>
            2
          </div>
          <div className="flex flex-col items-center gap-2 border-blue_gray-100 px-[15px] sm:px-5">
            <div className="flex self-start">
              <Heading size="4xl" as="h4" className={stage2WordState}>
                Pre-Seed
              </Heading>
            </div>
            <div className="flex-1 w-full self-start">
              <Heading size="3xl" as="h4" className="!text-gray-500_02">
                <span>{stage2StatusState}</span>
              </Heading>
            </div>
          </div>
          <div className="relative group">
            <div className="flex flex-col items-center justify-center w-4 h-4 bg-white-A700 text-xs border-2 border-gray-500_02 rounded-full">
              i   
            </div>
            <div style={{zIndex: 100}} className="absolute top-full mt-2 left-1/2 transform -translate-x-1/2 w-[calc(100%+15rem)] opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto bg-gray-800 text-white-A700 text-xs rounded py-1 px-2 whitespace-no-wrap">
              <Heading className="!font-poppins !text-white-A700 py-1">Pre-Seed Round:</Heading>
              <Heading className="!font-poppins !text-white-A700 pb-1">Objective: {new Intl.NumberFormat('en-US').format(sessionData.stage2Number)} {sessionData.stage2Metric}</Heading>
              <p>A formative stage focused on refining your product and business model, aiming to gain initial traction with early adopters</p>
            </div>
          </div>
        </div>
        <div className="flex flex-1 px-4 py-[19px] border-r border-slate-300 justify-content items-center">
          <div className={stage3BubbleState}>
            3
          </div>
          <div className="flex flex-col items-center gap-2 border-blue_gray-100 px-[15px] sm:px-5">
            <div className="flex self-start">
              <Heading size="4xl" as="h4" className={stage3WordState}>
                Seed
              </Heading>
            </div>
            <div className="flex-1 w-full self-start">
              <Heading size="3xl" as="h4" className="!text-gray-500_02">
                <span>{stage3StatusState}</span>
              </Heading>
            </div>
          </div>
          <div className="relative group">
            <div className="flex flex-col items-center justify-center w-4 h-4 bg-white-A700 text-xs border-2 border-gray-500_02 rounded-full">
              i   
            </div>
            <div style={{zIndex: 100}} className="absolute top-full mt-2 left-1/2 transform -translate-x-1/2 w-[calc(100%+15rem)] opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto bg-gray-800 text-white-A700 text-xs rounded py-1 px-2 whitespace-no-wrap">
              <Heading className="!font-poppins !text-white-A700 py-1">Seed Round:</Heading>
              <Heading className="!font-poppins !text-white-A700 pb-1">Objective: {new Intl.NumberFormat('en-US').format(sessionData.stage3Number)} {sessionData.stage3Metric}</Heading>
              <p>A foundational stage where you launch your product to the masses, establish a customer base, and prepare for market growth</p>
            </div>
          </div>
        </div>
        <div className="flex flex-1 px-4 py-[19px] border-r border-slate-300 justify-content items-center">
          <div className={stage4BubbleState}>
            4
          </div>
          <div className="flex flex-col items-center gap-2 border-blue_gray-100 px-[15px] sm:px-5">
            <div className="flex self-start">
              <Heading size="4xl" as="h4" className={stage4WordState}>
                Series Funding
              </Heading>
            </div>
            <div className="flex-1 w-full self-start">
              <Heading size="3xl" as="h4" className="!text-gray-500_02">
                <span>{stage4StatusState}</span>
              </Heading>
            </div>
          </div>
          <div className="relative group">
            <div className="flex flex-col items-center justify-center w-4 h-4 bg-white-A700 text-xs border-2 border-gray-500_02 rounded-full">
              i   
            </div>
            <div style={{zIndex: 100}} className="absolute top-full mt-2 left-1/2 transform -translate-x-1/2 w-[calc(100%+15rem)] opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto bg-gray-800 text-white-A700 text-xs rounded py-1 px-2 whitespace-no-wrap">
              <Heading className="!font-poppins !text-white-A700 py-1">Series Funding Round:</Heading>
              <Heading className="!font-poppins !text-white-A700 pb-1">Objective: {new Intl.NumberFormat('en-US').format(sessionData.stage4Number)} {sessionData.stage4Metric}</Heading>
              <p>A growth phase where you expand operations, strengthen market presence, and scale your team to drive profitability</p>
            </div>
          </div>
        </div>
        <div className="flex flex-1 px-4 py-[19px] justify-content items-center">
          <div className={stage5BubbleState}>
            5
          </div>
          <div className="flex flex-col items-center gap-2 border-blue_gray-100 px-[15px] sm:px-5">
            <div className="flex self-start">
              <Heading size="4xl" as="h4" className={stage5WordState}>
                IPO
              </Heading>
            </div>
            <div className="flex-1 w-full self-start">
              <Heading size="3xl" as="h4" className="!text-gray-500_02">
                <span>{stage5StatusState}</span>
              </Heading>
            </div>
          </div>
          <div className="relative group">
            <div className="flex flex-col items-center justify-center w-4 h-4 bg-white-A700 text-xs border-2 border-gray-500_02 rounded-full">
              i   
            </div>
            <div style={{zIndex: 100}} className="absolute top-full mt-2 left-1/2 transform -translate-x-1/2 w-[calc(100%+15rem)] opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto bg-gray-800 text-white-A700 text-xs rounded py-1 px-2 whitespace-no-wrap">
              <Heading className="!font-poppins !text-white-A700 py-1">IPO Round:</Heading>
              <Heading className="!font-poppins !text-white-A700 pb-1">Objective: {new Intl.NumberFormat('en-US').format(sessionData.stage5Number)} {sessionData.stage5Metric}</Heading>
              <p>A mature stage where your company becomes publicly traded, marking a new era of growth and shareholder accountability</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
