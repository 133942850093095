import React, { useEffect, useState } from "react";
import { Heading, Img } from "./..";
import { useNavigate } from "react-router-dom";
import { fetchStudentStatus } from "StudentAPIService";
import ProgressBar from "components/ProgressBar";

// TODO: Progress Bar

const colors = {
  "orange": {
    bg: "bg-yellow-100_01",
    image: "/images/img_currency_dollar.svg",
    border: "border-deep_orange-900_87",
    text: "!text-deep_orange-900_7f",
    progress: "bg-deep_orange-900",
    progressbg: "bg-deep_orange-900_02",
  },
  "blue": {
    bg: "bg-blue-50_04",
    image: "/images/img_line_chart_up_03.svg",
    border: "border-blue-900_87",
    text: "!text-blue-900_7f",
    progress: "bg-blue-900_01",
    progressbg: "bg-blue-900_02",
  },
  "pink": {
    bg: "bg-purple-50",
    image: "/images/img_graduation_hat_02.svg",
    border: "border-pink-800_87",
    text: "!text-pink-800_7f",
    progress: "bg-pink-800",
    progressbg: "bg-pink-800_01",
  }
};

const colorKeys = Object.keys(colors); // Get array of color keys

// Function to generate a seeded random number based on a string (courseName)
function seededRandom(seed) {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

export default function CourseCard({
  courseName,
  to,
  className,
  expectedDuration,
  durationUnit,
  courseId,
  studentId,
  ...props
}) {
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(to);
  };
  const [status, setStatus] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getStatus = async () => {
      setLoading(true);
      if (studentId && courseId) {
        try {
          const data = await fetchStudentStatus(studentId, courseId);
          if (data) {
            setStatus(data);
          }
        } catch (error) {
          console.error('Fetching status failed', error);
        } finally {
          setLoading(false);
        }
      }
    };
    getStatus();
  }, [studentId, courseId]);

  // Seed based on courseName
  const seed = courseName.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
  const colorIndex = Math.floor(seededRandom(seed) * colorKeys.length);
  const assignedColor = colorKeys[colorIndex];

  return (
    <div {...props} className={`${props.className} flex border border-gray-200_03 w-full rounded-lg course-card-hover`} onClick={handleCardClick} style={{ cursor: 'pointer' }}>
      <div className={`flex flex-col items-start w-full rounded-lg ${colors[assignedColor].bg} gap-2 p-2`}>

        <div className="flex w-full h-full justify-between ">

          <div className="hidden lg:flex flex-col self-stretch justify-end items-end ">
            <h1 className={`whitespace-nowrap rounded border font-semibold font-inter text-[10px] xl:text-xs border-solid ${colors[assignedColor].border} p-1 ${colors[assignedColor].text} opacity-0.8`}>
              {className}
            </h1>
          </div>


          <Img
            src={colors[assignedColor].image}
            alt="course icon"
            className="hidden xl:block h-[30px] w-[30px] xl:h-[40px] xl:w-[40px] 2xl:h-[60px] 2xl:w-[60px] items-end"
          />
        </div>

        <div className=" w-full">
          <h1 className="text-xs 2xl:text-lg font-semibold">
            {courseName}
          </h1>

          <div className="flex items-center gap-4">
            <Heading as="h4" className={colors[assignedColor].text}>
              {`${expectedDuration} ${durationUnit}`}
            </Heading>

            <div className="flex lg:hidden flex-col self-stretch justify-end items-end ">
              <h1 className={`whitespace-nowrap rounded border font-semibold font-inter text-[10px] xl:text-xs border-solid ${colors[assignedColor].border} p-1 ${colors[assignedColor].text} opacity-0.8`}>
                {className}
              </h1>
            </div>
          </div>


          {isLoading ? (
            // **Loading Indicator**
            <div className="flex w-full items-center gap-2 transition">
              <div className={`w-full ${colors[assignedColor].progressbg} rounded-full h-1 dark:bg-gray-700 animate-pulse`}>
              </div>
            </div>
          ) : (
            // **Progress Bar**
            status && status.courseStatus && (
              <ProgressBar color={colors[assignedColor].progress} backgroundColor={colors[assignedColor].progressbg} progress={status.courseStatus.percentageComplete} />
            )
          )}
        </div>

      </div>
    </div>
  );
}
