import React, { useState, useContext } from "react";
import JoinClassPopup from "../JoinClassPopup";
import CurrentDate from "components/CurrentDate";
import { UserContext } from 'UserContext';

export default function HomeDate({ ...props }) {
  const [showPopup, setShowPopup] = useState(false);
  const { userInfo } = useContext(UserContext);

  const handleJoinClassClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && <JoinClassPopup onClose={closePopup} />}
      <header
        {...props}
        className={`${props.className} flex font-inter items-center p-2 border-blue_gray-100 bg-white-A700`}
      >
        <div className="flex w-full justify-between">
          <div className="flex text-md p-2 items-center rounded-lg text-gray-800_01">
            <CurrentDate />
          </div>
          <div className="flex justify-end py-2">
            <div className="flex justify-center rounded-lg cursor-pointer">
              {userInfo?.role === 'Student' && (
                <div className="flex rounded-lg">

                  <button
                    className="!font-medium font-inter text-sm tracking-[0.10px] text-gray-800_01 hover:text-blue-500 active:text-blue-600 active:scale-95"
                    onClick={handleJoinClassClick}
                  >
                    Have a Class Code?
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
