import React from "react";
import { flexRender, getCoreRowModel, useReactTable, getSortedRowModel } from "@tanstack/react-table";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


const variants = {
  simple: "bg-white-A700",
};

const ReactTable = ({
  columns,
  data = [],
  headerProps = {},
  bodyProps = {},
  className = "",
  rowDataProps = { className: "" },
  variant,
  isLoading = false,
  expandedRows = {},
  handleExpandRow,
  orderType,
  ...restConfig
}) => {
  const tableConfig = {
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    ...restConfig,
  };

  const table = useReactTable(tableConfig);

  return (
    <table className={`${className} w-full  text-left table-auto overflow-x-auto text-xs bg-white-A700`}>
      <thead {...headerProps} className="text-xs sm:text-sm md:text-base">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => (
              <th 
                key={header.id} 
                {...header.column.columnDef?.meta} 
                className={`text-blue_gray-500 text-center px-2 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3 ${index === 0 ? 'w-[50px] min-w-[50px]' : ''}`}
              >
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody  className="text-xs sm:text-sm md:text-base">
        {isLoading ? (
          [...Array(10)].map((_, index) => (
            <tr key={index} className={`${variant ? variants[variant] : ""} ${rowDataProps?.className}`}>
              {columns.map((column, colIndex) => (
                <td 
                  key={colIndex} 
                  className={`text-center justify-center px-2 py-1 sm:py-2 md:py-3 ${colIndex === 0 ? 'w-[25px] ' : ''}`}
                >
                  <Skeleton />
                </td>
              ))}
            </tr>
          ))
        ) : (
          table.getRowModel().rows.map((row) => (
            <React.Fragment key={row.id}>
              <tr
                {...rowDataProps}
                className={`${variant ? variants[variant] : ""} ${rowDataProps?.className}`}
              >
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <td 
                    key={cell.id} 
                    className={`text-center text-sm justify-center px-1 py-4  ${cellIndex === 0 ? 'w-[10px]' : ''}`}
                  >
                    {cellIndex === 0 ? (
                      <div className="flex items-center justify-center text-xs whitespace-nowrap">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </div>
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </td>
                ))}
              </tr>
              {expandedRows[row.index] && (
                <tr key={`expanded-${row.id}`} className="bg-gray-100">
                  <td colSpan={columns.length} className="p-3 text-xs lg:text-sm md:text-base">
                    <div className="p-3 text-xs sm:text-sm md:text-base">
                      { row.original.explanation }
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))
        )}
      </tbody>
    </table>
  );
};

export { ReactTable };
