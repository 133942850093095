import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { Text, Heading, Img, Button } from "../../../../components";
import TopNavLogin from "components/TopNavLogin";
import LoginSideGraphic from "components/LoginSideGraphic";

export default function RegisterTeacherOnePage() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <>
      <Helmet>
        <title>Register | Intertwined</title>
        <meta name="description" content="Intertwined Finance" />
      </Helmet>

      <div className="flex h-screen overscroll-none overflow-hidden m-0">
        <div className="w-[75%] bg-white-A700 md:w-full relative md:absolute">
          <TopNavLogin className="absolute top-0 left-0 right-0 z-10 md:relative" />

          <div className="flex-1 flex flex-col gap-16 h-full justify-start items-center">
            <div className="flex h-[583px] items-center justify-center self-stretch bg-[url(/public/images/img_group_92.svg)] bg-cover bg-no-repeat px-14 pb-[72px] pt-[132px] md:h-auto md:p-5">
              <div className="flex w-[61%] justify-center rounded-[7px] border border-solid border-blue_gray-100 bg-white-A700 px-14 py-[71px] min-w-fit md:p-5">
                <Img
                  src="/images/img_avatars_3d_avatar_20.png"
                  alt="avatars3d"
                  className="h-[206px] w-[206px] object-cover"
                />
              </div>
            </div>

            <div className="flex flex-col items-center gap-4">
              <Heading size="15xl" as="h1" className="w-full text-center leading-[130%] !text-gray-900_05">
                <>
                  You can relax now!
                  <br />
                  We will reach out to you soon.
                </>
              </Heading>
              <Text as="p" className="!text-gray-900_05">
                A member of our team will reach out to you within 24 hours with login credentials.
              </Text>
            </div>

            <a href="/login" onClick={handleLoginClick}>

              <Button
                size="5xl"
                shape="round"
                className="min-w-[278px] border border-solid border-blue-A200 font-semibold shadow-sm sm:px-5"
              >
                Go To Login Page
              </Button>
            </a>



            <div className="flex w-[80%] gap-16 md:w-full md:flex-col">

            </div>

          </div>
        </div>

        <div className="w-[25%] md:hidden">
          <LoginSideGraphic />
        </div>
      </div>
    </>
  );
}
