import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils';

const TeacherDashboardTable = ({ classrooms }) => {

    return (
        <div className='relative'>
            <div className="w-full h-[73vh] overflow-auto overscroll-none rounded-lg">
                <table className='w-full table-auto'>
                    <thead className='sticky h-full top-0 shadow-sm text-accent bg-white-A700  font-semibold subpixel-antialiased'>
                        <tr >
                            <th className='py-4 sticky top-0'>Name</th>
                            <th >Status</th>
                            <th >Students</th>
                            <th >Created Date</th>
                            <th >Grade</th>
                        </tr>
                    </thead>
                    <tbody>
                        {classrooms.map((classItem) => (
                            <React.Fragment key={classItem.id}>
                                <tr className="hover:bg-gray-100 text-center">
                                    <td className=" p-4 border-b">
                                        <Link
                                            to={`/classroom/${classItem.id}`}
                                            className="text-blue-500 hover:text-blue-600"
                                        >
                                            {classItem.name}
                                        </Link>
                                    </td>
                                    <td className=" border-b">
                                        <span className={`py-2 px-4 ${classItem.status === "Active"
                                            ? "bg-green-500 text-white-A700"
                                            : classItem.status === "Not Started"
                                                ? "bg-red-400 text-white-A700"
                                                : classItem.status === "Completed"
                                                    ? "bg-green-200 text-green-800"
                                                    : "" // Default or other statuses
                                            } rounded`}>{classItem.status}</span>
                                    </td>
                                    <td className="py-2 px-4 border-b">{classItem.numStudents}</td>
                                    <td className="py-2 px-4 border-b">
                                        {formatDate(classItem.createdDate)}
                                    </td>
                                    <td className="py-2 px-4 border-b">{classItem.grade}</td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>

                </table>
            </div>
        </div>
    );
};

export default TeacherDashboardTable;


