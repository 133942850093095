import React, { useEffect, useState } from "react";
import { IconAlertCircle } from "@tabler/icons-react";
import { fetchStatus, postSettings } from "StartupSimAPIService";
import { useParams } from 'react-router-dom';
import LoadingAnimation from "components/LoadingAnimation";

export default function StartupSimSettings() {
  const { classroomID } = useParams();
  const [selectedDifficulty, setSelectedDifficulty] = useState('')
  const [currentMarketValueFail, setCurrentMarketValueFail] = useState('')
  const [employeeWellnessFail, setEmployeeWellnessFail] = useState('')
  const [grossRevenueFail, setGrossRevenueFail] = useState('')
  const [profitLossFail, setProfitLossFail] = useState('')
  const [difficultySet, setDifficultySet] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    try {
      fetchStatus(classroomID).then((promiseResult) => {
        setDifficultySet(promiseResult?.optionsSet)
        if (promiseResult?.options) {
          setSelectedDifficulty(promiseResult?.options.difficulty.charAt(0).toUpperCase() + promiseResult?.options.difficulty.slice(1))
          setCurrentMarketValueFail(promiseResult?.options.currentMarketValueFail);
          setEmployeeWellnessFail(promiseResult?.options.employeeWellnessFail);
          setGrossRevenueFail(promiseResult?.options.grossRevenueFail);
          setProfitLossFail(promiseResult?.options.profitLossFail);
        }
        setIsLoading(false);
        console.log(difficultySet)
        return;
      });
    } catch (error) {
      console.error('Error fetching status:', error);
    }
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "currentMarketValueFail":
        setCurrentMarketValueFail(value);
        break;
      case "employeeWellnessFail":
        setEmployeeWellnessFail(value);
        break;
      case "grossRevenueFail":
        setGrossRevenueFail(value);
        break;
      case "profitLossFail":
        setProfitLossFail(value);
        break;
      default:
        break;
    }
  };

  const handleUpdateSettings = async () => {
    if (selectedDifficulty === '' || currentMarketValueFail === '' || employeeWellnessFail === '' || grossRevenueFail === '' || profitLossFail === '') {
      alert("Please set values for all options before continuing");
      return;
    }
    setIsSubmitting(true);

    const settingData = {
      difficulty: selectedDifficulty,
      currentMarketValueFail: parseFloat(currentMarketValueFail),
      employeeWellnessFail: parseInt(employeeWellnessFail),
      grossRevenueFail: parseFloat(grossRevenueFail),
      profitLossFail: parseFloat(profitLossFail)
    }

    try {
      await postSettings(classroomID, settingData);
      setIsSubmitting(false);
      setDifficultySet(true);
      return;
    } catch (error) {
      console.error('Error posting trade:', error);
      setIsSubmitting(false);
      setErrorMessage(error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center bg-white-A700 rounded-lg">
        <LoadingAnimation />
      </div>

    )
  }

  return (
    <div className="flex flex-col p-4 gap-6 bg-white-A700 rounded-lg h-full w-full shadow-md text-accent">
      <div className="flex flex-col gap-2">
        <h1 className="text-3xl font-semibold">Startup Simulator Settings</h1>

        <div className="text-sm text-gray-600">
          <span>For a description of the different difficulties, please visit our <a target="_blank" className="inline text-blue-400 hover:underline" href="https://help.intertwinedfinance.com/en/articles/10223251-business-startup-simulator-101" rel="noreferrer">Support Site</a></span>
        </div>
      </div>

      {!isLoading && (
        <div className="flex flex-col gap-6 text-left w-1/2">
          {/* Difficulty */}
          {difficultySet ? (
            <div className="flex flex-col border rounded-lg divide-y">

              <div className="flex justify-between p-4 hover:bg-gray-100">
                <p>Difficulty</p>
                <p className="font-semibold">{selectedDifficulty}</p>
              </div>

              <div className="flex justify-between p-4 hover:bg-gray-100">
                <p>Current Market Value Fail Point</p>
                <p className="font-semibold">{currentMarketValueFail}</p>
              </div>

              <div className="flex justify-between p-4 hover:bg-gray-100">
                <p>Employee Wellness Fail Point</p>
                <p className="font-semibold">{employeeWellnessFail}</p>
              </div>

              <div className="flex justify-between p-4 hover:bg-gray-100">
                <p>Gross Revenue Fail Point</p>
                <p className="font-semibold">{grossRevenueFail}</p>
              </div>

              <div className="flex justify-between p-4 hover:bg-gray-100">
                <p>Profit/Loss Fail Point</p>
                <p className="font-semibold">{profitLossFail}</p>
              </div>

            </div>
          ) : (
            <>
              <div className="flex flex-col border rounded-lg divide-y">
                <div className="flex justify-between p-4 items-center">
                  <label className="">Difficulty</label>
                  <div className="border rounded-lg">
                    <select
                      className="w-40 items-end justify-end p-2 font-semibold"
                      value={selectedDifficulty}
                      onChange={(e) => setSelectedDifficulty(e.target.value)}
                    >
                      <option value="easy" >Easy</option>
                      <option value="medium" >Medium</option>
                      <option value="hard" >Hard</option>
                    </select>
                  </div>
                </div>

                <div className="flex justify-between p-4 items-center">
                  <div className="flex flex-col">
                    <label className="">Fail Point for Current Market Value</label>
                    <label className="text-xs text-gray-400">Student starts at <span className="font-semibold">$20,000</span></label>
                  </div>

                  <div className="border rounded-lg items-end justify-end p-2 font-semibold">
                    <input
                      required
                      type="text"
                      name="currentMarketValueFail"
                      value={currentMarketValueFail}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="flex justify-between p-4 items-center">
                  <div className="flex flex-col">
                    <label className="">Fail Point for Employee Wellness</label>
                    <label className="text-xs text-gray-400">Student starts at <span className="font-semibold">65/100</span></label>
                  </div>
                  <div className="border rounded-lg items-end justify-end p-2 font-semibold">
                    <input
                      required
                      type="text"
                      name="employeeWellnessFail"
                      value={employeeWellnessFail}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="flex justify-between p-4 items-center">
                  <div className="flex flex-col">
                    <label className="">Fail Point for Gross Revenue</label>
                    <label className="text-xs text-gray-400">Student starts at <span className="font-semibold">$10,000</span></label>
                  </div>
                  <div className="border rounded-lg items-end justify-end p-2 font-semibold">
                    <input
                      required
                      type="text"
                      name="grossRevenueFail"
                      value={grossRevenueFail}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="flex justify-between p-4 items-center">
                  <div className="flex flex-col">
                    <label className="">Fail Point for Profit/Loss</label>
                    <label className="text-xs text-gray-400">Student starts at <span className="font-semibold">-$5,000</span></label>
                  </div>
                  <div className="border rounded-lg items-end justify-end p-2 font-semibold">
                    <input
                      required
                      type="text"
                      name="profitLossFail"
                      value={profitLossFail}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              {/* Buttons */}
              <div className="flex gap-4 mt-4">
                <button
                  className="button-blue w-full"
                  onClick={handleUpdateSettings}
                  disabled={isSubmitting}
                >
                  <span className="text-white-A700">
                    {isSubmitting ? "Please wait..." : "Set Options"}
                  </span>
                </button>
              </div>

              {/* Error Messages */}
              {errorMessage && !difficultySet && (  // Only show error if no difficulty set
                <div className="mt-4 p-4 text-red-600 bg-red-100 border border-red-400 rounded">
                  <IconAlertCircle className="inline-block mr-2" />
                  {errorMessage}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
