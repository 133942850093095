import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Img, Heading, Text } from "../../components";
import { useNavigate } from "react-router-dom";
import {
  AccordionItemPanel,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemState,
  Accordion,
  AccordionItem,
} from "react-accessible-accordion";

export default function LearningViewTwoPage() {
  const navigate = useNavigate();
  const [expandedItems, setExpandedItems] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isWrongAnswer, setIsWrongAnswer] = useState(false);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);

  const handleBacktoCourses = () => {
    navigate('/courses');
  };

  const toggleItem = (index) => {
    const currentIndex = expandedItems.indexOf(index);
    const newExpandedItems = [...expandedItems];

    if (currentIndex === -1) {
      newExpandedItems.push(index);
    } else {
      newExpandedItems.splice(currentIndex, 1);
    }

    setExpandedItems(newExpandedItems);
  };

  const handleFinancialResponsibilityClick = () => {
    navigate('/learningviewtext');
  };

  const handleLessonQuizClick = () => {
    navigate('/learningviewquestionone');
  };

  const handleSubmitAnswer = () => {
    if (isCorrectAnswer) {
      navigate('/courses');
    } else {
      setIsSubmitted(true);
      if (selectedAnswer === "makinginformeddecisionsaboutspendingsavingandinvesting") {
        setIsCorrectAnswer(true);
      } else {
        setIsWrongAnswer(true);
      }
    }
  };

  const handleTryAgain = () => {
    setIsSubmitted(false);
    setSelectedAnswer(null);
    setIsWrongAnswer(false);
    setIsCorrectAnswer(false);
  };

  return (
    <>
      <Helmet>
        <title>Matt's Application12</title>
        <meta name="description" content="Intertwined Finance" />
      </Helmet>
      <div className="w-full bg-white-A700">
        <div className="bg-white-A700">
          <header className="flex items-center rounded-lg px-4 pt-4">
          <div className="flex w-full items-center justify-start border border-solid rounded-20 gap-6 pt-2.5 pb-2.5">
              <div onClick={handleBacktoCourses} className="flex items-center cursor-pointer">
                <Img
                  src="images/img_arrow_left_blue_a200.svg"
                  alt="arrowleft"
                  className="relative right-px top-0.5 h-[20px] mr-[-20px] ml-5"
                />
                <Text size="7xl" as="p" className="!text-blue-500 mt-1">
                  Back to all Courses
                </Text>
              </div>
              <div className="flex items-center gap-6 ml-auto mr-auto">
                <div className="flex items-center rounded border border-solid border-deep_purple-A200_01 bg-gray-50 p-[4px]">
                  <Heading as="p" className="!text-deep_purple-A200_01">
                    Unit 1
                  </Heading>
                </div>
                <Heading size="13xl" as="h2" className="text-center">
                  Financial Responsibility
                </Heading>
              </div>
            </div>
          </header>
          <div className="flex gap-2.5 rounded-lg p-4 md:flex-col">
          <div className="w-[26%] rounded-lg border border-solid border-indigo-50 bg-white-300_01 md:w-full">
              <Accordion allowZeroExpanded preExpanded={expandedItems} onChange={setExpandedItems} className="flex flex-col gap-2 rounded-lg bg-white-A700">
                {[...Array(5)].map((_, i) => (
                  <AccordionItem uuid={i} key={`row${i}`} className="border-b border-solid border-gray-300">
                    <div className="flex flex-1 flex-col gap-2">
                      <AccordionItemHeading className="w-full" onClick={() => toggleItem(i)}>
                        <AccordionItemButton className="py-4">
                          <AccordionItemState>
                            {(props) => (
                              <>
                                <div className="bg-white-A700 flex items-center justify-between px-4">
                                  <Img
                                    src="images/img_arrow_up_black_900.svg"
                                    alt="arrowdown"
                                    className={`h-[24px] w-[24px] ${props.expanded ? "" : "rotate-180"} mr-2`}
                                  />
                                  <Heading size="6xl" as="h1" className="w-auto leading-[130%]">
                                    Definition of Financial Responsibility
                                  </Heading>
                                  {props.expanded ? (
                                    <Img
                                      src="images/img_check_circle.svg"
                                      alt="image"
                                      className="h-[24px] w-[24px] ml-auto"
                                    />
                                  ) : (
                                    <Img
                                      src="images/img_check_circle_blue_gray_100_03.svg"
                                      alt="image"
                                      className="h-[24px] w-[24px] ml-auto"
                                    />
                                  )}
                                </div>
                              </>
                            )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="bg-white-A700 px-4">
                          <div className="rounded-tl-lg rounded-tr-lg bg-indigo-50 px-4">
                            <div className="flex items-center justify-between gap-5 border-b border-solid border-indigo-50 py-5">
                              <Heading size="6xl" as="h2" className="!font-bold">
                                Introduction
                              </Heading>
                              <Img
                                src="images/img_ondemand_video.svg"
                                alt="ondemandvideo"
                                className="h-[24px] w-[24px]"
                              />
                            </div>
                          </div>
                          <div className="bg-gray-50_01 px-4">
                            <Button
                              color="indigo_50"
                              size="7xl"
                              variant="outline"
                              shape="square"
                              rightIcon={
                                <Img
                                  src="images/img_library_books_gray_800_01.svg"
                                  alt="library books"
                                  className="h-[24px] w-[24px]"
                                />
                              }
                              className="w-full gap-[11px]"
                            >
                              Benefits of Financial Responsibility
                            </Button>
                          </div>
                          <div onClick={handleLessonQuizClick} className="flex items-center cursor-pointer justify-between gap-5 rounded-bl-lg rounded-br-lg bg-gray-50_01 px-4 pb-[19px] pt-5">
                            <Text size="7xl" as="p" className="self-end !text-black-900">
                              Lesson Quiz
                            </Text>
                            <Img src="images/img_bookmark.svg" alt="bookmark" className="h-[24px] w-[24px]" />
                          </div>
                          <div style={{ marginBottom: '20px' }}></div>
                        </div>
                      </AccordionItemPanel>
                    </div>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
            <div className="flex flex-1 flex-col items-start gap-6 rounded-lg pt-2 md:self-stretch">
              <Button
                color="undefined_undefined"
                leftIcon={<Img src="images/img_megaphone.svg" alt="megaphone" className="h-[18px] w-[18px]" />}
                className="ml-4 min-w-[147px] gap-2 rounded-[20px] font-semibold tracking-[0.10px] md:ml-0 sm:pr-5"
              >
                Close Menu
              </Button>
              <div className="self-stretch rounded-lg bg-white-A700 px-2">
                <div className="rounded-lg border border-solid border-indigo-50 bg-white-A700">
                  <div className="flex flex-col items-center gap-[31px] rounded-lg bg-white-A700 px-4 pb-4 pt-6 sm:pt-5">
                    <div className="flex w-[97%] justify-between gap-5 md:w-full">
                      <div className="flex items-center gap-[19px]">
                        <Img
                          src="images/img_edit_05_blue_gray_700_02.svg"
                          alt="editfive"
                          className="h-[24px] w-[24px]"
                        />
                        <Text as="p" className="self-end !text-blue_gray-500">
                          <span className="text-blue_gray_700_02">Questions 1 /</span>
                          <span className="text-blue_gray-500">&nbsp;</span>
                          <span className="text-blue_gray_300">4</span>
                        </Text>
                      </div>
                      <div className="flex">
                        <Text as="p" className="!text-red-500">
                          2 Attempt Left
                        </Text>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 self-stretch rounded-lg bg-gray-100_01 px-4 py-12 md:py-5">
                      <div className="flex items-start gap-2.5 self-start px-4 sm:flex-col">
                        <div className="flex flex-col px-2 pb-1.5 pt-2">
                          <Heading as="h3" className="text-[22px]">1.</Heading>
                        </div>
                        <Heading size="9xl" as="h4" className="self-end">
                          What does financial responsibility involve?
                        </Heading>
                      </div>
                      <div className="flex flex-col px-4">
                        <div
                          className={`flex-1 gap-[22px] rounded-lg py-[30px] pr-[35px] pl-2 text-base text-black-900 sm:py-5 sm:pr-5 ${
                            isSubmitted && selectedAnswer === "accumulatingasmuchdebtaspossible" ? "bg-red-500" : "bg-white-A700"
                          }`}
                          style={{ margin: '10px', padding: '20px' }}
                        >
                          <label className="flex cursor-pointer">
                            <input
                              className="mr-2 h-[24px] w-[24px] mr-5"
                              type="radio"
                              name="question"
                              value="accumulatingasmuchdebtaspossible"
                              onChange={() => setSelectedAnswer("accumulatingasmuchdebtaspossible")}
                              disabled={isCorrectAnswer || isWrongAnswer}
                            />
                            <span className="shift-left">Accumulating as much debt as possible.</span>
                          </label>
                        </div>
                        <div
                          className={`flex-1 gap-[22px] rounded-lg py-[30px] pr-[35px] pl-2 text-base text-black-900 sm:py-5 sm:pr-5 ${
                            isSubmitted && selectedAnswer === "ignoringfinancialdecisionsandgoals" ? "bg-red-500" : "bg-white-A700"
                          }`}
                          style={{ margin: '10px', padding: '20px' }}
                        >
                          <label className="flex cursor-pointer">
                            <input
                              className="mr-2 h-[24px] w-[24px] mr-5"
                              type="radio"
                              name="question"
                              value="ignoringfinancialdecisionsandgoals"
                              onChange={() => setSelectedAnswer("ignoringfinancialdecisionsandgoals")}
                              disabled={isCorrectAnswer || isWrongAnswer}
                            />
                            <span className="shift-left">Ignoring financial decisions and goals.</span>
                          </label>
                        </div>
                        <div
                          className={`flex-1 gap-[22px] rounded-lg py-[30px] pr-[35px] pl-2 text-base text-black-900 sm:py-5 sm:pr-5 ${
                            isSubmitted && selectedAnswer === "makinginformeddecisionsaboutspendingsavingandinvesting" ? "bg-green-500" : "bg-white-A700"
                          }`}
                          style={{ margin: '10px', padding: '20px' }}
                        >
                          <label className="flex cursor-pointer">
                            <input
                              className="mr-2 h-[24px] w-[24px] mr-5"
                              type="radio"
                              name="question"
                              value="makinginformeddecisionsaboutspendingsavingandinvesting"
                              onChange={() => setSelectedAnswer("makinginformeddecisionsaboutspendingsavingandinvesting")}
                              disabled={isCorrectAnswer || isWrongAnswer}
                            />
                            <span className="shift-left">Making informed decisions about spending, saving, and investing.</span>
                          </label>
                        </div>
                        <div
                          className={`flex-1 gap-[22px] rounded-lg py-[30px] pr-[35px] pl-2 text-base text-black-900 sm:py-5 sm:pr-5 ${
                            isSubmitted && selectedAnswer === "avoidinganyplanningforthefuture" ? "bg-red-500" : "bg-white-A700"
                          }`}
                          style={{ margin: '10px', padding: '20px' }}
                        >
                          <label className="flex cursor-pointer">
                            <input
                              className="mr-2 h-[24px] w-[24px] mr-5"
                              type="radio"
                              name="question"
                              value="avoidinganyplanningforthefuture"
                              onChange={() => setSelectedAnswer("avoidinganyplanningforthefuture")}
                              disabled={isCorrectAnswer || isWrongAnswer}
                            />
                            <span className="shift-left">Avoiding any planning for the future.</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pb-[235px] md:pb-5">
                    <div className="rounded-lg bg-white-A700">
                      <div className="flex justify-between gap-5 bg-white-A700 p-2.5">
                        <Button
                          color="undefined_undefined"
                          leftIcon={
                            <Img
                              src="images/img_arrowleft_blue_a200_1.svg"
                              alt="arrow_left"
                              className="h-[18px] w-[18px]"
                            />
                          }
                          className="min-w-[100px] gap-2 rounded-[20px] font-semibold tracking-[0.10px] pt-2 !text-blue-A400 sm:pr-5 cursor-pointer" onClick={handleFinancialResponsibilityClick}
                        >
                          Back
                        </Button>
                        {isSubmitted && isWrongAnswer && (
                          <div className="mt-4 flex flex-row items-center text-red-500">
                            <Text as="p">Incorrect answer. Please try again.</Text>
                            <Button onClick={handleTryAgain} className="px-10 py-5 rounded">
                              Try Again
                            </Button>
                          </div>
                        )}
                        <Button onClick={handleSubmitAnswer} className="rounded px-10 py-5">
                          {isCorrectAnswer ? "Finish Module" : "Submit Answer"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

