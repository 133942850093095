import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { createShareCode } from 'TeacherAPIService';
import { IconCopy, IconShare } from '@tabler/icons-react'

// const mockClassToken = {
//     id: "08dc711b-7374-484e-8be3-be0dbb31c48c",
//     token: "ZJWP-GDKY",
//     generatedUtc: "2024-05-10T18:03:09.3778056Z",
//     classId: "08dc7119-a8f2-4311-871d-f0352116fcc5"
// };

const ShareClassCode = ({ open, onClose, classTokenInfo, className, classroomID }) => {
    const [createdNewCode, setCreatedNewCode] = useState(false);
    const [classCode, setClassCode] = useState('');

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(classTokenInfo.token);
        } catch (err) {
        }
    };

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: `${className} Class Token`,
                    text: `${classTokenInfo.token}`,
                });
            } catch (error) {
                console.error('Error sharing content:', error);
            }
        } else {
            // Fallback: Copy URL to clipboard
            copyToClipboard()
            console.error('No sharing api')
        }
    };

    useEffect(() => {
        setClassCode(classTokenInfo?.token)

    }, [classTokenInfo?.token]);

    useEffect(() => {

    }, [classCode]);





    const handleNewCode = async () => {

        const fetchData = async () => {
            try {
                const newClassCode = await createShareCode(classroomID);
                setCreatedNewCode(true);
                setClassCode(newClassCode.token)
            } catch (error) {
                console.error('Error fetching classrooms:', error);
            } finally {

            }

        };

        fetchData();
    };



    return (
        <Popup open={open} closeOnDocumentClick onClose={onClose} modal contentStyle={{ width: '500px', height: '400px', borderRadius: '1rem' }}>
            <div className="flex flex-col items-center h-full p-4 gap-4 bg-white-A700 shadow-md border border-gray-200 rounded-lg">
                <h1 className='text-lg font-semibold text-accent'>{className}</h1>

                <div className='flex flex-col items-center justify-center h-full w-full gap-8'>
                    <h1 className='text-6xl text-accent font-semibold'>
                        {classCode}
                    </h1>

                    <button
                        onClick={copyToClipboard}
                        className="button-white gap-2 !h-fit"
                    >
                        <span className='text-md'>Copy Code</span>
                        <IconCopy />
                    </button>

                </div>




                <div className='flex gap-8 '>
                    <button
                        onClick={handleShare}
                        className="button-white gap-2"
                    >
                        <span className='text-md'>Share Class Code</span>
                        <IconShare />
                    </button>

                    <button
                        onClick={handleNewCode}
                        className="button-white"
                    >
                        <span className='text-md'>New Class Code</span>
                    </button>


                </div>

                {createdNewCode && (
                    <div>
                        <h2 className='text-md text-green-500 font-semibold'>Code Created Successfully</h2>
                    </div>
                )}


            </div>
        </Popup>
    );
};

export default ShareClassCode;