import { Helmet } from "react-helmet";
import { Text, Heading } from "../../../../components";
import TopNavLogin from "components/TopNavLogin";
import LoginSideGraphic from "components/LoginSideGraphic";
import ResetPasswordForm from "./ResetPasswordForm";

export default function ResetPasswordPage() {
    return (
        <>
            <Helmet>
                <title>Reset Password | Intertwined</title>
                <meta name="description" content="Intertwined Finance" />
            </Helmet>
            <div className="flex h-screen overscroll-none sm:overflow-auto m-0">
                <div className="w-[75%] bg-white-A700 md:w-full relative md:absolute">
                    <TopNavLogin className="absolute top-0 left-0 right-0 z-10 md:relative" type={"register"} />

                    <div className="flex-1 flex flex-col gap-16 h-full justify-center items-center">
                        <div className="gap-3 flex flex-col items-center">
                            <Heading size="12xl" as="h1" className="!text-gray-900_05 flex gap-2 items-center">
                                Reset Password
                            </Heading>
                            <Text as="p" className="!text-gray-900_05">
                                Enter your account email to get a link to reset your password.
                            </Text>
                            <Text as="p" className="mt-1.5 !text-gray-700_01">
                                <span className="text-gray-700_01">
                                    Your account email must be confirmed.
                                </span>
                            </Text>

                        </div>



                        <div className="flex items-center justify-center w-[80%] gap-16 md:w-full md:flex-col">
                            <ResetPasswordForm />
                        </div>

                    </div>
                </div>

                <div className="w-[25%] md:hidden">
                    <LoginSideGraphic />
                </div>
            </div>
        </>
    );
}
