import React, { useState, useEffect } from 'react';
import { Img } from 'components'; // Assuming you have Img component from your setup

const quotes = [
  {
    text: "Nearly 9 in 10 U.S. adults said high school did not leave them 'fully prepared' for handling money in the real world.",
    source: "Financial Literacy Crisis Report 2023"
  },
  {
    text: "Only 37% of U.S. adults could answer four out of five basic financial literacy questions correctly.",
    source: "FINRA Investor Education Foundation, National Financial Capability Study"
  },
  {
    text: "Roughly 44% of Americans wouldn’t be able to cover a $400 emergency with cash or its equivalent.",
    source: "Federal Reserve Board, Survey of Household Economics and Decisionmaking"
  },
  {
    text: "Only 24% of millennials demonstrate basic financial literacy skills, even though they are the largest adult generation in the workforce.",
    source: "FINRA Investor Education Foundation, Financial Capability Study"
  },
  {
    text: "About 3 in 5 U.S. adults do not maintain a budget or track their monthly spending.",
    source: "National Foundation for Credit Counseling Financial Literacy Survey"
  },
  {
    text: "Around two-thirds of adults worldwide are considered financially illiterate.",
    source: "S&P Global FinLit Survey"
  },
  {
    text: "On average, U.S. adults correctly answered only about half of the personal finance questions posed in a nationwide assessment.",
    source: "TIAA Institute-GFLEC Personal Finance Index"
  },
  {
    text: "Nearly half of all Americans feel anxious when thinking about their personal finances.",
    source: "National Endowment for Financial Education (NEFE) Survey"
  },
  {
    text: "Only 29% of young adults (ages 18-29) demonstrated basic financial knowledge related to concepts like inflation and interest.",
    source: "FINRA Investor Education Foundation, National Financial Capability Study"
  }
];

const DashboardQuote = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      // Start fade out
      setFadeIn(false);

      // After fade-out completes, change the quote and fade in again
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % quotes.length);
        setFadeIn(true);
      }, 500); // matches the transition duration
    }, 8000); // Change quotes every 8 seconds

    return () => clearInterval(interval);
  }, []);

  const currentQuote = quotes[currentIndex];

  return (
    <div className="flex flex-col p-4 gap-2 rounded-lg text-white-A700 bg-secondary bg-opacity-90 shadow-md">
      <div className="flex items-center gap-1">
        <Img
          src="images/img_new_releases.svg"
          alt="newreleases"
          className="h-[8px] w-[8px] xl:h-[10px] xl:w-[10px] invert"
        />
        <h1 className="flex text-[8px] 2xl:text-[16px] font-bold items-center">
          DID YOU KNOW?
        </h1>
      </div>

      <div
        className={`
          flex flex-col justify-between min-h-[50px] lg:min-h-[75px] items-end gap-3 
          transition-opacity duration-500 ease-in-out
          ${fadeIn ? 'opacity-100' : 'opacity-0'}
        `}
      >
        <h1 className="w-full text-[10px] lg:text-xs xl:text-md 2xl:text-md font-inter font-semibold text-white-A700">
          {currentQuote.text}
        </h1>
        <h2 className="text-[8px] lg:text-xs xl:text-sm">
          - {currentQuote.source}
        </h2>
      </div>
    </div>
  );
};

export default DashboardQuote;
