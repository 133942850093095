import React from "react";
import { Helmet } from "react-helmet";
import { Button, Img, Text, Radio, RadioGroup, Heading } from "../../components";
import {
  AccordionItemPanel,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemState,
  Accordion,
  AccordionItem,
} from "react-accessible-accordion";

export default function LearningViewFourPage() {
  return (
    <>
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Intertwined Finance" />
      </Helmet>
      <div className="w-full bg-white-A700">
        <div className="bg-white-A700">
          <header className="flex items-center rounded-lg px-4 pt-4">
            <div className="container-lg flex items-start justify-start gap-6 rounded-lg border border-solid border-indigo-50 pb-[11px] pt-5 md:flex-col">
              <div className="mt-[7px] flex items-start gap-2 md:mt-0">
                <Img src="images/img_arrow_left_blue_a200.svg" alt="arrowleft" className="h-[20px]" />
                <div className="flex">
                  <Text size="7xl" as="p" className="!text-blue-500">
                    Back to all Courses
                  </Text>
                </div>
              </div>
              <div className="relative left-1/2 flex items-start gap-6 sm:flex-col">
                <div className="mt-[5px] flex rounded border border-solid border-deep_purple-A200_01 bg-gray-50">
                  <div className="flex rounded p-[3px]">
                    <Heading as="p" className="!text-deep_purple-A200_01">
                      Unit 1
                    </Heading>
                  </div>
                </div>
                <Heading size="13xl" as="h2">
                  Financial Responsibility{" "}
                </Heading>
              </div>
            </div>
          </header>
          <div className="flex gap-2.5 rounded-lg p-4 md:flex-col">
            <div className="w-[26%] rounded-lg border border-solid border-indigo-50 bg-gray-300_01 md:w-full">
              <Accordion preExpanded={[0]} className="flex flex-col gap-2 rounded-lg bg-white-A700">
                {[...Array(5)].map((_, i) => (
                  <AccordionItem uuid={i} key={`row${i}`}>
                    <div className="flex flex-1 flex-col gap-2">
                      <AccordionItemHeading className="w-full">
                        <AccordionItemButton>
                          <AccordionItemState>
                            {(props) => (
                              <>
                                <div className="bg-white-A700">
                                  <div className="flex flex-wrap items-center justify-center gap-2.5 bg-white-A700 px-4">
                                    <Img
                                      src="images/img_arrow_up_black_900.svg"
                                      alt="arrowdown"
                                      className="h-[24px] w-[24px]"
                                    />
                                    <Heading size="6xl" as="h1" className="w-[82%] leading-[130%]">
                                      Definition of Financial Responsibility
                                    </Heading>
                                    {props?.expanded ? (
                                      <Img
                                        src="images/img_check_circle.svg"
                                        alt="image"
                                        className="relative bottom-10 left-[40%] h-[24px] w-[24px]"
                                      />
                                    ) : (
                                      <Img
                                        src="images/img_check_circle.svg"
                                        alt="image"
                                        className="h-[24px] w-[24px]"
                                      />
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="bg-white-A700 px-4">
                          <div className="rounded-tl-lg rounded-tr-lg bg-gray-50_01 px-4">
                            <div className="flex items-center justify-between gap-5 border-b border-solid border-indigo-50 py-5">
                              <Text size="7xl" as="p" className="!text-black-900">
                                Introduction
                              </Text>
                              <Img
                                src="images/img_ondemand_video.svg"
                                alt="ondemandvideo"
                                className="h-[24px] w-[24px]"
                              />
                            </div>
                          </div>
                          <div className="bg-gray-50_01 px-4">
                            <div className="flex items-center justify-between gap-5 border-b border-solid border-indigo-50 pb-[19px] pt-5">
                              <Text size="7xl" as="p" className="self-end !text-black-900">
                                Financial Responsibility
                              </Text>
                              <Img
                                src="images/img_library_books_gray_800_01.svg"
                                alt="librarybooks"
                                className="h-[24px] w-[24px] self-start"
                              />
                            </div>
                          </div>
                          <div className="bg-gray-50_01 px-4">
                            <Button
                              color="indigo_50"
                              size="7xl"
                              variant="outline"
                              shape="square"
                              rightIcon={
                                <Img
                                  src="images/img_library_books_gray_800_01.svg"
                                  alt="library books"
                                  className="h-[24px] w-[24px]"
                                />
                              }
                              className="w-full gap-[11px]"
                            >
                              Benefits of Financial Responsibility
                            </Button>
                          </div>
                          <div className="rounded-bl-lg rounded-br-lg bg-gray-50_01">
                            <div className="rounded-bl-lg rounded-br-lg bg-indigo-50 px-4">
                              <div className="flex items-center justify-between gap-5 pb-[19px] pt-5">
                                <Heading size="6xl" as="h2" className="self-end !font-bold">
                                  Lesson Quiz
                                </Heading>
                                <Img src="images/img_bookmark.svg" alt="bookmark" className="h-[24px] w-[24px]" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </AccordionItemPanel>
                    </div>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
            <div className="flex flex-1 flex-col items-start gap-4 rounded-lg md:self-stretch">
              <Button
                size="3xl"
                variant="outline"
                leftIcon={<Img src="images/img_megaphone.svg" alt="megaphone" className="h-[18px] w-[18px]" />}
                className="ml-4 min-w-[147px] gap-2 rounded-[20px] font-semibold tracking-[0.10px] md:ml-0 sm:pr-5"
              >
                Close Menu
              </Button>
              <div className="self-stretch rounded-lg bg-white-A700 p-2">
                <div className="rounded-lg border border-solid border-indigo-50 bg-white-A700">
                  <div className="flex flex-col items-center gap-[31px] rounded-lg bg-white-A700 px-4 pb-4 pt-6 sm:pt-5">
                    <div className="flex w-[97%] justify-between gap-5 md:w-full">
                      <div className="flex items-center gap-[19px]">
                        <Img
                          src="images/img_edit_05_blue_gray_700_02.svg"
                          alt="editfive"
                          className="h-[24px] w-[24px]"
                        />
                        <Text size="7xl" as="p" className="self-end !text-blue_gray-500">
                          <span className="text-blue_gray_700_02">Questions 1 /</span>
                          <span className="text-blue_gray-500">&nbsp;</span>
                          <span className="text-blue_gray_300">4</span>
                        </Text>
                      </div>
                      <div className="flex">
                        <Text size="7xl" as="p" className="!text-red-500">
                          1 Attempt Left
                        </Text>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 self-stretch rounded-lg bg-gray-100_01 px-4 py-12 md:py-5">
                      <div className="flex items-center gap-2.5 self-start px-4 sm:flex-col">
                        <div className="flex flex-col items-center px-2 pb-1.5 pt-2">
                          <Heading size="6xl" as="h3">
                            1.
                          </Heading>
                        </div>
                        <Heading size="9xl" as="h4" className="self-end">
                          What does financial responsibility involve?
                        </Heading>
                      </div>
                      <RadioGroup name="row2" className="flex flex-col">
                        <Radio
                          value="accumulatingasmuchdebtaspossible2"
                          label="Accumulating as much debt as possible."
                          className="flex-1 gap-[22px] rounded-lg bg-white-A700 py-[21px] pr-[35px] text-base text-black-900 sm:py-5 sm:pr-5"
                        />
                        <Radio
                          value="ignoringfinancialdecisionsandgoals2"
                          label="Ignoring financial decisions and goals."
                          className="mt-2 flex-1 gap-[22px] rounded-lg bg-white-A700 py-[21px] pr-[35px] text-base font-bold text-black-900 sm:py-5 sm:pr-5"
                        />
                        <Radio
                          value="makinginformeddecisionsaboutspendingsavingandinvesting2"
                          label="Making informed decisions about spending, saving, and investing."
                          className="mt-2 flex-1 gap-[22px] rounded-lg bg-white-A700 py-[21px] pr-[35px] text-base text-black-900 sm:py-5 sm:pr-5"
                        />
                        <Radio
                          value="avoidinganyplanningforthefuture2"
                          label="Avoiding any planning for the future."
                          className="mt-2 flex-1 gap-[22px] rounded-lg bg-white-A700 py-[21px] pr-[35px] text-base text-black-900 sm:py-5 sm:pr-5"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                  <div className="pb-[243px] md:pb-5">
                    <div className="rounded-lg bg-white-A700">
                      <div className="flex justify-between gap-5 bg-white-A700 p-2.5 sm:flex-col">
                        <Button
                          size="3xl"
                          variant="outline"
                          leftIcon={
                            <Img
                              src="images/img_arrowleft_blue_a200_1.svg"
                              alt="arrow_left"
                              className="h-[18px] w-[18px]"
                            />
                          }
                          className="min-w-[100px] gap-2 rounded-[20px] font-semibold tracking-[0.10px] !text-blue-A400 sm:pr-5"
                        >
                          Back
                        </Button>
                        <div className="flex items-center gap-6">
                          <div className="flex">
                            <Text size="7xl" as="p" className="!text-black-900">
                              Well Done!
                            </Text>
                          </div>
                          <Button
                            size="3xl"
                            rightIcon={
                              <Img src="images/img_arrowright.svg" alt="arrow_right" className="h-[18px] w-[18px]" />
                            }
                            className="min-w-[180px] gap-2 rounded-[20px] font-semibold tracking-[0.10px] sm:px-5"
                          >
                            Finish Module
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
