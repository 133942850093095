import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Img, Text, Button, Heading } from "../../components";
import { ReactTableHoldings } from "../../components/ReactTableHoldings";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar1 from "components/Sidebar1";
import HomeDate from "components/HomeDate";
import { IconAlertCircleFilled, IconCircleCheckFilled } from "@tabler/icons-react";
import { createStudents, createExcelFile } from "TeacherAPIService";

export default function CreateStudentAccounts() {
    const location = useLocation();
    const { classroomId } = location.state || {};
    const [defaultUsername, setDefaultUsername] = useState('');
    const [numOfStudents, setNumOfStudents] = useState('');
    const [hasError, setHasError] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [responseData, setResponseData] = useState(null);
    const [isAwaitingCreation, setIsAwaitingCreation] = useState(false);
    const [credentialsCreated, setCredentialsCreated] = useState(false);

    const navigate = useNavigate();

    const setStatus = (message, isError = false) => {
        setHasError(isError);
        setStatusMessage(message);
    };

    const handleSubmitClick = async (e) => {
        e.preventDefault();

        // Input Validation
        if (!defaultUsername || !numOfStudents) {
            setStatus("Please fill in all fields!", true);
            return;
        }

        setStatus("");
        setIsAwaitingCreation(true);

        try {
            // Create student accounts using the service function
            const data = await createStudents(classroomId, defaultUsername, parseInt(numOfStudents));

            setResponseData(data);
            setCredentialsCreated(true);
            setStatus("Students Created Successfully", false);
        } catch (error) {
            setCredentialsCreated(false);
            setStatus(error.message || "Error Creating Students", true);
        } finally {
            setIsAwaitingCreation(false);
        }
    };

    const handleExcelDownloadClick = async (e) => {
        e.preventDefault();

        if (credentialsCreated && responseData !== null) {
            try {
                // Generate Excel file using the service function
                const blob = await createExcelFile(classroomId, responseData);

                // Create a link to download the blob
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `Intertwined_${defaultUsername}_Accounts.xlsx`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);

                setStatus("File Generated - Check your browser downloads to confirm successful download.", false);
            } catch (error) {
                setStatus("Error Downloading Excel File", true);
            }
        }
    };

    const sampleData = [
        { userName: 'Apple01', password: 'Tape-139' },
        { userName: 'Apple02', password: 'Time_852' },
        { userName: 'Apple03', password: 'Belt!914' },
        { userName: 'Apple04', password: 'Card-407' },
        { userName: 'Apple05', password: 'Wall#562' },
    ];

    const table1Columns = React.useMemo(() => {
        const table1ColumnHelper = createColumnHelper();
        return [
            table1ColumnHelper.accessor("userName", {
                cell: (info) => (
                    <div className="items-center">
                        <Text size="7xl" as="p" className="tracking-[0.10px]">
                            {info?.getValue?.()}
                        </Text>
                    </div>
                ),
                header: (info) => (
                    <div className="flex items-center gap-1 px-6 py-3 md:p-5 sm:px-5 justify-center">
                        <Heading size="4xl" as="h4" className="!text-blue_gray-500">Username</Heading>
                    </div>
                ),
                meta: { width: "full" },
            }),
            table1ColumnHelper.accessor("password", {
                cell: (info) => (
                    <div className="justify-center items-center md:self-stretch">
                        <Text size="7xl" as="p" className="tracking-[0.10px] ">
                            {info?.getValue?.()}
                        </Text>
                    </div>
                ),
                header: (info) => (
                    <div className="flex items-center gap-1 px-6 py-3 md:p-5 sm:px-5 justify-center ">
                        <Heading size="4xl" as="h4" className="!text-blue_gray-500">Password</Heading>
                    </div>
                ),
                meta: { width: "full" },
            }),
        ];
    }, []);

    const handleBackClick = () => {
        navigate(`/classroom/${classroomId}`);
    };

    return (
        <>
            <Helmet>
                <title>intertwined</title>
                <meta name="description" content="Intertwined Finance" />
            </Helmet>

            <div className="flex h-screen overflow-hidden">
                <Sidebar1 />

                <div className="flex flex-col w-full max-full overflow-hidden bg-mesh-gradient p-4 gap-4">
                    <div className="flex justify-between rounded-lg bg-white-A700">
                        <button className="flex p-4" onClick={handleBackClick}>
                            <Img
                                src="/images/img_arrow_left_blue_a200.svg"
                                alt="arrowleft"
                                className="relative right-px"
                            />
                            <Text as="p" className="text-blue-500 cursor-pointer justify-start ">
                                Back
                            </Text>
                        </button>
                        <HomeDate className={"rounded-lg"} />
                    </div>

                    <div className="flex flex-col h-full gap-5 items-center overflow-auto bg-white-A700 p-4 rounded-lg">
                        <h1 className="text-xl font-semibold">Create Student Accounts</h1>
                        {!credentialsCreated && (
                            <p className="text-md">To create and generate student accounts for your classroom, please follow the instructions below.</p>
                        )}

                        {/* Warn user not to leave before saving */}
                        {credentialsCreated && (
                            <div className="flex bg-red-400 p-4 text-center gap-2 rounded-lg">
                                {/* <IconAlertCircle className="text-red-900 h-6"/> */}
                                <div className="flex flex-col ">
                                    <h2 className="text-red-900 font-semibold">Important - Once you close the page, this data cannot be recovered!</h2>
                                    <p className="text-red-800 my-2">Please save this information by doing one or more of the following:</p>
                                    <div className="bg-red-200 px-4 py-2 rounded-lg text-left">
                                        <ul className="text-red-800 list-disc pl-4">
                                            <li>Download as an Excel .XLSX file using the button below.</li>
                                            <li>Print/Save as PDF by pressing CTRL+P (Windows/Linux) or Command+P (Mac)</li>
                                            <li>Copy/Paste the records from the table below.</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        )}

                        {!credentialsCreated && (
                            <div className="flex justify-between gap-4">
                                <div className="flex flex-col gap-4 w-[50%] text-left md:w-full">
                                    <div className="flex gap-4">
                                        {/* <div className="flex items-center">
                                        <p className="flex rounded-full p-2 border text-blue-500">1</p>
                                    </div>
                                    <p>If the student already has an account, share the class code with them. Do not create new credentials.</p> */}
                                        <p className="bg-yellow-100 p-4 rounded-lg"><strong>Note:</strong> If the student already has an account, share the class code with them. Do not create new credentials.</p>
                                    </div>

                                    <div className="flex gap-4 items-center">
                                        <div className="flex items-center">
                                            <p className="flex rounded-full p-2 border text-blue-500">1</p>
                                        </div>

                                        <p>In the "Default Username" box, type in the desired base username for your students.</p>
                                    </div>

                                    <div className="flex gap-4 items-center">
                                        <div className="flex items-center">
                                            <p className="flex rounded-full p-2 border text-blue-500">2</p>
                                        </div>

                                        <p>Choose the number of accounts you want to create.</p>
                                    </div>

                                    <div className="flex gap-4 items-center">
                                        <div className="flex items-center">
                                            <p className="flex rounded-full p-2 border text-blue-500">3</p>
                                        </div>

                                        <p>Click on "Create Accounts".</p>
                                    </div>

                                    <div className="flex gap-4 items-center">
                                        <div className="flex items-center">
                                            <p className="flex rounded-full p-2 border text-blue-500">4</p>
                                        </div>

                                        <p>Export account details and share with students!</p>
                                    </div>

                                </div>

                                <div className="flex flex-col items-center rounded-lg bg-gray-200 w-[35%] p-4 gap-4 text-gray-800 md:w-full">
                                    {/* <p>Below are the credentials for your newly created student accounts:</p> */}
                                    <table className="w-full">
                                        <thead>
                                            <tr className="w-full p-0 bg-gray-300 border-blue_gray-100 border border-solids">
                                                <th className="p-4 text-center">Username</th>
                                                <th className="p-4 text-center">Password</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sampleData.map((student, index) => (
                                                <tr key={index} className="w-full border border-solid border-blue_gray-100 bg-white-A700">
                                                    <td className="p-4 text-center">{student.userName}</td>
                                                    <td className="p-4 text-center">{student.password}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <p className="text-gray-600 text-xs">This is an example entry for generating 5 students with the default username "Apple"</p>
                                </div>

                            </div>)}



                        {statusMessage !== "" && (
                            <div className="flex flex-col gap-4">
                                <div className={hasError ? ("flex items-center w-full justify-center p-4 rounded-lg bg-red-200") : ("flex items-center w-full justify-center p-4 rounded-lg bg-green-200")}>
                                    <p className="flex items-center gap-2"><span>{hasError ? (<IconAlertCircleFilled className="text-red-500" />) : (<IconCircleCheckFilled className="text-green-500" />)}</span>{statusMessage}</p>
                                </div>
                            </div>)}

                        {!credentialsCreated && (
                            <div id="formControls" className="flex flex-col gap-4">
                                <div id="formFields" className="flex gap-4">
                                    <div className="flex flex-col w-full gap-2">
                                        <label className="text-left">Default Username <span className="text-red-500">*</span> </label>
                                        <div className=" border p-2 rounded-lg border-gray-300">
                                            <input
                                                type="text"
                                                placeholder="eg. Apple"
                                                value={defaultUsername}
                                                onChange={(e) => setDefaultUsername(e.target.value)}
                                                className="w-full placeholder-gray-400"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-full gap-2">
                                        <label className=" text-left">No. of Students</label>
                                        <div className="border p-2 rounded-lg border-gray-300">
                                            <input
                                                type="number"
                                                value={numOfStudents}
                                                placeholder="0"
                                                onChange={(e) => setNumOfStudents(e.target.value)}
                                                className="w-full placeholder-gray-400"
                                            />
                                        </div>

                                    </div>
                                </div>

                                <div id="formButtons" className="flex w-full gap-4">
                                    <Button
                                        size="3xl"
                                        variant="outline"
                                        shape="round"
                                        className="button-white"
                                        onClick={handleBackClick}
                                    >Cancel</Button>
                                    <Button
                                        id="btnCreateAccounts"
                                        size="3xl"
                                        variant="outline"
                                        shape="round"
                                        disabled={isAwaitingCreation}
                                        className={`button-blue !w-full ${isAwaitingCreation ? 'bg-blue-200' : 'bg-blue-500'}`}
                                        onClick={handleSubmitClick}
                                    ><span className="text-white-A700">{isAwaitingCreation ? 'Please wait...' : 'Create Accounts'}</span></Button>
                                </div>
                            </div>)}

                        {credentialsCreated && (

                            <div className="flex flex-col rounded-lg bg-gray-200 w-[35%] p-3 text-gray-800 md:w-full">
                                <div>
                                    <button onClick={handleExcelDownloadClick} className="flex justify-center items-center gap-2 w-full p-2 bg-blue-500 text-white-A700 rounded-lg mt-2">Download as Excel</button>
                                    {/* <button className="flex justify-center items-center gap-2 w-full p-2 bg-blue-500 text-white-A700 rounded-lg mt-2">Download as PDF</button> */}
                                </div>
                                <div className="m-2 rounded-lg">
                                    <ReactTableHoldings
                                        size="lg"
                                        bodyProps={{ className: "w-full" }}
                                        headerProps={{
                                            className: "w-full bg-gray-300 border-blue_gray-100 border border-solid rounded-t md:flex-col",
                                        }}
                                        rowDataProps={{ className: "w-full border border-solid border-blue_gray-100 bg-white-A700" }}
                                        className="w-full"
                                        columns={table1Columns}
                                        data={responseData.users}
                                    />

                                </div>

                            </div>)}
                    </div>

                </div>
            </div>
        </>
    );
}
