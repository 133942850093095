import React from "react";
import { Heading, Img } from "./..";
import { useNavigate } from "react-router-dom";

// TODO: Progress Bar

const colors = {
  "orange": {
    bg: "bg-yellow-100_01",
    image: "/images/img_currency_dollar.svg",
    border: "border-deep_orange-900_87",
    text: "!text-deep_orange-900_7f",
    progress: "bg-deep_orange-900",
    progressbg: "bg-deep_orange-900_02",
  },
  "blue": {
    bg: "bg-blue-50_04",
    image: "/images/img_line_chart_up_03.svg",
    border: "border-blue-900_87",
    text: "!text-blue-900_7f",
    progress: "bg-blue-900_01",
    progressbg: "bg-blue-900_02",
  },
  "pink": {
    bg: "bg-purple-50",
    image: "/images/img_graduation_hat_02.svg",
    border: "border-pink-800_87",
    text: "!text-pink-800_7f",
    progress: "bg-pink-800",
    progressbg: "bg-pink-800_01",
  }
};

const colorKeys = Object.keys(colors); // Get array of color keys

// Function to generate a seeded random number based on a string (courseName)
function seededRandom(seed) {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

export default function TeacherCourseCard({
  courseName,
  to,
  className,
  expectedDuration,
  durationUnit,
  courseId,
  ...props
}) {

  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(to);
  };

  // Seed based on courseName
  const seed = courseName.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
  const colorIndex = Math.floor(seededRandom(seed) * colorKeys.length);
  const assignedColor = colorKeys[colorIndex];

  return (
    <div {...props} className={`${props.className} flex border border-gray-200_03 w-full rounded-lg course-card-hover`} onClick={handleCardClick} style={{ cursor: 'pointer' }}>
      <div className={`flex flex-col items-start w-full rounded-lg ${colors[assignedColor].bg} gap-2 p-2`}>

        <div className="flex w-full h-full justify-end ">


          <Img
            src={colors[assignedColor].image}
            alt="course icon"
            className="hidden md:block h-[30px] w-[30px] md:h-[60px] md:w-[60px] xl:h-[80px] xl:w-[80px] 2xl:h-[90px] 2xl:w-[90px] items-end"
          />
        </div>

        <div className=" w-full">
          <h1 className="text-xs md:text-lg font-semibold">
            {courseName}
          </h1>

          <div className="flex items-center gap-4">
            <Heading as="h4" className={colors[assignedColor].text}>
              {`${expectedDuration} ${durationUnit}`}
            </Heading>
          </div>
        </div>

      </div>
    </div>
  );
}
