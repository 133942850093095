import React, { useEffect, useState, useCallback } from "react";
import { Img, Text, Heading } from "../../components";
import {
    AccordionItemPanel,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemState,
    Accordion,
    AccordionItem,
} from "react-accessible-accordion";
import { useNavigate } from "react-router-dom";
import { LESSON_ARTICLE } from "utils";

export default function TeacherUnitTree({ active, courseId, data = { sections: [] }, content, ...props }) {
    const navigate = useNavigate();
    const [expandedItems, setExpandedItems] = useState([]);

    const handleNavClick = (lessonId, isCompleted) => {
        if (isCompleted || !isCompleted) {
            navigate(`/course/${courseId}/unit/${data.id}/lesson/${lessonId}`, {
                state: { data: data, section: content },
            });
        }
    };

    const handleLessonQuizClick = (quizId, isCompleted) => {
        if (isCompleted || !isCompleted) {
            navigate(`/course/${courseId}/unit/${data.id}/quiz/${quizId}`, {
                state: { data: data, section: content },
            });
        }
    };

    // Wrap `isCurrent` in useCallback to stabilize its reference
    const isCurrent = useCallback(
        (section) =>
            (section.quiz && section.quiz.id === active) ||
            (section.lessons && section.lessons.find((l) => l.id === active)),
        [active]
    );

    useEffect(() => {
        if (data && data.sections) {
            let a = [];
            data.sections.forEach((s, i) => {
                if (isCurrent(s)) {
                    a.push(i);
                }
            });
            setExpandedItems(a);
        }
    }, [data, active, isCurrent]);

    return (
        <div className="w-full rounded-lg md:w-full">
            <Accordion
                allowZeroExpanded
                preExpanded={expandedItems}
                onChange={setExpandedItems}
                className="flex flex-col gap-2 rounded-lg bg-white-A700"
            >
                {data.sections.map((s, i) => (
                    <AccordionItem
                        uuid={i}
                        key={`row${i}`}
                        className={i === data.sections.length ? "" : "border-b border-solid border-gray-300"}
                    >
                        <div className="flex flex-1 flex-col gap-2" key={`rowdiv${i}`}>
                            <AccordionItemHeading className="w-full">
                                <AccordionItemButton className="py-4">
                                    <AccordionItemState expanded={isCurrent(s)}>
                                        {(props) => (
                                            <>
                                                <div className="bg-white-A700 flex items-start px-4">
                                                    <Img
                                                        src="/images/img_arrow_up_black_900.svg"
                                                        alt="arrowdown"
                                                        className={`h-[24px] w-[24px] ${
                                                            props.expanded ? "" : "rotate-180"
                                                        } mr-2`}
                                                    />
                                                    <Heading size="6xl" as="h1" className="w-auto">
                                                        {s.name}
                                                    </Heading>
                                                </div>
                                            </>
                                        )}
                                    </AccordionItemState>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="bg-white-A700 px-4">
                                    <div className="bg-gray-50_01 rounded-lg ">
                                        {s.lessons.map((l, j) => (
                                            <div
                                                className={`flex items-center px-4 justify-between ${
                                                    j !== 0 ? "border-t" : ""
                                                } border-solid border-indigo-50 py-5 ${
                                                    active === l.id ? "bg-indigo-50 font-bold" : ""
                                                } cursor-pointer`}
                                                key={`lesson${j}`}
                                                onClick={() => handleNavClick(l.id, l.completed)}
                                            >
                                                <Text
                                                    size="7xl"
                                                    as="p"
                                                    className={`self-end !text-black-900 ${
                                                        active === l.id ? "!font-bold" : ""
                                                    }`}
                                                >
                                                    {l.name}
                                                </Text>
                                                {l.contentType === LESSON_ARTICLE ? (
                                                    <Img
                                                        src="/images/img_library_books_gray_800_01.svg"
                                                        alt="librarybooks"
                                                        className="h-[24px] w-[24px] self-start"
                                                    />
                                                ) : (
                                                    <Img
                                                        src="/images/img_ondemand_video.svg"
                                                        alt="ondemandvideo"
                                                        className="h-[24px] w-[24px]"
                                                    />
                                                )}
                                            </div>
                                        ))}
                                        {s.quiz ? (
                                            <div
                                                onClick={() => handleLessonQuizClick(s.quiz.id, s.quiz.completed)}
                                                className={`flex items-center px-4 justify-between border-t border-solid border-indigo-50 py-5 ${
                                                    active === s.quiz.id ? "bg-indigo-50 font-bold" : ""
                                                }`}
                                            >
                                                <Text size="7xl" as="p" className="self-end !text-black-900">
                                                    Lesson Quiz
                                                </Text>
                                                <Img
                                                    src="/images/img_bookmark.svg"
                                                    alt="bookmark"
                                                    className="h-[24px] w-[24px]"
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                    <div style={{ marginBottom: "20px" }}></div>
                                </div>
                            </AccordionItemPanel>
                        </div>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    );
}
