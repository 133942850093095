import React, { useMemo } from "react";

const BalanceSheet = ({stockInfo}) => {

    const headers = useMemo(() => ["Name", "2023", "2022", "2021", "2020"], []);
    const rows = useMemo(() => [
        { name: "Current Assets", key: "currentAssets" },
        { name: "Fixed Assets", key: "fixedAssets" },
        { name: "Intangibles", key: "intangibles" },
        { name: "Current Liabilities", key: "currentLiabilities" },
        { name: "Long Term Debt", key: "longTermDebt" },
        { name: "Shareholders Equity", key: "shareholdersEquity" },
    ], []);

    const tableData = useMemo(() => rows.map(row => ({
        name: row.name,
        ...headers.slice(1).reduce((acc, year) => {
            const yearData = stockInfo.balanceSheetTableRecords.find(item => new Date(item.fiscalDateEnding).getFullYear() === parseInt(year));
            acc[year] = yearData ? yearData[row.key] : "N/A";
            return acc;
        }, {})
    })), [stockInfo, rows, headers]);

    return (
        <>
            <div className="flex-1 overflow-y-auto">
                <div className="p-4">
                    <table className="w-full text-left">
                        <thead className="w-full border-blue_gray-100 border-b border-solid bg-gray-50_01 md:flex-col">
                            <tr>
                                {headers.map(header => (
                                    <th key={header} className="p-3 border-b border-gray-300 text-blue_gray-500 text-center">
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, rowIndex) => (
                                <tr key={rowIndex} className="border-b border-gray-300">
                                    <td className="p-4 text-center">{row.name}</td>
                                    {headers.slice(1).map(year => (
                                        <td key={year} className="p-3 text-center">
                                            {typeof row[year] === 'number' ? row[year].toLocaleString() : row[year]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default BalanceSheet;