import React, { useEffect } from 'react';
import { Img, Text } from 'components';
import CourseCard from 'components/CourseCard';
import { ConvertDuration } from 'utils';

const DashboardCourses = ({ studentId, courses, handleViewAllCourses }) => {

    useEffect(() => {

    }, []);

    return (
        <div className="flex flex-col rounded-lg p-2 2xl:p-4 gap-4 border border-solid border-blue_gray-100 bg-white-A700 shadow-md">
            <div className="flex justify-between items-center">
                <h1 className="font-semibold text-xs lg:text-md 2xl:text-base text-accent">
                    My Courses
                </h1>
                <div className="flex items-center gap-1 self-start">
                    <button
                        onClick={handleViewAllCourses}
                        className="!text-blue-500"
                        style={{ cursor: 'pointer', background: 'none', border: 'none', padding: '0' }}
                    >
                        <Text as="p" className='text-primary'>View All</Text>
                    </button>

                    <Img src="images/img_arrow_right_blue_a200.svg" alt="arrowright" className="h-[16px] w-[16px]" />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-4 rounded-lg">
                {courses.slice(0, 3).map((course, index) => (
                    <CourseCard
                        key={index}
                        className={course.class.name}
                        courseName={course.course.name}
                        expectedDuration={course.course.expectedDuration}
                        durationUnit={ConvertDuration(course.course.durationUnit)}
                        courseId={course.course.id}
                        studentId={studentId}
                        to={`/courses/course/${course.course.id}/student/${course.studentId}`} // Adjust routing as needed
                    />
                ))}
            </div>
        </div>
    );
};

export default DashboardCourses;
