import React from "react";
import { Helmet } from "react-helmet";
import { CloseSVG } from "../../assets/images";
import { Button, Text, Heading, Img, Input } from "../../components";
import DashboardWatchlist from "../../components/DashboardWatchlist";
import StudentSidebar from "../../components/StudentSidebar";
import { TabPanel, TabList, Tab, Tabs } from "react-tabs";
import { Link } from 'react-router-dom';

export default function AssignmentsPage() {
  const [searchBarValue35, setSearchBarValue35] = React.useState("");

  return (
    <>
      <Helmet>
        <title>Assignments | Intertwined</title>
        <meta name="description" content="Intertwined Finance" />
      </Helmet>
      <div className="flex w-full justify-center bg-white-A700 md:flex-col">
        <StudentSidebar />
        <div className="container-md w-[94%] md:p-5">
          <div className="flex flex-col gap-[5px] pt-2">
            <header>
              <Img
                src="images/img_screenshot_2024_04_18.png"
                alt="screenshot2024"
                className="h-[50px] w-full object-cover md:h-auto"
              />
            </header>
            <div className="flex gap-2 md:flex-col">
              <div className="w-[29%] py-2 pl-2 md:w-full">
              <Input
                  size="2xl"
                  name="search"
                  placeholder={`Search Stock eg. META, MSFT ...`}
                  value={searchBarValue35}
                  onChange={(e) => setSearchBarValue35(e)}
                  prefix={
                    <div className="flex h-[40px] w-[40px] items-center justify-center rounded-[50%]">
                      <Img
                        src="images/img_search_blue_gray_100_13x13.svg"
                        alt="search"
                        className="h-[24px] w-[24px] cursor-pointer"
                        style={{ marginTop: '15px' }}
                      />
                    </div>
                  }
                  suffix={
                    searchBarValue35?.length > 0 ? (
                      <CloseSVG onClick={() => setSearchBarValue35("")} height={24} width={24} fillColor="#d3dbe4ff" />
                    ) : null
                  }
                  className="gap-2 rounded-tl-lg rounded-tr-lg border border-solid border-blue_gray-100 tracking-[0.50px] !text-blue_gray-100 sm:pr-5"
                  style={{ paddingTop: '18px' }}
                />
                <div className="flex flex-col justify-center rounded-bl-lg rounded-br-lg border-b border-l border-r border-solid border-blue_gray-100 px-2 pb-[385px] md:pb-5">
                  <div className="flex self-start px-2 pb-[13px] pt-3.5">
                    <Heading size="9xl" as="h1">
                      Recent Searches
                    </Heading>
                  </div>
                  <DashboardWatchlist />
                </div>
              </div>
              
              <div className="w-[71%] bg-white-A700 py-2 pr-2 md:w-full">
                <Tabs
                  className="flex flex-col gap-4 rounded-lg border border-solid border-blue_gray-100 pb-[345px] md:pb-5"
                  selectedTabClassName=""
                  selectedTabPanelClassName="relative tab-panel--selected"
                >
                  <TabList className="flex items-center justify-end gap-8 border-b border-solid border-blue_gray-100 px-8 sm:flex-col sm:px-5">
                  <Tab className="font-roboto text-sm font-medium tracking-[0.10px] text-gray-800_01">
                      <Link to="/dashboard" style={{ color: 'black', textDecoration: 'none' }}>Dashboard</Link>
                    </Tab>
                    <Tab className="font-roboto text-sm font-medium tracking-[0.10px] text-gray-800_01">
                      <Link to="/watchlist" style={{ color: 'inherit', textDecoration: 'none' }}>Watchlist</Link>
                    </Tab>
                    <Tab className="font-roboto text-sm font-medium tracking-[0.10px] text-gray-800_01">
                      <Link to="/orders" style={{ color: 'inherit', textDecoration: 'none' }}>Orders</Link>
                    </Tab>
                    <Tab className="font-roboto text-sm font-medium tracking-[0.10px] text-gray-800_01">
                      <Link to="/holdings" style={{ color: 'black', textDecoration: 'none' }}>Holdings</Link>
                    </Tab>
                    <Tab className="flex flex-wrap items-center gap-1 pb-[18px] pt-5">
                    <Tab className="font-roboto text-sm font-medium tracking-[0.10px] text-gray-800_01">
                      <Link to="/assignments" style={{ color: 'blue', textDecoration: 'none' }}>Assignments</Link>
                    </Tab>
                      <Text
                        size="4xl"
                        as="p"
                        className="flex items-center justify-center self-start rounded-lg bg-blue-A200 px-1.5 py-px !font-sfprotext !text-white-A700"
                      >
                        25
                      </Text>
                    </Tab>
                  </TabList>
                  {[...Array(5)].map((_, index) => (
                    <TabPanel key={`tab-panel${index}`} className="absolute items-center">
                      <div className="w-full">
                        <div className="flex flex-col gap-4">
                          <div className="flex self-start px-4 pb-1 pt-2.5">
                            <Heading size="11xl" as="h2">
                              Assignments (3)
                            </Heading>
                          </div>
                          <div className="grid grid-cols-3 justify-center gap-4 pb-[7px] pl-4 pr-[29px] pt-2 md:grid-cols-2 sm:grid-cols-1 sm:pr-5">
                            <Img
                              src="images/img_image_1.png"
                              alt="image"
                              className="h-[120px] w-[120px] w-full rounded object-cover md:h-auto"
                            />
                            <div className="flex w-full flex-col gap-2.5 ml-[-100px]">
                              <div className="flex flex-col items-start gap-1.5">
                                <Heading size="6xl" as="h3" className="!text-gray-900">
                                  Buy three Stocks of your choice
                                </Heading>
                                <Text size="7xl" as="p" className="w-full !font-light leading-6 !text-gray-900_04">
                                  You need to research and buy 3 stock of your choice and provide a reason behind the
                                  choice. was it the balance statements, news or technicals that made you choose them.
                                </Text>
                              </div>
                              <div className="flex">
                                <Text as="p" className="!font-roboto !font-light !text-gray-600">
                                  due on: 12 Feb,2024, 11:59:00 pm
                                </Text>
                              </div>
                            </div>
                            <Button
                              size="3xl"
                              shape="round"
                              className="w-full min-w-[173px] font-roboto font-medium tracking-[0.10px] sm:px-5 flex items-center justify-center"
                            >
                              <div className="w-full text-center">
                                Submit Assignment
                              </div>
                            </Button>
                            <Img
                              src="images/img_image_1.png"
                              alt="image"
                              className="h-[120px] w-[120px] w-full rounded object-cover md:h-auto "
                            />
                            <div className="flex w-full flex-col gap-2.5 ml-[-100px]">
                              <div className="flex flex-col items-start gap-1.5">
                                <Heading size="6xl" as="h4" className="!text-gray-900">
                                  Buy three Stocks of your choice
                                </Heading>
                                <Text size="7xl" as="p" className="w-full !font-light leading-6 !text-gray-900_04">
                                  You need to research and buy 3 stock of your choice and provide a reason behind the
                                  choice. was it the balance statements, news or technicals that made you choose them.
                                </Text>
                              </div>
                              <div className="flex">
                                <Text as="p" className="!font-roboto !font-light !text-gray-600">
                                  due on: 12 Feb,2024, 11:59:00 pm
                                </Text>
                              </div>
                            </div>
                            <Button
                            color="blue_gray_100"
                              size="3xl"
                              shape="round"
                              className="w-full min-w-[173px] font-roboto font-medium tracking-[0.10px] sm:px-5 flex items-center justify-center"
                            >
                              <div className="w-full text-center">
                              1/3 Trades complete
                              </div>
                            </Button>
                            <Img
                              src="images/img_image_1.png"
                              alt="image"
                              className="h-[120px] w-[120px] w-full rounded object-cover md:h-auto"
                            />
                            <div className="flex w-full flex-col gap-2.5 ml-[-100px]">
                              <div className="flex flex-col items-start gap-1.5">
                                <Heading size="6xl" as="h5" className="!text-gray-900">
                                  Buy three Stocks of your choice
                                </Heading>
                                <Text size="7xl" as="p" className="w-full !font-light leading-6 !text-gray-900_04">
                                  You need to research and buy 3 stock of your choice and provide a reason behind the
                                  choice. was it the balance statements, news or technicals that made you choose them.
                                </Text>
                              </div>
                              <div className="flex">
                                <Text as="p" className="!font-roboto !font-light !text-gray-600">
                                  due on: 12 Feb,2024, 11:59:00 pm
                                </Text>
                              </div>
                            </div>
                            <Button
                              size="3xl"
                              shape="round"
                              className="w-full min-w-[173px] font-roboto font-medium tracking-[0.10px] sm:px-5 flex items-center justify-center"
                            >
                              <div className="w-full text-center">
                                Start Trading
                              </div>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
