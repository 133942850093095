// src/components/InstructorsTable/index.jsx

import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Button } from "components";

const InstructorsTable = ({ instructors, onRemove }) => {
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("email", {
      header: "Email",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("actions", {
      header: "Actions",
      cell: (info) => (
        <Button
          onClick={() => onRemove(info.row.original)}
          className="text-red-500 hover:text-red-700"
        >
          Remove
        </Button>
      ),
    }),
  ];

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.id}
                className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
              >
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {instructors.map((instructor, index) => (
            <tr key={index}>
              {columns.map((column) => (
                <td
                  key={column.id}
                  className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                >
                  {column.cell({ getValue: () => instructor[column.id], row: { original: instructor } })}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InstructorsTable;
