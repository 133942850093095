import React from 'react';
import { formatUSD } from 'utils';
import { IconBuildingBank, IconPig, IconCreditCard, IconCurrencyDollar } from '@tabler/icons-react';

const DashboardBudgetSimWidget = ({ checkingAccount, savingsAccount, creditCard, netCash }) => {
    const startupItems = [
        {
            label: 'Checking Account',
            value: checkingAccount,
            Icon: IconBuildingBank
        },
        {
            label: 'Savings Account',
            value: savingsAccount,
            Icon: IconPig
        },
        {
            label: 'Credit Card',
            value: creditCard,
            Icon: IconCreditCard
        },
        {
            label: 'Net Cash',
            value: netCash,
            Icon: IconCurrencyDollar
        },
    ];

    return (
        <div className="relative flex flex-col h-full 2xl:gap-4 p-2 2xl:p-4 rounded-lg border border-solid bg-white-A700 border-blue_gray-100 shadow-md">
            {/* Overlay for blur effect */}
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-50 backdrop-blur-sm rounded-lg z-10">
                <h2 className='text-lg font-semibold'>Budget Simulator</h2>
                <h2 className="text-md">Coming January 2025</h2>
            </div>

            <h1 className='font-semibold text-xs lg:text-md 2xl:text-base text-accent'>
                Budget Simulator
            </h1>

            {!checkingAccount ? (
                <div className='border flex w-full h-full items-center justify-center text-accent'>
                    No budget simulator data available. Head to the simulator to get started!
                </div>
            ) : (
                <div className="grid grid-cols-2 h-full gap-2 p-1 lg:flex lg:flex-row 2xl:gap-4 items-center">
                    {startupItems.map((item, index) => (
                        <div
                            key={index}
                            className="flex flex-col gap-2 lg:w-1/4 justify-between lg:gap-0 aspect-square max-h-32 transition-all bg-white-A700 border border-gray-300 p-2 rounded-lg shadow-md"
                        >
                            <div className='flex flex-row w-full items-center md:items-start gap-4 lg:gap-0 xl:gap-2 md:flex-col'>
                                {item.Icon && <item.Icon className="w-4 h-4 xl:w-6 xl:h-6 aspect-square text-accent" />}

                                <h2 className={`${item.value < 0 ? 'text-red-600' : 'text-green-600'} text-md xl:text-lg 2xl:text-xl font-bold`}>
                                    {formatUSD(item.value, false)}
                                </h2>
                            </div>

                            <div className='flex justify-between'>
                                <div className="2xl:w-4 2xl:min-h-[3rem] w-fit flex items-start justify-start">
                                    <h2 className='text-left whitespace-normal text-sm 2xl:text-base'>
                                        {item.label}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DashboardBudgetSimWidget;
