import React from "react";

export default function VideoLessonContent({ lesson = { lessonContent: "" }, ...props }) {

    return <div className="flex flex-col gap-4 rounded-lg bg-white-A700 p-2 w-full p-2">
        <div className="rounded-lg bg-gray-100_05 w-full">
            <iframe width="100%" 
            height="500px" 
            src={lesson.lessonContent} 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        {/* TODO: This hasn't been provided, so I'm commenting it out. */}
        {/* <div className="flex flex-col items-start gap-[7px]">

            <div className="flex">
                <Heading size="13xl" as="h3" className="!font-poppins">
                    Video: Introduction to Financial Literacy
                </Heading>
            </div>
            <Heading size="6xl" as="h4" className="!font-poppins">
                Summary
            </Heading>
        </div>
        <Text size="7xl" as="p" className="leading-[150%]">
            Financial responsibility is an essential skill for individuals, families, and businesses to ensure
            a secure and prosperous future. It is the process of managing money and assets in a way that
            aligns with the best interests of an individual, family, or business. It involves making informed
            decisions about spending, saving, investing, and budgeting. By exercising financial
            responsibility, individuals can achieve financial well-being and prepare for their future needs.
        </Text> */}
    </div>
}