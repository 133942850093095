
import React from "react";
import parse from "html-react-parser";

import 'styles/lesson.css' // So that we can match the css in the other thing!

export default function ArticleContent({ lesson }) {
    return <div className="">
        {parse(lesson.lessonContent)}
    </div>
}