import React from 'react';
import { formatUSD } from 'utils';
import { IconTrendingUp, IconMoodSmile, IconCash, IconScale } from '@tabler/icons-react';

const DashboardStartupSimWidget = ({ marketValue, employeeWellness, grossRevenue, profitLoss }) => {

    const startupItems = [
        {
            label: 'Market Value',
            value: marketValue,
            description: "The current valuation of your business in the market",
            Icon: IconTrendingUp
        },
        {
            label: 'Employee Wellness',
            value: employeeWellness,
            description: "Measure of your team’s morale, health, and engagement",
            Icon: IconMoodSmile
        },
        {
            label: 'Gross Revenue',
            value: grossRevenue,
            description: "Total income generated from business operations before expenses",
            Icon: IconCash
        },
        {
            label: 'Profit/Loss',
            value: profitLoss,
            description: "The net financial outcome after subtracting costs from revenue",
            Icon: IconScale
        },
    ];

    return (
        <div className="flex flex-col gap-2 h-full p-2 2xl:p-2 rounded-lg border border-solid bg-white-A700 border-blue_gray-100 shadow-md">
            <h1 className='font-semibold text-xs lg:text-md 2xl:text-base text-accent'>
                Startup Simulator
            </h1>
            {!marketValue ? (
                <div className='flex w-full h-full items-center justify-center text-accent'>
                    No startup simulator data available. Head to the simulator to get started!
                </div>
            ) : (
                <div className="grid grid-cols-2 h-full gap-2 p-1 lg:flex lg:flex-row 2xl:gap-4 items-center">
                    {startupItems.map((item, index) => (
                        <div
                            key={index}
                            className="flex flex-col  gap-2 lg:w-1/4 justify-between lg:gap-0 aspect-square max-h-32 transition-all bg-white-A700 border border-gray-300 p-2 rounded-lg shadow-md"
                        >
                            <div className='flex flex-row w-full items-center xl:items-start gap-4 lg:gap-0 lg:flex-col'>
                                {item.Icon && <item.Icon className="w-6 h-6 aspect-square text-accent" />}

                                {/* Conditional rendering for value */}
                                <h2
                                    className={`${item.label === 'Employee Wellness'
                                        ? item.value < 50
                                            ? 'text-red-600'
                                            : 'text-green-600'
                                        : item.value < 0
                                            ? 'text-red-600'
                                            : 'text-green-600'
                                        } text-md 2xl:text-xl font-bold`}
                                >
                                    {item.label === 'Employee Wellness'
                                        ? `${item.value}/100`
                                        : formatUSD(item.value, false)}
                                </h2>
                            </div>


                            <div className='flex justify-between'>
                                <div className="2xl:w-4 2xl:min-h-[3rem] w-fit flex items-start justify-start">
                                    <h2 className='text-left whitespace-normal text-sm 2xl:text-base'>
                                        {item.label}
                                    </h2>
                                </div>

                            </div>

                        </div>
                    ))}
                </div>
            )}

            {/* <SimulatorProgressWidget type="startup" currentPhase={"Seed"} /> TODO */}
        </div>
    );
};

export default DashboardStartupSimWidget;
