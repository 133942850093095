import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import StudentSidebar from "../../components/StudentSidebar";
import { fetchStudentId, fetchStudentStartupSimStatus, fetchStudentStartupSimOldProgressData, fetchStudentStartupSimResumeData } from "SimulatorAPIService";
import StartupProgressSteps from "components/StartupProgressSteps";
import StartupProgressBar from "components/StartupProgressBar";
import StartupOwnership from "components/StartupOwnership";
import StartupInfoItem from "components/StartupInfoItem";
import StartupDecision from "components/StartupDecision";
import StartupFinancials from "components/StartupFinancials";
import StartupTeam from "components/StartupTeam";
import StartupType from "components/StartupType";
import { Img } from "components";

export default function StartupDashboard() {
  // const [searchBarValue35, setSearchBarValue35] = useState("");
  const [studentId, setStudentId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [canStart, setCanStart] = useState(true);
  const [isStarted, setIsStarted] = useState(false);
  const [sessionData, setSessionData] = useState({});
  const [dashData, setDashData] = useState({});
  const [stageNumber, setStageNumber] = useState(0);
  const [stages, setStages] = useState({});
  const [investments, setInvestments] = useState({});
  const [unformattedDate, setUnformattedDate] = useState("");
  const [formattedDate, setFormattedDate] = useState("");
  const [finishedStage, setFinishedStage] = useState(false);
  const [simDates, setSimDates] = useState([]);
  const [viewingOldDate, setViewingOldDate] = useState(false);
  const [readOnlyDecisions, setReadOnlyDecisions] = useState(false);
  const [oldDateProgressId, setOldDateProgressId] = useState("");
  const [showCalendarPopup, setShowCalendarPopup] = useState(false);

  const navigate = useNavigate();

  const numberFormatOptions = { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = await fetchStudentId();

        if (id) {
          setStudentId(id);
          if (viewingOldDate) {
            console.log("OLD: " + oldDateProgressId);
            const studentStatus = await fetchStudentStartupSimStatus(id);
            if (studentStatus.canStart === false) {
              setCanStart(false);
            } else {
              if (studentStatus.started === true) {
                const oldProgressData = await fetchStudentStartupSimOldProgressData(id, oldDateProgressId);
                if (oldProgressData) {
                  setSessionData(oldProgressData.session_info);
                  setDashData(oldProgressData.progress);
                  setStageNumber(oldProgressData.stage);
                  setStages(oldProgressData.stages);
                  setInvestments(oldProgressData.investmentOptions);
                  const date = new Date(oldProgressData?.progress.simDecisionDate);
                  date.setHours(date.getHours() + 6); //Fix for Time Zone
                  const options = { day: 'numeric', month: 'long', year: 'numeric' };
                  const formatted = date.toLocaleDateString('en-US', options);
                  setUnformattedDate(oldProgressData?.latestDate);
                  setFormattedDate(formatted);
                  setFinishedStage(oldProgressData.finishedStage);
                  const formattedEvents = oldProgressData.simDates.map(item => ({
                    title: '',
                    start: item.date, // FullCalendar requires the date in 'YYYY-MM-DD' format
                    extendedProps: {
                      progressId: item.progressId,
                      affect: item.affect
                    },
                  }));
                  setSimDates(formattedEvents);
                  setIsStarted(true);
                  setShowCalendarPopup(false);
                  //setViewingOldDate(false);
                  //setOldDateProgressId("");
                }
              } else {
                setIsStarted(false);
              }
            }
          } else {
            const studentStatus = await fetchStudentStartupSimStatus(id);
            if (studentStatus.canStart === false) {
              setCanStart(false);
            } else {
              if (studentStatus.started === true) {
                const resumeData = await fetchStudentStartupSimResumeData(id);
                if (resumeData) {
                  setSessionData(resumeData.session_info);
                  setDashData(resumeData.progress);
                  setStageNumber(resumeData.stage);
                  setStages(resumeData.stages);
                  setInvestments(resumeData.investmentOptions);
                  const date = new Date(resumeData?.progress.simDecisionDate);
                  date.setHours(date.getHours() + 6); //Fix for Time Zone
                  const options = { day: 'numeric', month: 'long', year: 'numeric' };
                  const formatted = date.toLocaleDateString('en-US', options);
                  setUnformattedDate(resumeData?.latestDate);
                  setFormattedDate(formatted);
                  setFinishedStage(resumeData.finishedStage);
                  const formattedEvents = resumeData.simDates.map(item => ({
                    title: '',
                    start: item.date,
                    extendedProps: {
                      progressId: item.progressId,
                      affect: item.affect
                    }, // FullCalendar requires the date in 'YYYY-MM-DD' format
                  }));
                  setSimDates(formattedEvents);
                  setIsStarted(true);
                }
              } else {
                setIsStarted(false);
              }
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/timeout");
        } else {
          console.error('Error fetching data:', error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [navigate, viewingOldDate, oldDateProgressId]);

  useEffect(() => {
    //console.log(dashData);
    //console.log(isStarted);
    //console.log(stages);
  }, [dashData, viewingOldDate])

  return (
    <>
      <Helmet>
        <title>Startup Sim Dashboard | Intertwined</title>
        <meta name="description" content="Intertwined Finance" />
      </Helmet>

      <div className="flex w-full h-screen overflow-hidden m-auto">

        <div><StudentSidebar /></div>
        {!isLoading ? (
          <div className="flex flex-col w-full bg-mesh-gradient">
            {isStarted ? (
              <>
                <StartupProgressSteps sessionData={sessionData} dashData={dashData} stageNumber={stageNumber} />

                <div className="flex rounded-lg border border-solid border-slate-300 bg-white-A700 shadow-md m-3 h-full" style={{ overflow: "scroll" }}>
                  <div className="flex flex-col w-2/3 h-full border-r border-solid border-slate-300">
                    <div className="m-3">
                      <StartupProgressBar sessionData={sessionData} dashData={dashData} stageNumber={stageNumber} stages={stages} />
                    </div>
                    <div className="flex flex-row h-full">
                      <div className="flex flex-col grow-0 w-1/4 h-full">
                        <div className="mx-3 mb-3">
                          <StartupInfoItem title="Current Market Value" value={new Intl.NumberFormat('en-US', numberFormatOptions).format(dashData?.currentMarketValue)} description="The current valuation of your business in the market" />
                        </div>
                        <div className="mx-3 mb-3">
                          <StartupInfoItem title="Employee Wellness" value={dashData?.employeeWellness + "/100"} description="Measure of your team’s morale, health, and engagement" />
                        </div>
                        <div className="mx-3 mb-3">
                          <StartupInfoItem title="Gross Revenue" value={new Intl.NumberFormat('en-US', numberFormatOptions).format(dashData?.grossRevenue)} valueClass={(dashData?.grossRevenue.toString().startsWith("-") ? "!text-red-500" : "!text-green-500")} description="Total income generated from business operations before expenses" />
                        </div>
                        <div className="mx-3 mb-3">
                          <StartupInfoItem title="Profit / Loss" value={new Intl.NumberFormat('en-US', numberFormatOptions).format(dashData?.profitLoss)} valueClass={(dashData?.profitLoss.toString().startsWith("-") ? "!text-red-500" : "!text-green-500")} description="The net financial outcome after subtracting costs from revenue" />
                        </div>
                      </div>
                      <div className="flex flex-col grow w-3/4 h-full">
                        <div className="mr-3 mb-3">
                          <StartupDecision
                            studentId={studentId}
                            sessionData={sessionData}
                            setSessionData={setSessionData}
                            stageNumber={stageNumber}
                            setStageNumber={setStageNumber}
                            data={dashData}
                            setData={setDashData}
                            unformattedDate={unformattedDate}
                            date={formattedDate}
                            setDate={setFormattedDate}
                            finishedStage={finishedStage}
                            setFinishedStage={setFinishedStage}
                            showCalendarPopup={showCalendarPopup}
                            setShowCalendarPopup={setShowCalendarPopup}
                            simDates={simDates}
                            setSimDates={setSimDates}
                            viewingOldDate={viewingOldDate}
                            setViewingOldDate={setViewingOldDate}
                            readOnlyDecisions={readOnlyDecisions}
                            setReadOnlyDecisions={setReadOnlyDecisions}
                            setOldDateProgressId={setOldDateProgressId}
                            setStages={setStages}
                            stages={stages}
                            investments={investments}
                            setInvestments={setInvestments}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-1/3 h-full">
                    <div className="flex-row mx-3 my-3">
                      <StartupOwnership foundersPercent={dashData.foundersPercent} investorsPercent={dashData.investorsPercent} employeesPercent={dashData.employeesPercent} advisorsPercent={dashData.advisorsPercent} />
                    </div>
                    <div className="h-1/2 mx-3 mb-3">
                      <StartupFinancials stageNumber={stageNumber} revenue={dashData.annualRevenue} expenses={dashData.expenses} />
                    </div>
                    <div className="h-1/4 mx-3 mb-3">
                      <StartupTeam founders={dashData.founders} investors={dashData.investors} employees={dashData.employees} />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              !canStart ? (
                <div className="flex flex-row h-full">
                  <div className="flex flex-col grow w-3/4 h-full">
                    <div className="mr-3 mb-3">
                      The teacher for your class has not yet activated the Startup Simulator for you.
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row h-full">
                  <div className="flex flex-col grow w-3/4 h-full">
                    <div className="mr-3 mb-3">
                      <StartupType studentId={studentId} setSessionData={setSessionData} setStageNumber={setStageNumber} setIsStarted={setIsStarted} setData={setDashData} setFormattedDate={setFormattedDate} setSimDates={setSimDates} />
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        ) : (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}>
            <Img src="/images/loading.gif" style={{
              maxWidth: '100%', // Ensures the image scales properly
              maxHeight: '100%'
            }} />
          </div>
        )}
      </div>
    </>
  );
}

