import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Img, Heading, Text } from "../../components";
import {
  AccordionItemPanel,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemState,
  Accordion,
  AccordionItem,
} from "react-accessible-accordion";


export default function LearningViewTwoPage() {

  const navigate = useNavigate();
  const [expandedItems, setExpandedItems] = useState([]);

  const handleIntroFinancialResponsibility = () => {
    navigate('/home1');
  };

  const toggleItem = (index) => {
    const currentIndex = expandedItems.indexOf(index);
    const newExpandedItems = [...expandedItems];

    if (currentIndex === -1) {
      newExpandedItems.push(index);
    } else {
      newExpandedItems.splice(currentIndex, 1);
    }

    setExpandedItems(newExpandedItems);
  };


  return (
    <>
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Intertwined Finance" />
      </Helmet>
      <div className="w-full bg-white-A700">
        <div className="bg-white-A700">
          <header className="flex items-center rounded-lg px-4 pt-4">
            <div className="container-lg flex items-start justify-start gap-6 rounded-lg border border-solid border-indigo-50 pb-[11px] pt-5 md:flex-col">
              <div className="mt-[7px] flex items-start gap-2 md:mt-0">
                <Img src="images/img_arrow_left_blue_a200.svg" alt="arrowleft" className="h-[20px]" />
                <div className="flex">
                  <Text size="7xl" as="p" className="!text-blue-500">
                    Back to all Courses
                  </Text>
                </div>
              </div>
              <div className="relative left-1/2 flex items-start gap-6 sm:flex-col">
                <div className="mt-[5px] flex rounded border border-solid border-deep_purple-A200_01 bg-gray-50">
                  <div className="flex rounded p-[3px]">
                    <Heading as="p" className="!text-deep_purple-A200_01">
                      Unit 1
                    </Heading>
                  </div>
                </div>
                <Heading size="13xl" as="h2">
                  Financial Responsibility{" "}
                </Heading>
              </div>
            </div>
          </header>
          <div className="container-lg flex gap-2.5 md:flex-col md:p-5">
            <div className="w-[26%] rounded-lg border border-solid border-indigo-50 bg-white-300_01 md:w-full">
              <Accordion allowZeroExpanded preExpanded={expandedItems} onChange={setExpandedItems} className="flex flex-col gap-2 rounded-lg bg-white-A700">
                {[...Array(5)].map((_, i) => (
                  <AccordionItem uuid={i} key={`row${i}`} className="border-b border-solid border-gray-300">
                    <div className="flex flex-1 flex-col gap-2">
                      <AccordionItemHeading className="w-full" onClick={() => toggleItem(i)}>
                        <AccordionItemButton className="py-4">
                          <AccordionItemState>
                            {(props) => (
                              <>
                                <div className="bg-white-A700 flex items-center justify-between px-4">
                                  <Img
                                    src="images/img_arrow_up_black_900.svg"
                                    alt="arrowdown"
                                    className={`h-[24px] w-[24px] ${props.expanded ? "" : "rotate-180"} mr-2`}
                                  />
                                  <Heading size="6xl" as="h1" className="w-auto leading-[130%]">
                                    Definition of Financial Responsibility
                                  </Heading>
                                  {props.expanded ? (
                                    <Img
                                      src="images/img_check_circle.svg"
                                      alt="image"
                                      className="h-[24px] w-[24px] ml-auto"
                                    />
                                  ) : (
                                    <Img
                                      src="images/img_check_circle_blue_gray_100_03.svg"
                                      alt="image"
                                      className="h-[24px] w-[24px] ml-auto"
                                    />
                                  )}
                                </div>
                              </>
                            )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="bg-white-A700 px-4">
                          <div className="rounded-tl-lg rounded-tr-lg bg-indigo-50 px-4">
                            <div className="flex items-center justify-between gap-5 border-b border-solid border-indigo-50 py-5">
                              <Heading size="6xl" as="h2" className="!font-bold">
                                Introduction
                              </Heading>
                              <Img
                                src="images/img_ondemand_video.svg"
                                alt="ondemandvideo"
                                className="h-[24px] w-[24px]"
                              />
                            </div>
                          </div>
                          <div className="bg-gray-50_01 px-4">
                            <Button
                              color="indigo_50"
                              size="7xl"
                              variant="outline"
                              shape="square"
                              rightIcon={
                                <Img
                                  src="images/img_library_books_gray_800_01.svg"
                                  alt="library books"
                                  className="h-[24px] w-[24px]"
                                />
                              }
                              className="w-full gap-[11px]"
                            >
                              Benefits of Financial Responsibility
                            </Button>
                          </div>
                          <div onClick={handleIntroFinancialResponsibility} className="flex items-center cursor-pointer justify-between gap-5 rounded-bl-lg rounded-br-lg bg-gray-50_01 px-4 pb-[19px] pt-5">
                            <Text size="7xl" as="p" className="self-end !text-black-900">
                              Lesson Quiz
                            </Text>
                            <Img src="images/img_bookmark.svg" alt="bookmark" className="h-[24px] w-[24px]" />
                          </div>
                          <div style={{ marginBottom: '20px' }}></div>
                        </div>
                      </AccordionItemPanel>
                    </div>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
            <div className="flex flex-1 flex-col items-start gap-4 rounded-lg px-2 md:self-stretch">
              <Button
                size="3xl"
                variant="outline"
                leftIcon={<Img src="images/img_megaphone.svg" alt="megaphone" className="h-[18px] w-[18px]" />}
                className="min-w-[146px] gap-2 rounded-[20px] font-medium tracking-[0.10px] sm:pr-5"
                onClick={() => setExpandedItems([])} // This closes all tabs
              >
                Close Menu
              </Button>
              <div className="self-stretch rounded-lg border border-solid border-indigo-50 bg-white-A700 p-2">
                <div className="flex flex-col gap-4 rounded-lg bg-white-A700 p-2">
                  <div className="rounded-lg bg-gray-100_05">
                    <Img
                      src="images/img_image_101.png"
                      alt="image101"
                      className="h-[388px] w-full rounded-lg object-cover md:h-auto"
                    />
                  </div>
                  <div className="flex flex-col items-start gap-[7px]">
                    <div className="flex">
                      <Heading size="13xl" as="h3" className="!font-poppins">
                        Video: Introduction to Financial Literacy
                      </Heading>
                    </div>
                    <Heading size="6xl" as="h4" className="!font-poppins">
                      Summary
                    </Heading>
                  </div>
                  <Text size="7xl" as="p" className="leading-[150%]">
                    Financial responsibility is an essential skill for individuals, families, and businesses to ensure
                    a secure and prosperous future. It is the process of managing money and assets in a way that
                    aligns with the best interests of an individual, family, or business. It involves making informed
                    decisions about spending, saving, investing, and budgeting. By exercising financial
                    responsibility, individuals can achieve financial well-being and prepare for their future needs.
                  </Text>
                </div>
                <div className="flex justify-between gap-5 bg-white-A700 p-2.5">
                  <Button
                    size="3xl"
                    variant="outline"
                    leftIcon={
                      <Img
                        src="images/img_arrowleft_blue_a200_1.svg"
                        alt="arrow_left"
                        className="h-[18px] w-[18px]"
                      />
                    }
                    className="min-w-[99px] gap-2 rounded-[20px] font-medium tracking-[0.10px] !text-blue-A400 sm:pr-5"
                  >
                    Back
                  </Button>
                  <Button
                    size="3xl"
                    rightIcon={
                      <Img
                        src="images/img_arrowright_white_a700.svg"
                        alt="arrow_right"
                        className="h-[18px] w-[18px]"
                      />
                    }
                    className="min-w-[98px] gap-2 rounded-[20px] font-medium tracking-[0.10px] sm:pr-5"
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
