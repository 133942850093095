export default function CurrentDate() {
    return (
        <>
            {new Date().toLocaleDateString("en-us", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            })}
        </>
    );
}