import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { fetchInstructors } from "TeacherAPIService";
import AddInstructorPopup from "components/AddInstructorPopup";
import QuickLink from "components/QuickLink";
import InstructorsTable from "components/InstructorsTable";

const Instructors = ({ classroomData }) => {
    const [instructorData, setInstructorData] = useState([]);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();

    const getInstructors = useCallback(async () => {
        try {
            const data = await fetchInstructors(classroomData.id);
            setInstructorData(data || []); // Ensure we always set an array
        } catch (error) {
            if (error.status === 401) {
                navigate('/timeout');
            } else {
                console.error('Error fetching instructors:', error);
                setInstructorData([]);
            }
        }
    }, [classroomData.id, navigate]);

    useEffect(() => {
        getInstructors();
    }, [getInstructors]);

    const handleRemoveInstructor = async (instructor) => {
        // TODO: Implement remove instructor functionality
        console.log('Remove instructor:', instructor);
    };

    return (
        <div className="flex">
            <div className="flex flex-col w-[85%] gap-4 p-4 md:w-full">
                <div className="flex w-full gap-2 justify-end">
                    <button
                        onClick={() => setPopupOpen(true)}
                        className={`flex justify-center h-[80%] items-center p-4 font-semibold text-blue-500 text-sm border border-blue-500 bg-white rounded-lg hover:bg-blue-100 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100`}
                    >
                        Add Instructor
                    </button>
                </div>

                <AddInstructorPopup
                    open={isPopupOpen}
                    onClose={() => setPopupOpen(false)}
                    onInstructorAdded={getInstructors}
                    classroomData={classroomData} />

                <div className="flex flex-col h-full w-full border border-gray-300 rounded-lg overflow-hidden">
                    <InstructorsTable 
                        instructors={instructorData} 
                        onRemove={handleRemoveInstructor}
                    />
                </div>
            </div>

            <div className="flex-col md:w-[25%] h-full p-4 hidden lg:flex">
                <QuickLink classroomData={classroomData} />
            </div>
        </div>
    );
};

export default Instructors;
