// src/components/ClassroomStudentsTable.jsx

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatUSD } from 'utils';
import {
    IconTrash,
    IconSortAscendingLetters,
    IconSortDescendingLetters,
    IconSortAscendingNumbers,
    IconSortDescendingNumbers,
} from '@tabler/icons-react';

const StudentsTable = ({ students, onDeleteClick, disabled }) => {
    // Define sort configuration state
    const [sortConfig, setSortConfig] = useState({
        key: null, // The column to sort by
        direction: 'ascending', // 'ascending' or 'descending'
        type: null, // 'string' or 'number'
    });

    // Function to handle sorting when a header is clicked
    const handleSort = (key, type) => {
        if (sortConfig.key === key) {
            // Toggle sort direction
            const newDirection = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
            setSortConfig({ key, direction: newDirection, type });
        } else {
            // Start sorting by this key in ascending order
            setSortConfig({ key, direction: 'ascending', type });
        }
    };

    // Memoized sorted students to optimize performance
    const sortedStudents = useMemo(() => {
        if (!sortConfig.key) return students;

        const sorted = [...students].sort((a, b) => {
            if (sortConfig.type === 'string') {
                return sortConfig.direction === 'ascending'
                    ? a[sortConfig.key].localeCompare(b[sortConfig.key])
                    : b[sortConfig.key].localeCompare(a[sortConfig.key]);
            } else if (sortConfig.type === 'number') {
                return sortConfig.direction === 'ascending'
                    ? a[sortConfig.key] - b[sortConfig.key]
                    : b[sortConfig.key] - a[sortConfig.key];
            }
            return 0;
        });

        return sorted;
    }, [students, sortConfig]);

    if (!students || students.length === 0) {
        return (
            <div className="container mx-auto p-4">
                <p className="text-center text-accent">No students available.</p>
            </div>
        );
    }

    return (
        <div className="container h-[56vh]">
            {/* Scrollable Container */}
            <div className="h-full overflow-y-auto border border-gray-200 rounded-b">
                <table className="min-w-full bg-white border-collapse">
                    <thead className="sticky top-0 bg-gray-100">
                        <tr>
                            {/* First Name Header */}
                            <th
                                className="py-2 px-4 border-b text-left text-sm font-medium text-gray-700 cursor-pointer"
                                aria-sort={
                                    sortConfig.key === 'first'
                                        ? sortConfig.direction === 'ascending'
                                            ? 'ascending'
                                            : 'descending'
                                        : 'none'
                                }
                                role="columnheader"
                                onClick={() => handleSort('first', 'string')}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        handleSort('first', 'string');
                                    }
                                }}
                            >
                                <div className="flex items-center">
                                    First Name
                                    {/* Inline Conditional Rendering of Sort Icon */}
                                    {sortConfig.key === 'first' ? (
                                        sortConfig.direction === 'ascending' ? (
                                            <IconSortAscendingLetters className="ml-1 text-primary" size={16} />
                                        ) : (
                                            <IconSortDescendingLetters className="ml-1 text-primary" size={16} />
                                        )
                                    ) : (
                                        // Default to ascending icon when not sorted
                                        <IconSortAscendingLetters className="ml-1 text-gray-400" size={16} />
                                    )}
                                </div>
                            </th>

                            {/* Last Name Header */}
                            <th
                                className="py-2 px-4 border-b text-left text-sm font-medium text-gray-700 cursor-pointer"
                                aria-sort={
                                    sortConfig.key === 'last'
                                        ? sortConfig.direction === 'ascending'
                                            ? 'ascending'
                                            : 'descending'
                                        : 'none'
                                }
                                role="columnheader"
                                onClick={() => handleSort('last', 'string')}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        handleSort('last', 'string');
                                    }
                                }}
                            >
                                <div className="flex items-center">
                                    Last Name
                                    {/* Inline Conditional Rendering of Sort Icon */}
                                    {sortConfig.key === 'last' ? (
                                        sortConfig.direction === 'ascending' ? (
                                            <IconSortAscendingLetters className="ml-1 text-primary" size={16} />
                                        ) : (
                                            <IconSortDescendingLetters className="ml-1 text-primary" size={16} />
                                        )
                                    ) : (
                                        // Default to ascending icon when not sorted
                                        <IconSortAscendingLetters className="ml-1 text-gray-400" size={16} />
                                    )}
                                </div>
                            </th>

                            {/* Balance Header */}
                            <th
                                className="py-2 px-4 border-b text-left text-sm font-medium text-gray-700 cursor-pointer"
                                aria-sort={
                                    sortConfig.key === 'balance'
                                        ? sortConfig.direction === 'ascending'
                                            ? 'ascending'
                                            : 'descending'
                                        : 'none'
                                }
                                role="columnheader"
                                onClick={() => handleSort('balance', 'number')}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        handleSort('balance', 'number');
                                    }
                                }}
                            >
                                <div className="flex items-center">
                                    Balance
                                    {/* Inline Conditional Rendering of Sort Icon */}
                                    {sortConfig.key === 'balance' ? (
                                        sortConfig.direction === 'ascending' ? (
                                            <IconSortAscendingNumbers className="ml-1 text-primary" size={16} />
                                        ) : (
                                            <IconSortDescendingNumbers className="ml-1 text-primary" size={16} />
                                        )
                                    ) : (
                                        // Default to ascending icon when not sorted
                                        <IconSortAscendingNumbers className="ml-1 text-gray-400" size={16} />
                                    )}
                                </div>
                            </th>

                            {/* Empty Header for Delete Button */}
                            <th className="py-2 px-4 border-b text-left text-sm font-medium text-gray-700 w-[50px]"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedStudents.map((student) => (
                            <tr
                                key={student.id}
                                className={`hover:bg-gray-50 ${
                                    disabled ? 'opacity-50 pointer-events-none' : ''
                                }`}
                            >
                                <td className="py-2 px-4 border-b text-sm text-accent whitespace-nowrap">
                                    {student.first}
                                </td>
                                <td className="py-2 px-4 border-b text-sm text-accent whitespace-nowrap">
                                    {student.last}
                                </td>
                                <td className="py-2 px-4 border-b text-sm text-accent whitespace-nowrap">
                                    {formatUSD(student.balance)}
                                </td>
                                <td className="py-2 px-4 border-b text-sm text-accent whitespace-nowrap">
                                    <button
                                        onClick={() => onDeleteClick(student)}
                                        className="p-1 text-accent hover:text-red-500 transition-colors duration-200"
                                        aria-label="Delete student"
                                        disabled={disabled}
                                    >
                                        <IconTrash size={20} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

// PropTypes for type checking
StudentsTable.propTypes = {
    students: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            first: PropTypes.string.isRequired,
            last: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            balance: PropTypes.number.isRequired,
        })
    ).isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

StudentsTable.defaultProps = {
    disabled: false,
};

export default StudentsTable;
