import React from "react";
import { Helmet } from "react-helmet";
import { Text, Heading } from "./../../../../components";
import LoginSideGraphic from "components/LoginSideGraphic";
import TopNavLogin from "components/TopNavLogin";
import TeacherRegisterForm from "./TeacherRegisterForm";



export default function RegisterTeacherPage() {

  return (
    <>
      <Helmet>
        <title>Register | Intertwined</title>
        <meta name="description" content="Intertwined Finance" />
      </Helmet>

      <div className="flex h-screen overscroll-none ">
        <div className="w-[75%] bg-white-A700 md:w-full relative md:absolute">
          <TopNavLogin className="absolute top-0 left-0 right-0 z-10 md:relative" type={"register"} />

          <div className="flex-1 flex flex-col gap-16 h-full justify-center items-center">
            <div className="gap-3 flex flex-col items-center">
              <Heading size="12xl" as="h1" className="!text-gray-900_05 flex gap-2 items-center">
                Teacher Registration
              </Heading>
              <Text as="p" className="w-full text-center font-semibold  !text-gray-900_05">
                Create an online classroom environment that cultivates learning, curiosity,
                and collaboration.
              </Text>

            </div>



            <div className="flex items-center justify-center w-[80%] gap-16 md:w-full md:flex-col">
              <TeacherRegisterForm />
            </div>

          </div>
        </div>

        <div className="w-[25%] md:hidden">
          <LoginSideGraphic/>
        </div>
      </div>
    </>
  );
}