import React, { useEffect } from 'react';

const ProgressBar = ({ color, backgroundColor, progress }) => {

    useEffect(() => {

    }, []);

    return (
        <div className="flex w-full items-center gap-2 transition">

            <div className={`w-full ${backgroundColor} rounded-full h-1 dark:bg-gray-700`}>
                <div className={`rounded-full h-1 ${color} w-[${Math.min(progress, 100)}%]`}></div>
            </div>

            <h1 className="!text-gray-800_01 text-xs font-bold">
                {Math.min([progress], 100)}%
            </h1>
        </div>
    );
};

export default ProgressBar;