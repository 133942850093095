import React, { useEffect, useRef } from 'react';

const FundamentalDataWidget = ({ symbol, exchange = 'NASDAQ' }) => {
    const widgetContainerRef = useRef(null);

    useEffect(() => {
        const widgetConfig = {
            "symbol": `${exchange}:${symbol}`,
            "colorTheme": "light",
            "isTransparent": false,
            "largeChartUrl": "",
            "displayMode": "regular",
            "width": "100%",
            "height": "100%",
            "locale": "en"
        };

        if (widgetContainerRef.current) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-financials.js';
            script.innerHTML = JSON.stringify(widgetConfig);
            widgetContainerRef.current.innerHTML = ''; // Clear the container
            widgetContainerRef.current.appendChild(script);
        }
    }, [symbol, exchange]); // Re-run effect when symbol or exchange changes

    return (
        <div className="tradingview-widget-container" ref={widgetContainerRef}>
            <div className="tradingview-widget-container__widget"></div>
            <div className="tradingview-widget-copyright">
            </div>
        </div>
    );
};

export default FundamentalDataWidget;