import React, { useState } from "react";
import { Text, Heading } from "./..";
import { postTrade } from "SimulatorAPIService";
import Popup from 'reactjs-popup'

export default function LoginPageColumnTwo({
  studentId,
  stockSymbol,
  stockName,
  stockPrice,
  tradeordertext = "Place a trade order",
  ...props
}) {
  const [activeButton, setActiveButton] = useState('buy');
  const [activeCircle, setActiveCircle] = useState('Market');
  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('0');
  const [description, setDescription] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [orderStatus, setOrderStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  const handlePlaceOrder = async () => {
    setHasAttemptedSubmit(true);
    // Add validation for required explanation
    if (!description.trim()) {
      setErrorMessage('Please provide an explanation for your trade.');
      setOrderStatus('error');
      setPopupOpen(true);
      return;
    }

    const tradeData = {
      symbol: stockSymbol,
      name: stockName,
      numShares: quantity,
      limitPrice: price,
      stopPrice: price,
      orderAction: activeButton === 'buy' ? 0 : 1,
      orderType: activeCircle === 'Market' ? 0 : activeCircle === 'Limit' ? 1 : 2,
      description: description
    }

    try {
      setIsLoading(true);
      const response = await postTrade(studentId, tradeData);

      // Check for error conditions in the response
      if (response.displayIncorrectTimeframeError) {
        setErrorMessage('The timeframe for this trade is incorrect.');
      } else if (response.displayInsufficientExplanationError) {
        setErrorMessage('The explanation for this trade is insufficient.');
      } else if (response.displayInsufficientFundsError) {
        setErrorMessage('You have insufficient funds to place this trade.');
      } else if (response.displayInsufficientQuantityOfSharesError) {
        setErrorMessage('The quantity of shares is insufficient.');
      } else if (response.displayInsufficientSharesError) {
        setErrorMessage('There are insufficient shares available.');
      } else if (response.displayLongExplanationError) {
        setErrorMessage('The explanation for this trade is too long.');
      } else if (response.displayNoDataError) {
        setErrorMessage('No data available for this trade.');
      } else {
        // If there are no errors, set the order status to success and reset the form
        setOrderStatus('success');
        setQuantity('');
        setPrice('0');
        setDescription("");
        setIsLoading(false);
        setPopupOpen(true);
        setHasAttemptedSubmit(false); // Reset the validation state
        return;
      }

      // If an error is found, set the order status to error and display the popup
      setOrderStatus('error');
      setIsLoading(false);
      setPopupOpen(true);
    } catch (error) {
      console.error('Error posting trade:', error);
      setErrorMessage('There was an unexpected error posting your trade.');
      setOrderStatus('error');
      setIsLoading(false);
      setPopupOpen(true);
      setDescription("");
    }
  };


  const handleInputChange = (e, setState) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setState(value);
    }
  };

  return (
    <div {...props} className="w-full p-4">
      <div className="flex w-full flex-col gap-4">
        <span className='font-bold text-lg mb-2'>{tradeordertext}</span>
        <div className="flex space-x-4">
          <button
            className={`flex-1 py-2 rounded ${activeButton === 'buy' ? 'bg-blue-500 text-white-A700' : 'bg-gray-200'}`}
            onClick={() => setActiveButton('buy')}
          >
            Buy
          </button>
          <button
            className={`flex-1 py-2 rounded ${activeButton === 'sell' ? 'bg-red-500 text-white-A700' : 'bg-gray-200'}`}
            onClick={() => setActiveButton('sell')}
          >
            Sell
          </button>
        </div>
        <div className="flex space-x-4 justify-evenly">
          {['Market', 'Limit', 'Stop'].map((text) => (
            <div key={text} className="flex items-center space-x-2">
              <input
                type="radio"
                id={text}
                name="orderType"
                value={text}
                checked={activeCircle === text}
                onChange={() => setActiveCircle(text)}
                className="form-radio"
              />
              <label htmlFor={text} className="cursor-pointer">{text}</label>
            </div>
          ))}
        </div>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col">
            <label className="mb-1">Quantity of Stock</label>
            <div className="border border-gray-400 p-2 rounded">
              <input
                type="text"
                value={quantity}
                onChange={(e) => handleInputChange(e, setQuantity)}
                pattern="\d*"
                className=""
              />
            </div>
          </div>
          {activeCircle !== 'Market' && (
            <div className="flex flex-col">
              <label className="mb-1">Price</label>
              <div className="flex items-center border border-gray-400 p-2 rounded focus:outline-none focus:ring-1 focus:ring-gray-600">
                <span className="mr-1">$</span>
                <input
                  type="text"
                  value={price}
                  onChange={(e) => handleInputChange(e, setPrice)}
                  pattern="\d*"
                  className="flex-grow focus:outline-none"
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <label className="mb-1">
            Explain your trade <span className="text-red-500">*</span>
          </label>
          <textarea
            className={`border ${hasAttemptedSubmit && !description.trim() ? 'border-red-400' : 'border-gray-400'} p-2 rounded placeholder-gray-600 focus:ring-1 focus:ring-gray-400`}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            maxLength="200"
            placeholder="Explain your trade in 200 words or less (required)"
            required
            onInput={(e) => {
              const words = e.target.value.split(/\s+/);
              if (words.length > 200) {
                e.target.value = words.slice(0, 200).join(" ");
              }
            }}
          />
          <div className="flex justify-between items-center mt-1">
            <small className="text-left text-red-500">
              {hasAttemptedSubmit && !description.trim() ? 'Explanation is required' : ''}
            </small>
            <small className="text-right text-gray-600">200 character max</small>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4">
          <div className="font-semibold">
            Transaction Total: ${quantity * stockPrice}
          </div>
          <button
            className={`bg-blue-500 text-white-A700 px-4 py-2 rounded hover:bg-blue-600 ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500'}`}
            onClick={handlePlaceOrder}
            disabled={isLoading}
          >
            {isLoading ? "Placing Order..." : "Place order"}
          </button>
        </div>
      </div>
      <Popup open={popupOpen} onClose={() => setPopupOpen(false)} modal contentStyle={{ width: '350px', height: '200px', borderRadius: '1rem' }}>
        <div className="modal flex flex-col items-center justify-center h-full">
          {orderStatus === 'success' ? (
            <div className="flex flex-col items-center justify-center h-full gap-4 bg-white-A700 border border-gray-200 shadow-md p-4 rounded-lg">
              <Heading size="6xl" className="text-green-500">Order Successful</Heading>
              <Text className="text-center">
                {activeButton === 'buy' 
                  ? "Your purchase order has been placed successfully! You can view this stock in your holdings once the order executes."
                  : "Your sell order has been placed successfully! The proceeds will be available in your account once the order executes."
                }
              </Text>
              <button
                className="bg-blue-500 text-white-A700 px-4 py-2 rounded hover:bg-blue-600 mt-4"
                onClick={() => setPopupOpen(false)}
              >
                Dismiss
              </button>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-full gap-4 bg-white-A700 border border-gray-200 shadow-md p-4 rounded-lg">
              <Heading size="6xl">Order Failed</Heading>
              <Text className="text-center">{errorMessage}</Text>
              <button
                className="bg-blue-500 text-white-A700 px-4 py-2 rounded hover:bg-blue-600 mt-4"
                onClick={() => setPopupOpen(false)}
              >
                Dismiss
              </button>
            </div>
          )}
        </div>
      </Popup>
    </div>
  );
}