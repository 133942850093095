import React, { useState, useEffect } from "react";
import { Heading } from "../../components";
import { ReactTable } from "../../components/ReactTable";
import { createColumnHelper } from "@tanstack/react-table";
import { ReactTableHoldings } from "components/ReactTableHoldings";
import { fetchStudentPortfolioGraph, fetchStudentStocks, fetchStudentTradeOrders } from "SimulatorAPIService";
import Skeleton from "react-loading-skeleton"
import 'react-loading-skeleton/dist/skeleton.css'
import { Link } from "react-router-dom";
import PortfolioChart from "components/PortfolioChart";
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import { formatDate } from "utils";


export default function StudentStockSimulator({ studentId }) {
    const [expandedRows, setExpandedRows] = useState({});

    const table6Columns = React.useMemo(() => {
        const table6ColumnHelper = createColumnHelper();
        return [
            table6ColumnHelper.accessor("name", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center !text-blue-500 border-solid border-blue_gray-100">
                        <Link
                            to={{
                                pathname: `/stockview/${info.row.original.symbol}`,
                                state: { symbol: info.row.original.symbol } // Pass the symbol in the state
                            }}
                            className="text-blue-500 hover:text-blue-600 text-center"
                        >
                            {info.getValue()}
                        </Link>
                    </div>
                ),
                header: () => "Stock",
                meta: { width: "full" },
            }),
            table6ColumnHelper.accessor("symbol", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        {info?.getValue?.()}
                    </div>
                ),
                header: () => "Symbol",
                meta: { width: "full" },
            }),
            table6ColumnHelper.accessor("shareCount", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        {info?.getValue?.()}
                    </div>
                ),
                header: () => "Qty",
                meta: { width: "full" },
            }),
            table6ColumnHelper.accessor("avgcost", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        {info?.getValue?.()}
                    </div>
                ),
                header: () => "Avg. Cost",
                meta: { width: "full" },
            }),
            table6ColumnHelper.accessor("Current", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        {info?.getValue?.()}
                    </div>
                ),
                header: () => "Ltp",
                meta: { width: "full" },
            }),
            table6ColumnHelper.accessor("current", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        {info?.getValue?.()}
                    </div>
                ),
                header: () => "Current Value",
                meta: { width: "full" },
            }),
            table6ColumnHelper.accessor("pl", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        {info?.getValue?.()}
                    </div>
                ),
                header: () => "P&L",
                meta: { width: "full" },
            }),
        ];
    }, []);

    const table5Columns = React.useMemo(() => {
        const table5ColumnHelper = createColumnHelper();
        return [
            table5ColumnHelper.display({
                id: "expand",
                cell: ({ row }) => (
                    <div className="flex items-center justify-center">
                        <button onClick={() => handleExpandRow(row.index)} className="mr-2">
                            {expandedRows[row.index] ? (<IconChevronDown />) : (<IconChevronUp />)}
                        </button>
                    </div>
                ),
                meta: { width: "40px" },
            }),
            table5ColumnHelper.accessor("tickerName", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        <Link
                            to={{
                                pathname: `/stockview/${info.row.original.tickerSymbol}`,
                                state: { symbol: info.row.original.tickerSymbol } // Pass the symbol in the state
                            }}
                            className="text-blue-500 hover:text-blue-600 text-center"
                        >
                            {info.getValue()}
                        </Link>
                    </div>
                ),
                header: () => "Stock",
                meta: { width: "full" },
            }),
            table5ColumnHelper.accessor("tickerSymbol", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        {info.getValue()}
                    </div>
                ),
                header: () => "Symbol",
                meta: { width: "full" },
            }),
            table5ColumnHelper.accessor("action", {
                cell: (info) => (
                    <div className="flex items-center justify-center">
                        {info.getValue() === 0 ? (
                            <div className="bg-green-100 text-green-700 px-2 py-1 rounded">BUY</div>
                        ) : (
                            <div className="bg-red-100 text-red-700 px-2 py-1 rounded">SELL</div>
                        )}
                    </div>
                ),
                header: () => "Action",
                meta: { width: "129px" },
            }),
            table5ColumnHelper.accessor("type", {
                cell: (info) => (
                    <div className="flex items-center justify-center">
                        {info.getValue() === 0 ? "Market" : info.getValue() === 1 ? "Limit" : "Stop"}
                    </div>
                ),
                header: () => "Type",
                meta: { width: "129px" },
            }),
            table5ColumnHelper.accessor("quantityOfShares", {
                cell: (info) => (
                    <div className="flex items-center justify-center">
                        {Math.abs(info.getValue())}
                    </div>
                ),
                header: () => "Qty",
                meta: { width: "129px" },
            }),
            table5ColumnHelper.accessor("activeDetails.pricePerShare", {
                cell: (info) => (
                    <div className="flex items-center justify-center">
                        {info.getValue()}
                    </div>
                ),
                header: () => "Price",
                meta: { width: "129px" },
            }),
            table5ColumnHelper.accessor("activeDetails.stockValue", {
                cell: (info) => (
                    <div className="flex items-center justify-center">
                        {info.getValue()}
                    </div>
                ),
                header: () => "Total Amount",
                meta: { width: "129px" },
            }),
            table5ColumnHelper.accessor("activeDetails.submittedUtc", {
                cell: (info) => (
                    <div className="flex w-full justify-between ml-5 items-center border-solid border-blue_gray-100" style={{ marginLeft: '10px' }}>
                        {formatDate(info.getValue())}
                    </div>
                ),
                header: () => "Purchase Date",
                meta: { width: "full" },
            }),

        ];
    }, [expandedRows]);

    const table4Columns = React.useMemo(() => {
        const table4ColumnHelper = createColumnHelper();
        return [
            table4ColumnHelper.display({
                id: "expand",
                cell: ({ row }) => (
                    <div className="flex items-center justify-center">
                        <button onClick={() => handleExpandRow(row.index)} className="mr-2">
                            {expandedRows[row.index] ? (<IconChevronDown />) : (<IconChevronUp />)}
                        </button>
                    </div>
                ),
                meta: { width: "40px" },
            }),
            table4ColumnHelper.accessor("tickerName", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        <Link to="/orders" className="text-blue-500 hover:text-blue-600">
                            {info.getValue()}
                        </Link>
                    </div>
                ),
                header: () => "Stock",
                meta: { width: "full" },
            }),
            table4ColumnHelper.accessor("tickerSymbol", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        {info.getValue()}
                    </div>
                ),
                header: () => "Symbol",
                meta: { width: "full" },
            }),
            table4ColumnHelper.accessor("action", {
                cell: (info) => (
                    <div className="flex items-center justify-center">
                        {info.getValue() === 0 ? (
                            <div className="bg-green-100 text-green-700 px-2 py-1 rounded">BUY</div>
                        ) : (
                            <div className="bg-red-100 text-red-700 px-2 py-1 rounded">SELL</div>
                        )}
                    </div>
                ),
                header: () => "Action",
                meta: { width: "129px" },
            }),
            table4ColumnHelper.accessor("type", {
                cell: (info) => (
                    <div className="flex items-center justify-center">
                        {info.getValue() === 0 ? "Market" : info.getValue() === 1 ? "Limit" : "Stop"}
                    </div>
                ),
                header: () => "Type",
                meta: { width: "129px" },
            }),
            table4ColumnHelper.accessor("quantityOfShares", {
                cell: (info) => (
                    <div className="flex items-center justify-center">
                        {Math.abs(info.getValue())}
                    </div>
                ),
                header: () => "Qty",
                meta: { width: "129px" },
            }),
            table4ColumnHelper.accessor("executedDetails.pricePerShare", {
                cell: (info) => (
                    <div className="flex items-center justify-center">
                        {info.getValue()}
                    </div>
                ),
                header: () => "Price",
                meta: { width: "129px" },
            }),
            table4ColumnHelper.accessor("executedDetails.stockValue", {
                cell: (info) => (
                    <div className="flex items-center justify-center">
                        {info.getValue()}
                    </div>
                ),
                header: () => "Total Amount",
                meta: { width: "129px" },
            }),
            table4ColumnHelper.accessor("executedDetails.executedUtc", {
                cell: (info) => (
                    <div className="flex w-full justify-between ml-5 items-center border-solid border-blue_gray-100" style={{ marginLeft: '10px' }}>
                        {formatDate(info.getValue())}
                    </div>
                ),
                header: () => "Purchase Date",
                meta: { width: "full" },
            }),
        ];
    }, [expandedRows]);

    const [stocks, setStocks] = useState([]);
    const [portfolioGraph, setPortfolioGraph] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [orders, setOrders] = useState([]);

    

    const handleExpandRow = (rowIndex) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [rowIndex]: !prevState[rowIndex],
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (studentId) {
                    const portfolioData = await fetchStudentPortfolioGraph(studentId);
                    setPortfolioGraph(portfolioData);

                    const stockData = await fetchStudentStocks(studentId);
                    setStocks(stockData);

                    const tradeOrders = await fetchStudentTradeOrders(studentId);
                    setOrders(tradeOrders);
                }
            } catch (error) {
                console.error('Error fetching data or posting trade:', error);
            } finally {
                setIsLoading(false);
            }

        };

        fetchData();
    }, [studentId]);


    return (
        <div className="flex flex-col overflow-y-auto gap-4">

            <div className="flex">
                {/* <div className="w-[30%] border rounded-lg p-4 items-center justify-center">
                    <h2 className="font-semibold">Trading Dates</h2>
                    
                    <h2 className="flex h-full items-center justify-center text-center">Coming soon!</h2>
                </div> */}
                

                <div className="flex w-full flex-col gap-6 rounded-lg border border-solid border-blue_gray-50_01 bg-white-A700 p-4 ml-3 md:w-full">
                    <PortfolioChart portfolioData={portfolioGraph} height={250} />
                </div>
            </div>



            <div className="flex flex-col w-full gap-4 py-4">
                <h2 className="font-medium">Portfolio</h2>

                <div className="flex w-full rounded-lg  items-center justify-center border border-solid border-blue_gray-50_01 bg-white-A700 px-4 py-[19px] sm:flex-col">
                    <div className="flex flex-col items-center gap-2 border-r border-solid border-blue_gray-100 px-[29px] sm:px-5">
                        <div className="flex self-start">
                            <Heading as="h3" className="!font-poppins !text-gray-500_02">
                                Balance Funds
                            </Heading>
                        </div>
                        <div className="flex-1 w-full self-start">
                            <Heading size="7xl" as="h4" className="!text-gray-800_02">
                                <span>${portfolioGraph.currentBalance?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || <Skeleton width={"85%"} count={1} />}</span>
                            </Heading>
                        </div>
                    </div>
                    <div className="flex flex-col items-center gap-2 border-l border-r border-solid border-blue_gray-100 px-9 sm:px-5">
                        <div className="flex self-start">
                            <Heading as="h5" className="!font-poppins !text-gray-500_02">
                                Total Investment
                            </Heading>
                        </div>
                        <div className="flex-1 w-full self-start">
                            <Heading size="7xl" as="h6" className="!text-gray-800_02">
                                <span>$
                                    {isNaN(portfolioGraph.startingBalance - portfolioGraph.currentBalance) ? (
                                        <Skeleton width="100px" height="1em" />
                                    ) : (
                                        `${(portfolioGraph.startingBalance - portfolioGraph.currentBalance).toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}`
                                    )}
                                </span>
                            </Heading>
                        </div>
                    </div>
                    <div className="flex flex-col items-center gap-2 border-l border-r border-solid border-blue_gray-100 px-[42px] md:px-5">
                        <div className="flex self-start">
                            <Heading as="p" className="!font-poppins !text-gray-500_02">
                                Current Value
                            </Heading>
                        </div>
                        <div className="flex-1 w-full self-start">
                            <Heading size="7xl" as="h6" className="!text-gray-800_02">
                                <span>${portfolioGraph.portfolioTotalValue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || <Skeleton width={"85%"} count={1} />}</span>
                            </Heading>
                        </div>
                    </div>
                    <div className="flex flex-col items-center text-center gap-2 border-l border-r border-solid border-blue_gray-100 px-7 sm:px-5">
                        <div className="flex">
                            <Heading as="p" className="!font-poppins !text-gray-500_02">
                                Day's P&L
                            </Heading>
                        </div>
                        <div className="flex-1 w-full self-start">
                            <Heading size="7xl" as="h6">
                                <span>
                                    ${portfolioGraph.historicalGraphPoints &&
                                        portfolioGraph.historicalGraphPoints.length > 1 ? (
                                        <>
                                            {(
                                                portfolioGraph.portfolioTotalValue -
                                                portfolioGraph.historicalGraphPoints[portfolioGraph.historicalGraphPoints.length - 1].portfolioValue
                                            ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            <span
                                                className={
                                                    (portfolioGraph.portfolioTotalValue -
                                                        portfolioGraph.historicalGraphPoints[portfolioGraph.historicalGraphPoints.length - 1].portfolioValue) > 0
                                                        ? "text-green-500"
                                                        : "text-red-500"
                                                }
                                            >
                                                {` (${(
                                                    ((portfolioGraph.portfolioTotalValue -
                                                        portfolioGraph.historicalGraphPoints[portfolioGraph.historicalGraphPoints.length - 1].portfolioValue) /
                                                        portfolioGraph.historicalGraphPoints[portfolioGraph.historicalGraphPoints.length - 1].portfolioValue) * 100
                                                ).toFixed(2)
                                                    }%)`}
                                            </span>
                                        </>
                                    ) : (
                                        <Skeleton width={"65%"} count={1} />
                                    )}
                                </span>
                            </Heading>
                        </div>
                    </div>
                    <div className="flex flex-col items-center text-center gap-2 border-l border-solid border-blue_gray-100 px-[35px] sm:px-5">
                        <div className="flex">
                            <Heading as="p" className="!font-poppins !text-gray-500_02">
                                Total P&L
                            </Heading>
                        </div>
                        <div className="flex-1 w-full self-start">
                            <Heading size="7xl" as="h6" className="!font-poppins !text-gray-800_02">
                                <span>$
                                    {portfolioGraph.portfolioTotalValue !== undefined &&
                                        portfolioGraph.startingBalance !== undefined ? (
                                        <>
                                            {(
                                                portfolioGraph.portfolioTotalValue - (portfolioGraph.startingBalance - portfolioGraph.currentBalance)
                                            ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            <span
                                                className={
                                                    (portfolioGraph.portfolioTotalValue - (portfolioGraph.startingBalance - portfolioGraph.currentBalance)) > 0
                                                        ? "text-green-500"
                                                        : "text-red-500"
                                                }
                                            >
                                                {` (${(
                                                    ((portfolioGraph.portfolioTotalValue - (portfolioGraph.startingBalance - portfolioGraph.currentBalance)) /
                                                        portfolioGraph.startingBalance) * 100
                                                ).toFixed(2)
                                                    }%)`}
                                            </span>
                                        </>
                                    ) : (
                                        <Skeleton width={"65%"} count={1} />
                                    )}
                                </span>
                            </Heading>
                        </div>
                    </div>
                </div>

            </div>

            <div>
                <ReactTableHoldings
                    size="2xl"
                    bodyProps={{ className: "w-full" }}
                    headerProps={{
                        className: "w-full border-blue_gray-100 border-b border-solid bg-gray-50_01 md:flex-col",
                    }}
                    rowDataProps={{ className: "w-full border-t border-b border-solid border-blue_gray-100" }}
                    className="w-full"
                    columns={table6Columns}
                    data={isLoading ? [] : stocks}
                    isLoading={isLoading} // Add this prop to handle loading state
                />
            </div>

            <div className="flex flex-col gap-4">
                <Heading size="6xl" as="h3">
                    Pending Orders
                </Heading>

                <ReactTable
                    size="2xl"
                    bodyProps={{ className: "w-full" }}
                    headerProps={{
                        className: "w-full border-blue_gray-100 border-b border-solid bg-gray-50_01 md:flex-col",
                    }}
                    rowDataProps={{ className: "w-full border-b border-solid border-blue_gray-100" }}
                    className="w-full rounded-lg"
                    columns={table5Columns}
                    data={isLoading ? [] : orders?.active}
                    isLoading={isLoading}
                    expandedRows={expandedRows}
                    handleExpandRow={handleExpandRow}
                    orderType="active"
                />
                <div className="flex self-start">
                    <Heading size="6xl" as="h6">
                        Completed Orders
                    </Heading>
                </div>
                <ReactTable
                    size="2xl"
                    bodyProps={{ className: "w-full" }}
                    headerProps={{
                        className: "w-full border-blue_gray-100 border-b border-solid bg-gray-50_01 md:flex-col",
                    }}
                    rowDataProps={{ className: "border-t justify-center border-b border-solid border-blue_gray-100" }}
                    className="w-full"
                    columns={table4Columns}
                    data={isLoading ? [] : orders?.executed}
                    isLoading={isLoading}
                    expandedRows={expandedRows}
                    handleExpandRow={handleExpandRow}
                    orderType="executed"
                />
            </div>




        </div>
    );
};

