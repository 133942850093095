import React, { useEffect, useState, useMemo } from "react";
import { Heading, Text } from "../../components";
import { fetchAssignments, fetchStudentDetails, fetchSection } from "StudentAPIService";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "components/LoadingAnimation"; // Import the LoadingAnimation component

// Helper function to get the start of the current week (Sunday)
const getWeekDates = () => {
  const today = new Date();
  const currentDayOfWeek = today.getDay(); // 0-6 (Sun-Sat)
  const firstDayOfWeek = new Date(today);
  firstDayOfWeek.setDate(today.getDate() - currentDayOfWeek);

  const week = Array.from({ length: 7 }, (_, i) => {
    const date = new Date(firstDayOfWeek);
    date.setDate(firstDayOfWeek.getDate() + i);
    return date;
  });
  return { today, week };
};

const DashboardCalendar = ({ studentId }) => {
  const [assignmentsByDate, setAssignmentsByDate] = useState({});
  const [sections, setSections] = useState({});
  const [classrooms, setClassrooms] = useState({});
  const [selectedDay, setSelectedDay] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const { today, week: currentWeek } = useMemo(getWeekDates, []);

  useEffect(() => {
    if (!studentId) return;

    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch assignments and student info in parallel
        const [assignments, studentInfo] = await Promise.all([
          fetchAssignments(studentId),
          fetchStudentDetails(studentId),
        ]);

        // Extract and store classrooms
        const classroomsData = (studentInfo.courses || []).reduce((acc, course) => {
          acc[course.class.id] = course.class;
          return acc;
        }, {});
        setClassrooms(classroomsData);

        // Fetch all sections for these assignments in parallel
        // Deduplicate sectionIds to reduce calls
        const uniqueSectionIds = Array.from(new Set(assignments.map(a => a.sectionId)));
        const sectionPromises = uniqueSectionIds.map((sectionId) => fetchSection(sectionId));
        const sectionResults = await Promise.all(sectionPromises);

        const sectionsData = sectionResults.reduce((acc, section) => {
          acc[section.id] = section;
          return acc;
        }, {});
        setSections(sectionsData);

        // Precompute assignments by date for quick lookup
        const assignmentsMap = {};
        for (const assignment of assignments) {
          const dueDate = new Date(assignment.dueUTC);
          const dayStr = dueDate.toDateString();
          if (!assignmentsMap[dayStr]) {
            assignmentsMap[dayStr] = [];
          }
          assignmentsMap[dayStr].push(assignment);
        }

        setAssignmentsByDate(assignmentsMap);
        setSelectedDay(today);
      } catch (error) {
        if (error?.message === "401") {
          navigate("/timeout");
        } else {
          console.error("Error fetching data:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [studentId, navigate, today]);

  // Function to get assignments for a selected day from the precomputed map
  const getAssignmentsForSelectedDay = () => {
    if (!selectedDay) return [];
    const dayStr = selectedDay.toDateString();
    return assignmentsByDate[dayStr] || [];
  };

  const assignmentsForDay = getAssignmentsForSelectedDay();

  return (
    <div className="flex flex-col font-inter divide-y w-full h-full bg-white-A700 rounded-lg">
      <h1 className="font-semibold text-xs lg:text-md 2xl:text-base text-accent p-2">
        Upcoming Events
      </h1>

      <div className="flex flex-col w-full h-full">
        <div className="flex justify-between w-full p-2 2xl:p-4">
          {currentWeek.map((date, index) => (
            <div key={index} className="flex flex-col items-center justify-evenly gap-2">
              <p className="text-sm 2xl:text-md">
                {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][date.getDay()]}
              </p>
              <button
                onClick={() => setSelectedDay(date)}
                className={`flex flex-col items-center justify-center w-8 h-8 rounded-full ${
                  selectedDay && selectedDay.toDateString() === date.toDateString()
                    ? "bg-blue-200"
                    : "bg-white-A700 hover:bg-gray-300_02"
                }`}
              >
                <Text size="7xl" as="p">
                  {date.getDate()}
                </Text>
              </button>
            </div>
          ))}
        </div>

        <div className="flex flex-col h-full overflow-y-auto p-4">
          {selectedDay && (
            <div className="flex flex-col gap-2 grow rounded-lg border border-solid border-blue_gray-100 p-4">
              <Text size="4xl" as="p" className="!text-gray-700">
                {selectedDay.toLocaleDateString(undefined, {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </Text>

              {loading ? (
                // Replace the loading text with the LoadingAnimation component
                <div className="flex items-center justify-center h-full">
                  <LoadingAnimation />
                </div>
              ) : assignmentsForDay.length === 0 ? (
                <Text size="4xl" as="p" className="!text-gray-600">
                  No assignments due today.
                </Text>
              ) : (
                assignmentsForDay.map((assignment) => {
                  const section = sections[assignment.sectionId];
                  const classroom = classrooms[assignment.classroomId];
                  const dueDate = new Date(assignment.dueUTC);
                  return (
                    <div
                      key={assignment.id}
                      className="mt-1.5 flex items-center justify-left self-stretch rounded-md border border-solid border-gray-300 bg-gray-50_03 p-2.5"
                    >
                      <div className="flex flex-col items-start gap-1.5">
                        <Heading size="6xl" as="h6" className="!text-blue_gray-900_02">
                          {section ? section.name : "Loading..."}
                        </Heading>
                        <Text size="2xl" as="p" className="!font-normal !text-gray-700">
                          Due:{" "}
                          {dueDate.toLocaleDateString(undefined, {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}{" "}
                          at {dueDate.toLocaleTimeString()}
                        </Text>
                        <Text size="xl" as="p" className="!font-normal !text-gray-600">
                          Classroom: {classroom ? classroom.name : "Loading..."}
                        </Text>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardCalendar;
