import React from "react";
import { Helmet } from "react-helmet";
import { Heading } from "../../components";
import StudentSidebar from "../../components/StudentSidebar";
import TickerTape from "components/TickerTape";
import StockSearch from "components/StockSearch";
import StockSimulatorTabs from "components/StockSimulatorTabs";
import EconomicCalendarComponent from "components/EconomicCalendarComponent";

export default function EconomicCalendarPage() {

    return (
        <>
            <Helmet>
                <title>intertwined</title>
                <meta name="description" content="Intertwined Finance" />
            </Helmet>

            <div className="flex w-full h-screen overflow-hidden m-auto">

                <div><StudentSidebar /></div>

                <div className="flex flex-col w-full">
                    <TickerTape />

                    <div className="flex w-full h-full">

                        <div className="flex flex-grow flex-shrink-0 w-[25%] ml-4 mt-4 h-[94%] border rounded-lg md:hidden">
                            <StockSearch />
                        </div>

                        <div className="flex flex-col justify-start  w-full h-[94%] gap-4 m-4 border border-solid rounded-lg ">

                            <div className="flex w-full gap-6 border-b p-4 justify-end">
                                <StockSimulatorTabs currentTab={"economiccalendar"} />
                            </div>

                            <div className="flex flex-col gap-4 p-4 overflow-auto">

                                <div className="flex flex-col items-stretch w-full gap-6">
                                    <div className="flex self-start">
                                        <Heading size="11xl" as="h2">
                                            Economic Calendar
                                        </Heading>
                                    </div>
                                    <div className="flex h-full items-center justify-evenly gap-4 min-h-[500px]">
                                        <EconomicCalendarComponent />
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>


            </div>


        </>
    );
}


