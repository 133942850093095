import React from 'react';
import PortfolioChart from 'components/PortfolioChart';
import LoadingAnimation from 'components/LoadingAnimation'; // Ensure this is imported

const DashboardStockSimWidget = ({ portfolioGraph, loading }) => {
    return (
        <div className="flex flex-col w-full h-full rounded-lg border border-solid bg-white-A700 border-gray-200_03 p-2 2xl:p-4">
            <h1 className="font-semibold text-xs lg:text-md 2xl:text-base text-accent">
                My Stock Simulation Portfolio
            </h1>
            <div className="flex-1 flex items-center justify-center">
                {loading ? (
                    <LoadingAnimation />
                ) : (
                    <PortfolioChart portfolioData={portfolioGraph} />
                )}
            </div>
        </div>
    );
};

export default DashboardStockSimWidget;
