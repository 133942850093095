import React from "react";
import Popup from 'reactjs-popup';
import StartupSimCalendar from "components/StartupSimCalendar";

export default function StartupSimProgressPopup({ open, onClose, setShowPopup, unformattedDate, simDates, setViewingOldDate, setOldDateProgressId}) {

  return (
    <Popup open={open} onClose={onClose} modal contentStyle={{ width: '450px', height: '625px', borderRadius: '1rem', backgroundColor: 'white' }}>
      <StartupSimCalendar setShowPopup={setShowPopup} unformattedDate={unformattedDate} simDates={simDates} setViewingOldDate={setViewingOldDate} setOldDateProgressId={setOldDateProgressId} />
    </Popup>
  );
}
