import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Button, Img } from "../../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import TeacherLearningViewTopBar from "components/LearningView/TeacherLearningViewTopBar";
import TeacherUnitTree from "components/LearningView/TeacherUnitTree";
import ArticleContent from "components/LearningView/ArticleContent";
import VideoLessonContent from "components/LearningView/VideoLessonContent";
import { LESSON_ARTICLE } from "utils";

// Import the service functions for API calls
import { fetchUnitTree, fetchSectionContent } from "TeacherAPIService"; // Adjust the import path as necessary

export default function TeacherLearningViewLessonPage() {
    const { state } = useLocation();
    const { courseId, unitId, lessonId } = useParams();
    const [sectionContent, setSection] = useState({ lessons: [] });
    const [tree, setTree] = useState(undefined);
    const [activeLesson, setActiveLesson] = useState({ id: "", contentType: LESSON_ARTICLE, lessonContent: "" });
    const [resetTree, setResetTree] = useState("");
    const contentRef = useRef(null);
    const navigate = useNavigate();
    const [menuState, setMenuState] = useState(true);
    const [change, setChange] = useState(false);

    function handleBackClick() {
        if (!tree) return;

        for (let i = 0; i < tree.sections.length; i++) {
            const section = tree.sections[i];
            for (let j = 0; j < section.lessons.length; j++) {
                const lesson = section.lessons[j];

                if (lesson.id === activeLesson.id) {
                    if (j > 0) {
                        navigate(`/course/${courseId}/unit/${unitId}/lesson/${section.lessons[j - 1].id}`, {
                            state: { tree: tree, section: sectionContent },
                        });
                    } else if (i > 0) {
                        const pli = tree.sections[i - 1].lessons.length - 1;
                        navigate(`/course/${courseId}/unit/${unitId}/lesson/${tree.sections[i - 1].lessons[pli].id}`, {
                            state: { tree: tree, section: sectionContent },
                        });
                    } else {
                        navigate(`/course/${courseId}`);
                    }
                }
            }
        }
    }

    function goToNext(nextPath) {
        navigate(`/course/${courseId}/unit/${unitId}${nextPath}`, {
            state: { tree: tree, section: sectionContent },
        });
    }

    function handleNextClick() {
        if (!tree || !activeLesson) return;

        for (let i = 0; i < tree.sections.length; i++) {
            const section = tree.sections[i];
            for (let j = 0; j < section.lessons.length; j++) {
                const lesson = section.lessons[j];

                if (lesson.id === activeLesson.id) {
                    if (j < section.lessons.length - 1) {
                        goToNext(`/lesson/${section.lessons[j + 1].id}`);
                    } else if (section.quiz) {
                        goToNext(`/quiz/${section.quiz.id}`);
                    } else if (i < tree.sections.length - 1) {
                        goToNext(`/lesson/${tree.sections[i + 1].lessons[0].id}`);
                    }

                    setResetTree(resetTree === "" ? "1" : "");
                }
            }
        }

        if (contentRef.current) {
            contentRef.current.scrollTop = 0;
        }
    }

    useEffect(() => {
        const fetchTree = async () => {
            try {
                const data = await fetchUnitTree(unitId, "08dc5bd3-a845-4194-8282-5cda1fd3e64e");
                setTree(data);
                if (!lessonId) {
                    navigate(`/course/${courseId}/unit/${unitId}/lesson/${data.sections[0].lessons[0].id}`, {
                        state: { tree: data, section: sectionContent },
                    });
                }
            } catch (error) {
                if (error.status === 401) {
                    navigate("/timeout");
                } else {
                    console.error("Fetching unit tree failed", error);
                }
            }
        };

        if (!state?.tree) {
            fetchTree();
        } else {
            setTree(state.tree);
            if (!lessonId) {
                navigate(`/course/${courseId}/unit/${unitId}/lesson/${state.tree.sections[0].lessons[0].id}`, {
                    state: { tree: state.tree, section: sectionContent },
                });
            }
        }
    }, [unitId, resetTree, courseId, lessonId, navigate, sectionContent, state?.tree]);

    useEffect(() => {
        const fetchSection = async () => {
            try {
                const section = tree?.sections.find((s) =>
                    s.lessons.find((l) => l.id === lessonId)
                );

                if (!section) {
                    console.error("Invalid section Id.");
                    return;
                }

                if (!state?.section || state.section.id !== section.id) {
                    const sectionData = await fetchSectionContent(section.id);
                    setSection(sectionData);
                    const activeLesson = sectionData.lessons.find((lesson) => lesson.id === lessonId);
                    if (activeLesson) {
                        setActiveLesson(activeLesson);
                    }
                } else {
                    setSection(state.section);
                    const activeLesson = state.section.lessons.find((lesson) => lesson.id === lessonId);
                    if (activeLesson) {
                        setActiveLesson({ ...activeLesson });
                        setChange(!change);
                    }
                }
            } catch (error) {
                if (error.status === 401) {
                    navigate("/timeout");
                } else {
                    console.error("Fetching section failed", error);
                }
            }
        };

        if (tree) {
            fetchSection();
        }
    }, [unitId, lessonId, tree, resetTree, change, navigate, state?.section]);

    return (
        <>
            <Helmet>
                <title>intertwined</title>
                <meta name="description" content="Intertwined Finance" />
            </Helmet>

            <div className="h-screen p-2 w-full flex flex-col gap-4 overflow-hidden bg-mesh-gradient">

                <div className="w-full bg-white-A700 rounded-lg">
                    <TeacherLearningViewTopBar courseId={courseId}
                        studentId={"08dc5bd3-a845-4194-8282-5cda1fd3e64e"}
                        unitInfo={tree && { name: tree.name && tree.name, sequenceNumber: tree.number ? tree.number : 0 }}
                    />
                </div>


                <div className="flex-1 flex max-h-[calc(100vh-100px)] bg-white-A700 divide-x rounded-lg gap-2">

                    <div className={`${menuState ? 'w-[30%]' : 'w-[0%]'} overflow-auto py-4`}>
                        {menuState && (
                            <TeacherUnitTree courseId={courseId}
                                data={tree}
                                content={sectionContent}
                                active={activeLesson.id} />
                        )}
                    </div>

                    <div ref={contentRef} className={`${menuState ? 'w-[70%]' : 'w-[100%]'} flex flex-col gap-4 p-4`}>
                        <Button
                            size="3xl"
                            variant="outline"
                            leftIcon={<Img src="/images/img_megaphone.svg" alt="megaphone" className="h-[18px] w-[18px]" />}
                            className="max-w-[180px] gap-2 rounded-[20px] font-medium tracking-[0.10px] p-4 sm:pr-5"
                            onClick={() => setMenuState(!menuState)} // This closes all tabs
                        >
                            {menuState ? "Close Menu" : "Open Menu"}
                        </Button>

                        <div className="border rounded-lg p-4 overflow-auto">
                            {activeLesson.contentType === LESSON_ARTICLE ?
                                <ArticleContent lesson={activeLesson} rerender={change} />
                                : <VideoLessonContent lesson={activeLesson} />}

                            <div className="flex justify-between p-4">
                                <Button
                                    size="3xl"
                                    variant="outline"
                                    leftIcon={
                                        <Img
                                            src="/images/img_arrowleft_blue_a200_1.svg"
                                            alt="arrow_left"
                                            className="h-[18px] w-[18px]"
                                        />
                                    }
                                    onClick={handleBackClick}
                                    className="min-w-[99px] gap-2 rounded-[20px] font-medium tracking-[0.10px] !text-blue-A400 sm:pr-5"
                                >
                                    Back
                                </Button>
                                <Button
                                    size="3xl"
                                    rightIcon={
                                        <Img
                                            src="/images/img_arrowright_white_a700.svg"
                                            alt="arrow_right"
                                            className="h-[18px] w-[18px]"
                                        />
                                    }
                                    onClick={handleNextClick}
                                    className="min-w-[98px] gap-2 rounded-[20px] font-medium tracking-[0.10px] sm:pr-5 cursor-pointer"
                                >
                                    Next
                                </Button>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </>
    );
}
