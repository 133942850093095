import React from 'react';
import Chart from 'react-apexcharts';

const TotalMissedQuestionsChart = ({ classData, selectedUnitId }) => {
    // Filter sections based on the selected unit
    const selectedUnit = classData.unitsStatistics.find(unit => unit.unitId === selectedUnitId);

    if (!selectedUnit) {
        return <div>No data available for the selected unit.</div>;
    }

    const sections = selectedUnit.sectionsStatistics.map(section => section.sectionName);
    const missedQuestions = selectedUnit.sectionsStatistics.map(section => section.totalMissedQuestionsInSection);

    // Calculate the maximum value in the data to set the tick amount
    const maxMissedQuestions = Math.max(...missedQuestions);

    const chartOptions = {
        chart: {
            type: 'bar',
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },
            events: {
                markerClick: function (event, chartContext, { seriesIndex, dataPointIndex, config }) {
                    event.stopPropagation();
                }
            },
        },
        xaxis: {
            categories: sections,
            title: {
                text: 'Section Name',
            },
            labels: {
                style: {
                    fontSize: '12px',
                },
                formatter: function (value) {
                    return value.length > 10 ? value.substring(0, 10) + '...' : value; // Truncate label if longer than 10 characters
                },
            },
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                formatter: (value) => Math.round(value),
                style: {
                    fontSize: '12px',
                },
            },
            tickAmount: maxMissedQuestions, // Set tick amount to the max value in the data
            min: 0, // Ensure the y-axis starts at 0
            forceNiceScale: true, // Ensures that the scale looks nice
        },
        grid: {
            show: true, // Enable grid lines
            yaxis: {
                lines: {
                    show: true, // Show y-axis grid lines
                }
            }
        },
        stroke: {
            curve: 'straight',
        },
        title: {
            text: `Total Missed Questions by Section`,
            align: 'left'
        },
        markers: {
            size: 5,
            colors: ['#007bff'],
            strokeWidth: 2,
            hover: {
                size: 7,
            },
            showNullDataPoints: false,
        },
        tooltip: {
            enabled: true,
        },
        dataLabels: {
            enabled: false, // Disable the value labels on the bar
        },
    };

    const chartSeries = [
        {
            name: 'Total Missed Questions',
            data: missedQuestions,
        },
    ];

    return (
        <div className='h-full'>
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height="90%" // Ensure Chart takes 100% height of its container
                width="100%"  // Ensure Chart takes 100% width of its container
            />
        </div>
    );
};

export default TotalMissedQuestionsChart;
